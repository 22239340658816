<template>
  <v-row>
    <v-col>
      <v-row v-if="shopifyStoreTaken">
        <v-col>
          <v-alert
              prominent
              type="error"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ $t('storeNameTaken') }}
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-else-if="isNotConsented && connected">
        <v-col>
          <v-alert
              prominent
              type="error"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ $t('isNotConsented') }}
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="!updatedConfig.shop">
        <v-col>
          <v-alert
              prominent
              type="info"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ $t('saveToComplete') }}
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="!editMode">
        <v-card-text>
          <v-row
            dense
            align="center"
          >
            <v-col class="shrink">
              {{ $t('store') }}:
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                plain
                :href="`https://${updatedConfig.storeName}.myshopify.com/`"
                target="_blank"
              >
                {{ updatedConfig.storeName }}.myshopify.com
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-row>
      <v-row v-else>
        <v-col class="pr-8">
          <v-form
            ref="form"
            v-model="isValid"
          >
            <!-- ******************** -->
            <!-- Store API connection -->
            <!-- ******************** -->

            <!-- 'New' connection mode (oauth flow): accessToken present if connected. if password present = legacy mode -->
            <div v-if="!connected">
              <v-row dense>
                <v-col>
                  <v-btn
                    color="primary"
                    :loading="connecting"
                    @click="connectShopifyStore"
                  >
                    {{ $t('connect') }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row
                dense
                align="center"
              >
                <v-col class="shrink">
                  {{ $t('store') }}:
                </v-col>
                <v-col>
                  <v-btn
                    color="primary"
                    plain
                    :href="`https://${updatedConfig.storeName}.myshopify.com/`"
                    target="_blank"
                  >
                    {{ updatedConfig.storeName }}.myshopify.com
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-if="!isConnected"
              >
                <v-btn
                  color="primary"
                  :loading="connecting"
                  @click="reconnect()"
                >
                  {{ $t('reconnect') }}
                </v-btn>
              </v-row>
            </div>
            <!-- Old "legacy" connection mode (only for existing connectors with an apiKey and password) -->
            <div v-if="updatedConfig.password && updatedConfig.apiKey && !updatedConfig.accessToken">
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="updatedConfig.apiKey"
                    :rules="[required]"
                    dense
                    outlined
                    placeholder="abcdef1234567890abcdef1234567890"
                    :label="$t('apiKey')"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="updatedConfig.password"
                    :rules="[required]"
                    dense
                    outlined
                    placeholder="1234567890abcdef1234567890abcdef"
                    :label="$t('password')"
                  />
                </v-col>
              </v-row>
            </div>

            <!-- ******************** -->
            <!-- Configs              -->
            <!-- ******************** -->

            <div v-if="connected">
              <v-row dense>
                <v-col>
                  <v-switch
                    v-model="updatedConfig.partiallyFulfilled"
                    :label="$t('partiallyFulfill')"
                    dense
                  />
                </v-col>
              </v-row>
              <v-row
                dense
                class="mb-4"
              >
                <v-col>
                  <span class="text-body-1">{{ $t('packageDefaults') }}</span>
                  <hr>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">
                  <v-select
                    v-model="updatedConfig.defaultPackageType"
                    :label="$t('defaultPackageType')"
                    dense
                    outlined
                    :items="packageTypes"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">
                  <v-text-field
                    v-model="updatedConfig.defaultWeight"
                    :label="$t('defaultWeight')"
                    :rules="[required, number]"
                    append-icon="mdi-asterisk"
                    outlined
                    dense
                  />
                </v-col>
                <v-col class="shrink">
                  <v-select
                    v-model="updatedConfig.defaultWeightUnit"
                    dense
                    outlined
                    :items="weightUnits"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col class="shrink pt-2 text-body-2">
                  Dimensions:
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model="updatedConfig.defaultDimensions.length"
                    :label="$t('length')"
                    :rules="[required, number]"
                    append-icon="mdi-asterisk"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model="updatedConfig.defaultDimensions.width"
                    :label="$t('width')"
                    :rules="[required, number]"
                    append-icon="mdi-asterisk"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model="updatedConfig.defaultDimensions.height"
                    :label="$t('height')"
                    :rules="[required, number]"
                    append-icon="mdi-asterisk"
                    outlined
                    dense
                  />
                </v-col>
                <v-col class="shrink">
                  <v-select
                    v-model="updatedConfig.defaultDimensions.unit"
                    dense
                    outlined
                    :items="dimensionUnits"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <span class="text-body-1">{{ $t('locations') }}</span>
                  <hr>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-switch
                    v-model="updatedConfig.useEffiLocation"
                    :label="$t('effiLocation')"
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="!updatedConfig.useEffiLocation"
                >
                  <v-chip
                    v-for="(location, idx) in updatedConfig.locations"
                    :key="idx"
                    class="ma-1 font-weight-bold"
                  >
                    {{ location.name }} ({{ location.address1 }}, {{ location.city }})
                  </v-chip>
                </v-col>
                <v-col
                  v-else
                >
                  <v-chip
                    class="ma-1 font-weight-bold"
                    :to="`/account?returnUrl=${$route.path}?edit=${connectorConfigId}`"
                  >
                    {{ accountAddress }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    v-if="!updatedConfig.useEffiLocation"
                    color="primary"
                    :loading="refreshing"
                    @click="refreshLocations"
                  >
                    {{ $t('refreshLocations') }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import config from '@/services/config'
import ShipperService from '@/services/shipper'
import {number, required, url} from '@/utils/validation'
import lodash from 'lodash'

export default {
  name: 'Shopify',
  props: {
    editMode: Boolean,
    connectorConfigId: {
      type: String,
      default: () => '',
    },
    connectorConfig: {
      type: Object,
      default: () => ({
        manual: true,
        partiallyFulfilled: true,
        useEffiLocation: false,
        defaultDimensions: {},
      })
    }
  },

  inject: ['showErrorDialog'],

  i18n: {
    messages: {
      en: {
        store: 'Store',
        connect: 'Connect Effi to your Shopify store',
        packageDefaults: 'Package defaults',
        defaultPackageType: 'Package type',
        defaultWeight: 'Weight',
        effiLocation: 'Use Effi pickup location',
        length: 'Length',
        width: 'Width',
        height: 'Height',
        packageType: {
          box: 'Box',
          envelope: 'Envelope',
        },
        locations: 'Pickup location (warehouse)',
        refreshLocations: 'Refresh locations list',
        partiallyFulfill: 'Importation of partially fulfilled shipments',
        password: 'Password',
        apiKey: 'API Key',
        reconnect: 'Reconnect',
        storeNameTaken: 'This Shopify store is already used in an EFFI account.',
        saveToComplete: 'Review settings and save to complete configuration',
        emptyAccountAddress: 'Please click here to configure your account pickup address!',
        isNotConsented: 'You must consent to the privacy policy!'
      },
      fr: {
        store: 'Boutique',
        connect: 'Connecter Effi à votre boutique en ligne Shopify',
        packageDefaults: 'Informations de colis par défaut (si non fournies par Shopify)',
        defaultPackageType: 'Type de colis',
        defaultWeight: 'Poids',
        effiLocation: 'Utiliser l\'emplacement de cueillette d\'Effi',
        length: 'Longueur',
        width: 'Largeur',
        height: 'Hauteur',
        packageType: {
          box: 'Boîte',
          envelope: 'Enveloppe',
        },
        locations: 'Emplacement de cueillette (entrepôt)',
        refreshLocations: 'Rafraîchir la liste des emplacements',
        partiallyFulfill: 'Importation de commandes partiellement traitées',
        password: 'Mot de passe',
        apiKey: 'Clé d\'API',
        reconnect: 'Reconnexion',
        storeNameTaken: 'Cette boutique Shopify est déjà utilisée dans un compte EFFI.',
        saveToComplete: 'Révisez les paramètres et sauvegardez pour compléter la configuration',
        emptyAccountAddress: 'Veuillez cliquer ici pour configurer l\'adresse de cueillette de votre compte!',
        isNotConsented: 'Vous devez consentir à la politique de confidentialité!'
      }
    }
  },

  data() {
    return {
      shopifyStoreTaken: false,
      isValid: false,
      updatedConfig: this.connectorConfig,
      connecting: false,
      isConnected: true,
      refreshing: false,
      packageTypes: [
        {text: this.$t('packageType.box'), value: 'BOX'},
        {text: this.$t('packageType.envelope'), value: 'ENVELOPE'},
      ],
      weightUnits: [
        {text: 'LB', value: 'lb'},
        {text: 'KG', value: 'kg'},
      ],
      dimensionUnits: [
        {text: this.$i18n.locale === 'fr' ? 'PO' : 'IN', value: 'in'},
        {text: 'CM', value: 'cm'},
      ]
    }
  },

  computed: {
    connected() {
      return (this.updatedConfig.password && this.updatedConfig.apiKey) || this.updatedConfig.accessToken
    },
    isNotConsented() {
      const account = this.$store.state.account
      return !account.shopifyConsent
    },
    accountAddress() {
      if (this.$store.state.account.pickupAddress) {
        const accountAddressData = JSON.parse(this.$store.state.account.pickupAddress)
        return accountAddressData.address1 + ', ' + accountAddressData.city
      }
      else {
        return this.$t('emptyAccountAddress')
      }
    }
  },

  watch: {
    connectorConfig: 'updateConfig',
  },

  beforeMount() {
    if (!this.updatedConfig.defaultDimensions) {
      this.updatedConfig.defaultDimensions = {}
    }
  },
  mounted() {
    if (this.connectorConfigId && this.connected) {
      ShipperService.testConnector(this.connectorConfigId)
          .then((result) => {
            if (result.success === true) {
              this.isConnected = result.success
            } else {
              this.isConnected = false
            }
          })
          .catch((error) => {
            console.error(error)
          })
      // Check for duplicates!
      ShipperService.shopifyStoreTaken(this.updatedConfig.storeName, this.connectorConfigId)
          .then(exists => {
            this.shopifyStoreTaken = exists
          })
    }
  },

  methods: {
    required, number, url,
    valid() {
      this.$refs.form?.validate()
      return this.isValid && !this.shopifyStoreTaken && !this.isNotConsented
    },
    connectShopifyStore() {
      this.connecting = true
      if (config.ENV_TYPE === 'PROD') {
        window.location.href = 'https://apps.shopify.com/effi'
      } else {
        // TODO: je crois qu'à terme, on aura pas le choix d'avoir une seule APP peut importe l'environnement ... sais pas si on va pouvoir installer des App non submit même sur des dev store ...
        this.showErrorDialog("Pour les tests avec une boutique de développement, SVP utiliser le portail de Shopify et la fonction 'Test on development store'.\n https://partners.shopify.com/988016/apps")
        this.connecting = false
      }
    },
    reconnect() {
      this.connecting = true
      // We still allow to use the OAuth flow here since it is not a new installation but a reconnection from the account
      ShipperService.getShopifyAuthorizeUrl(this.updatedConfig.storeName)
        .then(value => window.location.href = value)
        .catch(reason => {
          this.connecting = false
          this.showErrorDialog(reason)
        })
    },
    refreshLocations() {
      this.refreshing = true
      ShipperService.refreshShopifyLocations(this.connectorConfigId)
        .then(locations => this.updatedConfig.locations = locations)
        .catch(reason => this.showErrorDialog(reason))
        .finally(() => this.refreshing = false)
    },
    updateConfig() {
      const config = lodash.cloneDeep(this.connectorConfig)
      if (!config.defaultDimensions) {
        config.defaultDimensions = {}
      }
      this.updatedConfig = config
    }
  }
}
</script>

<style scoped>

</style>