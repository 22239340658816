<template>
  <v-container>
    <div v-if="redirectToSettings">
      <AccountInfoDialog />
    </div>
    <v-row class="mt-4">
      <v-col>
        <h2>{{ $t(order.id ? 'title.edit' : 'title.create') }}</h2>
      </v-col>
      <v-spacer />
      <v-col
        cols="6"
        class="text-right justify-end"
      >
        <v-btn
          color="primary"
          :loading="saving"
          class="ml-2"
          :disabled="!isValid"
          @click="save(false)"
        >
          <v-icon>mdi-check</v-icon>
          {{ $t('save') }}
        </v-btn>
        <v-btn
          color="primary"
          :loading="saving"
          class="ml-2"
          :hidden="!!orderId"
          :disabled="!isValid"
          @click="save(true)"
        >
          <v-icon>mdi-check</v-icon>
          {{ $t('saveAndNew') }}
        </v-btn>
        <v-btn
          color="primary"
          to="/shipper/shipments/unfulfilled"
          class="ml-2"
        >
          <v-icon>mdi-cancel</v-icon>
          {{ $t('cancel') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-form
      ref="form"
      v-model="isValid"
    >
      <v-row>
        <v-col cols="4">
          <v-card>
            <v-card-title>
              {{ $t('title.pickup') }}
            </v-card-title>
            <v-container>
              <v-row
                v-show="!orderId"
                dense
              >
                <v-col>
                  <v-checkbox
                    v-model="useAccountAddress"
                    class="mt-0"
                    :label="$t('useAccountAddress')"
                    dense
                    :disabled="_.isEmpty(accountAddress)"
                    @click="setPickupAddressToAccount()"
                  />
                </v-col>
              </v-row>
              <div v-if="!useAccountAddress">
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-if="!useAccountAddress"
                      v-model="selectedPickupAddress"
                      outlined
                      dense
                      hide-details="auto"
                      style="z-index: 1000;"
                      :label="$t('search')"
                      :items="addressBook"
                      :loading="searching"
                      :search-input.sync="pickupAddressSearch"
                      item-text="contact"
                      return-object
                      no-filter
                      hide-no-data
                      prepend-inner-icon="mdi-magnify"
                      append-icon=""
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <span class="font-weight-bold">{{ data.item.contact }}</span><br>
                          <AddressRender
                            :address="data.item"
                            single-line
                          />
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-divider class="my-3" />
                <v-row dense>
                  <v-col>
                    <AddressWidget
                      v-model="order.pickupAddress"
                      additional-fields
                      required
                      contact-required
                      phone-required
                      email-required
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-checkbox
                      v-model="saveAddressBookPickup"
                      :label="$t('saveAddressBook')"
                      class="mt-0"
                    />
                  </v-col>
                </v-row>
              </div>
              <div v-show="useAccountAddress">
                <v-row>
                  <v-col>
                    <span v-html="$t('companyName')" /> {{ order.pickupAddress.contact }}<br>
                    <MapWidget :mapped-address="accountAddress" />
                    <span v-html="$t('address')" />{{ formatAddress(accountAddress) }}<br>
                    <span v-html="$t('contact')" />{{ accountAddress.contact }}<br>
                    <span v-html="$t('phone')" />{{ accountAddress.phone }}<br>
                    <span v-html="$t('email')" />{{ accountAddress.email }}<br>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-card-title>
              {{ $t('title.delivery') }}
            </v-card-title>
            <v-container>
              <v-row
                dense
                class="mb-2"
              >
                <v-col>
                  <v-autocomplete
                    v-model="selectedDeliveryAddress"
                    outlined
                    dense
                    hide-details="auto"
                    style="z-index: 1000;"
                    :label="$t('search')"
                    :items="addressBook"
                    :loading="searching"
                    :search-input.sync="deliveryAddressSearch"
                    item-text="contact"
                    return-object
                    no-filter
                    hide-no-data
                    prepend-inner-icon="mdi-magnify"
                    append-icon=""
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <span class="font-weight-bold">{{ data.item.contact }}</span><br>
                        <AddressRender
                          :address="data.item"
                          single-line
                        />
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider class="my-3" />
              <v-row dense>
                <v-col>
                  <AddressWidget
                    v-model="order.deliveryAddress"
                    additional-fields
                    required
                    contact-required
                    phone-required
                    email-required
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-checkbox
                    v-model="saveAddressBookDelivery"
                    :label="$t('saveAddressBook')"
                    class="mt-0"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-card-title>
              {{ $t('title.extra') }}
            </v-card-title>
            <v-container>
              <v-row>
                <v-col>
                  <p>{{ $t('packageType') }}</p>
                  <v-radio-group
                    v-model="order.packages[0].type"
                    mandatory
                    row
                    dense
                    hide-details="auto"
                  >
                    <v-radio
                      :label="$t('box')"
                      value="BOX"
                    />
                    <v-radio
                      :label="$t('envelope')"
                      value="ENVELOPE"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="order.packages[0].quantity"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('quantity')"
                    :rules="[required, number]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        v-model="order.packages[0].dimensions.length"
                        outlined
                        dense
                        hide-details="auto"
                        :label="$t('length')"
                        :rules="[required, number]"
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="order.packages[0].dimensions.width"
                        outlined
                        dense
                        hide-details="auto"
                        :label="$t('width')"
                        :rules="[required, number]"
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="order.packages[0].dimensions.height"
                        outlined
                        dense
                        hide-details="auto"
                        :label="$t('height')"
                        :rules="[required, number]"
                      />
                    </v-col>
                    <v-col class="shrink">
                      <v-select
                        v-model="order.packages[0].dimensions.unit"
                        :items="['IN','CM']"
                        :rules="[required]"
                        outlined
                        dense
                        hide-details="auto"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        v-model="order.packages[0].weight"
                        outlined
                        dense
                        hide-details="auto"
                        :label="$t('weight')"
                        :rules="[required, number]"
                      />
                    </v-col>
                    <v-col class="shrink">
                      <v-select
                        v-model="order.packages[0].weightUnit"
                        :items="['LB','KG']"
                        :rules="[required]"
                        outlined
                        dense
                        hide-details="auto"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-body-2">
                  {{ $t('title.timeWindow') }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <DatePicker
                    v-model="order.deliveryDate"
                    width="100%"
                    :label="$t('deliveryDate')"
                    :min-date="$dayjs(new Date).format('YYYY-MM-DD')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <TimeWindow
                    v-model="order.timeWindow"
                    class="mt-0"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="order.note"
                    dense
                    outlined
                    hide-details="auto"
                    :label="$t('note')"
                    rows="5"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import AddressRender from '@/components/AddressRender'
import AddressWidget from '@/components/AddressWidget'
import ShipperService from '@/services/shipper'
import DatePicker from '@/components/DatePicker'
import TimeWindow from '@/components/TimeWindow'
import {number, required} from '@/utils/validation'
import MapWidget from '@/components/MapWidget'
import AccountInfoDialog from '@/components/AccountInfoDialog'
import {normalize} from "@/utils/address";
import lodash from "lodash";

export default {
  name: 'OrderEdit',
  inject: ['showErrorDialog'],
  components: {TimeWindow, DatePicker, AddressWidget, AddressRender, MapWidget, AccountInfoDialog},
  data: function() {
    const data = {
      number, required,
      orderId: null,
      order: { // see com.effiapp.ctrl.api.shipper.v1.model.Order
        pickupAddress: {
          contact: this.$store.state.account.company || this.$store.state.account.email,
        },
        deliveryDate: this.$dayjs(new Date()).format('YYYY-MM-DD'),
        packages: [{
          quantity: 1,
          type: 'BOX',
          weight: 1,
          weightUnit: 'LB',
          dimensions: {
            length: 1,
            width: 1,
            height: 1,
            unit: 'IN'
          }
        }]
      },
      saving: false,
      isValid: false,
      saveAddressBookPickup: false,
      saveAddressBookDelivery: false,
      pickupAddressSearch: '',
      deliveryAddressSearch: '',
      addressBook: [],
      searching: false,
      debounce: null,
      selectedDeliveryAddress: null,
      selectedPickupAddress: null,
      useAccountAddress: true,
      accountAddress: {},
      redirectToSettings: false,
    }

    const adr = this.$store.state.account.pickupAddress
    if (adr) {
      const address = normalize(JSON.parse(adr))
      data.accountAddress = address
      address.name = this.$store.state.account.company
      address.contact = this.$store.state.account.contactName
      address.phone = this.$store.state.account.contactPhone
      address.email = this.$store.state.account.contactEmail
      data.order.pickupAddress = address
    }

    if (this._.isEmpty(data.accountAddress)){
      data.useAccountAddress = false
    }

    return data
  },
  watch: {
    pickupAddressSearch(val) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      this.isInDebounce = true
      this.debounce = setTimeout(() => {
        if (val && val.length > 2) {
          this.searchAddress(val)
        }
        this.isInDebounce = false
      }, 750)
    },
    deliveryAddressSearch(val) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      this.isInDebounce = true
      this.debounce = setTimeout(() => {
        if (val && val.length > 2) {
          this.searchAddress(val)
        }
        this.isInDebounce = false
      }, 750)
    },
    selectedDeliveryAddress() {
      this.order.deliveryAddress = this.selectedDeliveryAddress
      this.saveAddressBookDelivery = true
      this.deliveryAddressSearch = ''
    },
    selectedPickupAddress() {
      this.order.pickupAddress = this.selectedPickupAddress
      this.saveAddressBookPickup = true
      this.pickupAddressSearch = ''
    }
  },
  mounted() {
    if (this._.isEmpty(this.accountAddress)){
      this.redirectToSettings = true
    }
    if (this.$route.params.id) {
      this.saving = true
      ShipperService.getOrder(this.$route.params.id)
        .then(value => {
          this.orderId = value.id
          const order = value
          // Convert json values to real objects!
          if (order.pickupAddress) {
            order.pickupAddress = normalize(JSON.parse(order.pickupAddress))
            this.saveAddressBookPickup = !!order.pickupAddress.id
          }
          if (order.deliveryAddress) {
            order.deliveryAddress = normalize(JSON.parse(order.deliveryAddress))
            this.saveAddressBookDelivery = !!order.deliveryAddress.id
          }
          if (order.packages)
            order.packages = JSON.parse(order.packages)
          if (order.timeWindow)
            order.timeWindow = JSON.parse(order.timeWindow)
          this.order = order
          // TODO: detect if order address is same as account address and set to true ?
          this.useAccountAddress = !this.order.id
          this.$nextTick(() => this.$refs.form.validate())
        })
        .catch(this.showErrorDialog)
        .finally(() => this.saving = false)
    }
  },
  methods: {
    save(stay) {
      if (this.$refs.form.validate()) {
        this.saving = true
        // Serialize objects to json ...
        const order = lodash.cloneDeep(this.order)
        order.pickupAddress = JSON.stringify(this.order.pickupAddress)
        order.deliveryAddress = JSON.stringify(this.order.deliveryAddress)
        order.packages = JSON.stringify(this.order.packages)
        if (this.order.timeWindow) {
          order.timeWindow = JSON.stringify(this.order.timeWindow)
        }
        let promise
        if (this.orderId) {
          promise = ShipperService.editOrder(this.orderId, order)
        } else {
          promise = ShipperService.createOrder(order)
        }
        promise
          .then(() => {
            if (this.saveAddressBookDelivery) {
              return ShipperService.saveAddress(this.order.deliveryAddress)
            }
            if (this.saveAddressBookPickup) {
              return ShipperService.saveAddress(this.selectedPickupAddress)
            }
            return true
          })
          .then(() => {
            if (stay !== true) {
              this.$router.push('/shipper/shipments/unfulfilled')
            } else {
              Object.assign(this.$data, this.$options.data.apply(this))
              this.$refs.form.resetValidation()
              if (this.$router.currentRoute.path !== '/shipper/order/create') {
                this.$router.push('/shipper/order/create')
              }
            }
          })
          .catch(reason => this.showErrorDialog(reason))
          .finally(() => this.saving = false)
      }
    },
    setPickupAddressToAccount(){
      if (this.useAccountAddress){
        this.order.pickupAddress = this.accountAddress
      }
    },
    searchAddress(search) {
      this.searching = true
      ShipperService.searchAddress(search)
        .then((result) => {
          this.addressBook = []
          for (const address of result) {
            this.addressBook.push(address)
          }
        })
        .finally(() => this.searching = false)
    },
    formatAddress(address){
      return this._.isEmpty(address.address2)? address.address1 + ', ' + address.city +
          ' (' + address.province + '), ' + address.postalcode :
        address.address1 + ' ' + address.address2 + ', ' + address.city + ' (' + address.province + '), ' + address.postalcode
    }
  },
  i18n: {
    messages: {
      fr: {
        title: {
          create: 'Créer une commande',
          edit: 'Modifier une commande',
          pickup: 'Adresse de cueillette',
          delivery: 'Adresse de livraison',
          extra: 'Détails',
          timeWindow: 'Contrainte de temps',
        },
        useAccountAddress: 'Utiliser l\'adresse du compte',
        companyName: '<strong>Nom de l\'entreprise</strong>: ',
        address: '<strong>Adresse</strong>: ',
        contact: '<strong>Contact</strong>: ',
        phone: '<strong>Téléphone</strong>: ',
        email: '<strong>Courriel</strong>: ',
        search: 'Rechercher dans le carnet',
        addressBook: 'Carnet d\'adresses',
        saveAndNew: 'Enregistrer et nouveau',
        saveAddressBook: 'Sauvegarder dans le carnet',
        missingPickupAddress: 'Votre adresse est incomplète. Veuillez corriger avant de créer une commande.',
        packageType: 'Type de colis',
        deliveryDate: 'Livrer le',
        note: 'Note',
        box: 'Boîte',
        envelope: 'Enveloppe',
        weight: 'Poids',
        quantity: 'Quantité',
        length: 'Longueur',
        width: 'Largeur',
        height: 'Hauteur',
      },
      en: {
        title: {
          create: 'Create an order',
          edit: 'Modify an order',
          pickup: 'Pickup address',
          delivery: 'Delivery address',
          extra: 'Details',
          timeWindow: 'Time constraint',
        },
        useAccountAddress: 'Use account\'s address',
        companyName: '<strong>Company name</strong>: ',
        address: '<strong>Address</strong>: ',
        contact: '<strong>Contact</strong>: ',
        phone: '<strong>Phone</strong>: ',
        email: '<strong>Courriel</strong>: ',
        search: 'Search in address book',
        addressBook: 'Address book',
        saveAndNew: 'Save and new',
        saveAddressBook: 'Save in address book',
        missingPickupAddress: 'Your address is incomplete. Please correct befor creating an order.',
        packageType: 'Parcel type',
        deliveryDate: 'Deliver on',
        note: 'Note',
        box: 'Box',
        envelope: 'Envelope',
        weight: 'Weight',
        quantity: 'Quantity',
        length: 'Length',
        width: 'Width',
        height: 'Height',
      }
    }
  }
}
</script>

<style scoped>

</style>