<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="10">
        <v-card>
          <v-card-title>
            {{ $t('title') }}
          </v-card-title>
          <v-container>
            <v-tabs v-model="activeTab">
              <v-tab>{{ $t('tab.connectors.title') }}</v-tab>
              <v-tab>{{ $t('tab.carriers.title') }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="activeTab">
              <v-tab-item>
                <v-container>
                  <v-alert
                    icon="mdi-help-circle-outline"
                    type="info"
                  >
                    {{ $t('tab.connectors.msg') }}
                  </v-alert>
                  <v-progress-circular
                    v-if="connectorsLoading"
                    color="primary"
                    indeterminate
                    size="50"
                  />
                  <v-row
                    v-for="(connector, idx) in connectors"
                    :key="idx"
                  >
                    <v-col>
                      <ConnectorEdit
                        :connector="connector"
                        :edit.sync="connectorsEdit[idx]"
                        @edited="onConnectorSaved($event, idx)"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="ml-1">
                    <v-col>
                      <v-btn
                        color="primary"
                        @click.stop="addConnector"
                      >
                        {{ $t('tab.connectors.add') }}
                      </v-btn>
                      <ConnectorTypeDialog ref="connectorTypeDialog" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container>
                  <v-alert
                    icon="mdi-help-circle-outline"
                    type="info"
                  >
                    {{ $t('tab.carriers.msg') }}
                  </v-alert>
                  <v-alert
                    icon="mdi-alert"
                    type="warning"
                  >
                    {{ $t('tab.carriers.warn') }}
                  </v-alert>
                  <v-row>
                    <v-col>
                      <RoutingRuleEdit />
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConnectorEdit from '@/components/ConnectorEdit'
import RoutingRuleEdit from '@/components/RoutingRuleEdit'
import ShipperService from '@/services/shipper'
import ConnectorTypeDialog from '@/components/ConnectorTypeDialog'
import _ from 'lodash'
import {UPDATE_CONNECTORS} from "@/store";

export default {
  name: 'ShipperConfig',
  components: {RoutingRuleEdit, ConnectorEdit, ConnectorTypeDialog},
  inject: ['showErrorDialog'],
  props: {
    edit: {
      type: String,
      default: () => ''
    },
    temp: {
      type: String,
      default: () => 'false'
    },
    tab: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    activeTab: 0,
    connectors: [],
    connectorsEdit: [],
    connectorsLoading: false,
  }),
  mounted() {
    if (this.tab === "rules") {
      this.activeTab = 1
    }
    this.connectorsLoading = true
    ShipperService.listConnectors()
      .then((connectors) => {
        this.connectors = connectors
        _.each(this.connectors, ( connector, index ) => {
          this.connectorsEdit[index] = false
        })
        if (this.edit) {
          // special case where the connector we want to edit if flagged with a temp account id (for Shopify oauth flow without an account)
          if (this.temp === 'true' && this.connectors.findIndex(c => c.id === this.edit) < 0) {
            ShipperService.getConnector(this.edit)
                .then((connector) => {
                  this.connectors.push(connector)
                  this.editConnector(this.edit)
                  this.$store.state.connectors.push(connector)
                })
                .catch((error) => {
                  console.error(error)
                  this.showErrorDialog(error)
                })
          } else {
            this.editConnector(this.edit)
          }
        }
      })
      .catch((error) => {
        console.error(error)
        this.showErrorDialog(error)
      })
      .finally(() => this.connectorsLoading = false)
  },
  methods: {
    addConnector() {
      this.$refs.connectorTypeDialog.prompt().then((type) => {
        if (type) {
          this.connectors.push({
            connectorType: type,
            config: '{}'
          })
          this.connectorsEdit[this.connectors.length - 1] = true
        }
      })
    },
    editConnector(id) {
      const editedIndex = this.connectors.findIndex(c => c.id === id)
      if (editedIndex >= 0) {
        this.connectorsEdit[editedIndex] = true
      }
    },
    onConnectorSaved(connector, idx) {
      this.$set(this.connectors, idx, connector)
      this.$store.dispatch(UPDATE_CONNECTORS)
    }
  },
  i18n: {
    messages: {
      fr: {
        title: 'Configuration',
        tab: {
          connectors: {
            title: 'Commandes',
            msg: 'C\'est ici que vous configurez comment vos commandes seront créées dans Effi. ' +
                'Soit manuellement ou à l\'aide d\'une connexion avec votre site de commerce en ligne.',
            add: 'Ajouter une source',
          },
          carriers: {
            title: 'Transporteurs',
            msg: 'C\'est ici que vous configurez vers qui vos envois seront transmis pour ' +
                'effectuer les livraisons de vos commandes.',
            warn: 'Il est seulement possible de choisir un transporteur pour le moment. ' +
                'Des modifications seront apportées bientôt pour élargir les possibilités!',
          }
        }
      },
      en: {
        title: 'Configuration',
        tab: {
          connectors: {
            title: 'Orders',
            msg: 'This is where you configure how your orders will be created in Effi. ' +
                'Either manually or using a connection to your e-commerce site.',
            add: 'Add a source',
          },
          carriers: {
            title: 'Carriers',
            msg: 'This is where you configure to whom your shipments will be forwarded to deliver your orders.',
            warn: 'It is only possible to choose one carrier at the moment. ' +
                'Changes will be made soon to expand the possibilities!',
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>