<template>
  <v-autocomplete
    v-model="place"
    :items="results"
    :loading="isLoading"
    :search-input.sync="search"
    :label="label"
    :placeholder="placeholder"
    hide-selected
    hide-no-data
    item-text="description"
    item-value="place_id"
    solo
    dense
    no-filter
    prepend-inner-icon="mdi-magnify"
    return-object
  >
    <template v-slot:item="data">
      <v-list-item-icon>
        <v-icon>mdi-map-marker-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="data.item.description" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import GeocodeService from '@/services/geocode'
import {v4 as uuidv4} from 'uuid'

export default {
  name: 'GoogleAutoComplete',
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      results: [],
      isLoading: false,
      search: '',
      place: null,
      placeDetails: null,
      // Google requires a session token for all related queries of a "search". So we are setting one per widget instance and resetting upon selection
      sessiontoken: uuidv4(),
    }
  },

  watch: {
    search(value) {

      if (!value) {
        return
      }

      // If already searching, stop ... TODO: (queue search request ?)
      if (this.isLoading) {
        console.log('already searching!')
      }

      this.isLoading = true
      GeocodeService.autoComplete(value, this.sessiontoken)
        .then((results) => {
          this.results = results
        })
        .catch((error) => {
          alert(error)
        })
        .finally(() => (this.isLoading = false))
    },
    place(place) {
      GeocodeService.placeDetails(place.place_id, this.sessiontoken)
        .then((details) => {
          this.sessiontoken = uuidv4()
          this.placeDetails = details
          this.$emit('selection', details)
        })
        .catch((error) => {
          alert(error)
        })
    }
  },
}
</script>

<style scoped>

</style>
