<template>
  <v-row>
    <v-col>
      <v-row v-if="!editMode || updatedConfig.token">
        <v-card-text>
          {{ $t('token') }}:
          <code style="overflow-wrap: anywhere;">{{ updatedConfig.token }}</code>
        </v-card-text>
      </v-row>
      <v-row v-else>
        <v-col class="pr-8">
          <v-alert type="info">
            {{ $t('generation') }}
          </v-alert>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {required, url} from '@/utils/validation'

export default {
  name: 'ShipperApi',

  props: {
    editMode: Boolean,
    connectorConfigId: {
      type: String,
      default: () => '',
    },
    connectorConfig: {
      type: Object,
      default: () => ({
        manual: true,
        partiallyFulfilled: true,
        defaultDimensions: {},
      })
    }
  },

  i18n: {
    messages: {
      fr: {
        token: 'Jeton d\'accès',
        generation: 'Une clé d\'API sera générée au moment de la création du connecteur.'
      },
      en: {
        token: 'API token',
        generation: 'An API key will be generated as soon as the connector is created.'
      }
    }
  },

  data() {
    return {
      isValid: false,
      updatedConfig: this.connectorConfig,
    }
  },

  watch: {
    connectorConfig() {
      this.updatedConfig = this.connectorConfig
    }
  },

  mounted() {
    this.isValid = true
  },

  methods: {
    required,
    url,
    valid() {
      return this.isValid
    }
  }
}
</script>

<style scoped>

</style>
