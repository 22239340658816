<template>
  <Invitation :invite="invite" :return-url="returnUrl" :invite-type="inviteType" />
</template>

<script>
import Invitation from "@/pages/Invitation"
import ShipperService from '@/services/shipper'

export default {
  components: {Invitation},
  props: {
    returnUrl: {
      type: String,
      default: '/shipper/users',
    },
    inviteType: {
      type: String,
      default: 'user',
    },
  },
  methods: {
    invite: ShipperService.invite
  }
}
</script>
