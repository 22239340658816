<template>
  <div>
    <div v-if="!routing">
      <v-row class="mt-4">
        <v-col>
          <h2>{{ $t('title') }} ({{ total }})</h2>
        </v-col>
        <v-spacer />
        <v-col class="text-right">
          <v-btn
            v-if="hasManualConnector"
            color="primary"
            to="/shipper/order/create"
          >
            {{ $t('create') }}
          </v-btn>
          <v-btn
            v-if="selectedItems.length > 0 && allowFulfillment"
            color="primary"
            class="ml-2"
            @click="fulfillSelected"
          >
            {{ `${$t('fulfill')} (${selectedItems.length})` }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="pt-1">
          <v-divider />
        </v-col>
      </v-row>
      <v-row v-if="hasNoRoutingRule">
        <v-alert type="warning">
          <v-row align="center">
            <v-col class="d-flex">
              <span class="mr-2">{{ $t(`errors.noRoutingRule`) }}</span>
              <v-btn
                  color="primary"
                  to="/shipper/config?tab=rules"
              >
                <span>{{ $t('errors.fix') }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-row>
      <v-row
        v-for="(connector) in connectorsInError"
        :key="connector.id"
      >
        <v-alert type="warning">
          <v-row align="center">
            <v-col class="d-flex">
              <span class="mr-2">{{ $t(`errors.noShopify`) }} {{ connector.config.storeName }} </span>
              <v-btn
                color="primary"
                :to="'/shipper/config?edit=' + connector.id"
              >
                <span>{{ $t('reconnect') }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-row>
      <v-row>
        <v-col class="shrink">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :disabled="loading"
                style="cursor: pointer;"
                v-bind="attrs"
                @click="refresh()"
                v-on="on"
              >
                mdi-replay
              </v-icon>
            </template>
            <span>{{ $t('refresh') }}</span>
          </v-tooltip>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-row
            dense
            justify="end"
          >
            <v-text-field
              v-model="search"
              :placeholder="$t('search')"
              class="ml-2"
              dense
              prepend-inner-icon="mdi-magnify"
              solo
            />
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-row
              dense
              justify="end"
          >
            <v-menu
                v-model="connectorMenu"
                :close-on-content-click="false"
                offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                  {{ $t('connectorFilter') }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group
                    v-model="connectorId"
                >
                  <v-list-item
                      v-for="cc in configs"
                      :key="cc.id"
                      :value="cc.id"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" />
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('connector.' + cc.connectorType) + (cc.connectorIdentifier ? ` (${cc.connectorIdentifier})` : '') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        class="font-weight-bold"
        dense
      >
        <v-col
          cols="2"
        >
          <ColumnHeader
            v-model="sort"
            :width="150"
            sort-key="number"
            :label="$t('order')"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <ColumnHeader
            v-model="sort"
            sort-key="created"
            :width="100"
            :label="$t('created')"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <ColumnHeader
            :label="$t('pickupAddress')"
          />
        </v-col>
        <v-col cols="3">
          <ColumnHeader
            :label="$t('deliveryAddress')"
          />
        </v-col>
        <v-col>
          <ColumnHeader
            cols="auto"
            :label="$t('service')"
          />
        </v-col>
        <v-col class="justify-end d-flex">
          <v-checkbox
            v-model="selectAll"
            class="mt-0"
            @click="toggleSelectAll"
          />
        </v-col>
      </v-row>
      <v-row
        v-for="(shipment) in shipments"
        :key="shipment.id"
        class="dataRow rounded align-center mb-2"
        dense
        :class="rowClasses(shipment)"
      >
        <v-col
          class="pa-2"
          :set="config = getShipmentConnectorConfig(shipment)"
        >
          <v-row dense>
            <v-col
              cols="2"
              class="d-flex align-center"
            >
              <v-img
                class="shrink ml-4 mr-1"
                :src="`/connector/${shipment.connectorType}.png`"
                max-width="24"
                position=""
              />
              <div v-if="shipment.connectorType === 'shopify'">
                <a
                  :href="`https://${config.storeName}.myshopify.com/admin/orders/${shipment.externalId}`"
                  target="_blank"
                  class="primary--text"
                >
                  <strong><TextHighlight :queries="[search]">{{ shipment.externalNumber }}</TextHighlight></strong>
                </a>
              </div>
              <div v-else>
                <strong><TextHighlight :queries="[search]">{{ shipment.externalNumber }}</TextHighlight></strong>
              </div>
            </v-col>
            <v-col cols="2">
              {{ $date(shipment.created).format('YYYY-MM-DD HH:mm') }}
            </v-col>
            <v-col
              cols="2"
              class="text-body-2"
            >
              <div v-if="config.locations && config.locations.length > 1">
                <v-select
                  v-model="shipment.pickupAddress"
                  :items="config.locations"
                  outlined
                  dense
                  hide-details
                  return-object
                  item-text="name"
                  item-value="address1"
                  @change="onChangePickup(shipment)"
                />
              </div>
              <div
                v-if="shipment.pickupAddress"
                :class="config.locations && config.locations.length > 1 ? 'mt-1 ml-2' : ''"
              >
                {{ shipment.pickupAddress.address1 }} {{ shipment.pickupAddress.address2 }}<br>
                {{ shipment.pickupAddress.city }}, {{ shipment.pickupAddress.provinceCode || shipment.pickupAddress.province }}, {{ shipment.pickupAddress.countryCode || shipment.pickupAddress.country }}<br>
                {{ shipment.pickupAddress.postalcode }}
              </div>
            </v-col>
            <v-col
              cols="3"
              class="text-body-1"
            >
              <div v-if="shipment.deliveryAddress">
                <TextHighlight :queries="[search]">
                  {{ shipment.deliveryAddress.name || shipment.deliveryAddress.contact }}
                </TextHighlight><br>
                <TextHighlight :queries="[search]">
                  {{ shipment.deliveryAddress.address1 }}
                </TextHighlight> <TextHighlight :queries="[search]">
                  {{ shipment.deliveryAddress.address2 }}
                </TextHighlight><br>
                <TextHighlight :queries="[search]">{{ shipment.deliveryAddress.city }}</TextHighlight>,
                <TextHighlight :queries="[search]">{{ shipment.deliveryAddress.provinceCode ? shipment.deliveryAddress.provinceCode : shipment.deliveryAddress.province }}</TextHighlight>,
                <TextHighlight :queries="[search]">{{ shipment.deliveryAddress.countryCode ? shipment.deliveryAddress.countryCode : shipment.deliveryAddress.country }}</TextHighlight><br />
                <TextHighlight :queries="[search]">
                  {{ shipment.deliveryAddress.postalcode }}
                </TextHighlight>
              </div>
            </v-col>
            <v-col>
              <v-progress-circular
                v-if="!shipment.processed && !shipment.errorCode"
                indeterminate
                color="primary"
              />
              <v-select
                v-else-if="!shipment.errorCode"
                v-model="shipment.selectedRate"
                :items="shipment.proposedCarrierRates"
                outlined
                dense
                item-value="code"
              />
            </v-col>
            <v-col>
              <v-row
                dense
                justify="end"
              >
                <v-btn
                  v-if="shipment.connectorType === 'shipperManual'"
                  icon
                  color="primary"
                  :to="`/shipper/order/${shipment.id}`"
                >
                  <v-icon>mdi-file-edit</v-icon>
                </v-btn>
                <v-btn
                  v-if="shipment.connectorType === 'shipperManual'"
                  icon
                  color="primary"
                  @click="deleteOrder(shipment.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-checkbox
                  v-model="selectedItems"
                  :value="shipment.id"
                  class="mt-0 ml-1 shrink"
                  :disabled="!!shipment.errorCode || !shipment.selectedRate"
                  hide-details
                  dense
                />
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-if="shipment.errorCode"
            dense
          >
            <v-col class="pl-6 pr-6">
              <v-alert type="warning">
                <v-row align="center">
                  <v-col class="d-flex align-top">
                    <span class="mr-2">{{ $t(`errors.${shipment.errorCode}`) }}</span>
                    <v-btn
                      v-if="!!shipment.trackingId"
                      plain
                      target="_blank"
                      :to="`/tracking/${shipment.trackingId}`"
                      class="mr-2"
                    >
                      (<code>{{ shipment.trackingId }}</code>)
                    </v-btn>
                    <v-btn
                      v-if="shipment.errorDetails"
                      class="mr-2"
                      @click="showErrorDialog(shipment.errorDetails)"
                    >
                      {{ $t('details') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-if="loading"
        class="justify-center"
      >
        <v-progress-circular
          color="primary"
          size="50"
          indeterminate
        />
      </v-row>
      <v-row
        v-if="shipments.length > 0 || offset > 0"
        class="mb-4"
      >
        <v-col cols="4">
          <v-btn
            color="primary"
            :disabled="offset === 0"
            :loading="loading"
            @click="offset -= limit; fetchShipments()"
          >
            <v-icon>mdi-arrow-left</v-icon>
            {{ $t('previous') }}
          </v-btn>
        </v-col>
        <!-- TODO: page navigation ??? -->
        <v-col
          cols="4"
          style="text-align: center;"
        >
          Page {{ currentPage }} {{ $t('of') }} {{ totalPages }}
        </v-col>
        <v-col
          cols="4"
          class="text-right"
        >
          <v-btn
            color="primary"
            :disabled="!hasNextPage"
            :loading="loading"
            @click="offset += limit; fetchShipments()"
          >
            {{ $t('next') }}
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <ShipperShipmentsRoute
        :shipments="selectedShipments"
        @completed="routingDone"
      />
    </div>
    <ConfirmDialog
      ref="confirmDialog"
      :title="$t('confirm.delete.title')"
      :message="$t('confirm.delete.msg')"
    />
  </div>
</template>

<script>
import ShipperService from '@/services/shipper'
import ColumnHeader from '@/components/ColumnHeader'
import lodash, {debounce} from 'lodash'
import ShipperShipmentsRoute from '@/pages/shipper/ShipmentsRoute'
import ConfirmDialog from '@/components/ConfirmDialog'
import * as Sentry from '@sentry/vue'
import TextHighlight from 'vue-text-highlight'
import {normalize} from "@/utils/address";

export default {
  name: 'ShipperShipmentsUnfulfilled',
  inject: ['showErrorDialog'],
  components: {
    ConfirmDialog,
    ShipperShipmentsRoute,
    ColumnHeader,
    TextHighlight,
  },
  props: {
    orderIds: {
      type: String,
      default: () => ''
    },
  },
  data() {
    return {
      allowFulfillment: false,
      routing: false,
      loading: false,
      selectAll: false,
      isConnected: true,
      search: '',
      sort: 'number DESC',
      selectedItems: [],
      selectedShipments: [],
      shipments: [],
      limit: 25,
      offset: 0,
      total: 0,
      configs: {},
      hasManualConnector: false,
      hasNoRoutingRule: false,
      connectorsInError: [],
      connectorId: null,
      connectorMenu: false,
    }
  },
  computed: {
    currentPage() {
      return Math.ceil((this.offset + 1) / this.limit)
    },
    totalPages() {
      return Math.ceil(this.total / this.limit)
    },
    hasNextPage() {
      return this.offset + this.limit < this.total
    }
  },
  watch: {
    search: 'debounceSearch',
    sort: 'refresh',
    connectorId: 'refresh',
  },
  beforeMount() {
    this.fetchConnectorConfigs()
    this.fetchRoutingRules()
  },
  mounted() {
    this.fetchShipments()
  },
  methods: {
    refresh() {
      this.fetchShipments()
    },
    async fetchShipments() {
      this.loading = true
      this.allowFulfillment = false
      try {
        this.selectedShipments = []
        this.selectedItems = []
        this.selectAll = false
        const list = await ShipperService.getAvailableShipments(this.offset, null, this.orderIds, this.search, this.sort, this.limit, this.connectorId)
        this.shipments = list.items
        this.total = list.total
        this.rateShipments(list.items)
      } catch (e) {
        this.showErrorDialog(e)
        this.loading = false
      } finally {
        this.allowFulfillment = true
      }
    },
    debounceSearch: debounce(function() {
      this.shipments = []
      this.fetchShipments()
    }, 750),
    async fetchConnectorConfigs() {
      // TODO: on devrait mettre ça dans le global store et loader au démarrage ?
      const connectors = await ShipperService.listConnectors()
      for (const connector of connectors) {
        connector.config = JSON.parse(connector.config)
        this.configs[connector.id] = connector
        ShipperService.testConnector(connector.id)
          .then((result) => {
            if (result.success === false) {
              this.connectorsInError.push(connector)
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }
      this.hasManualConnector = connectors.findIndex((connector) => connector.connectorType === 'shipperManual') > -1
    },
    async fetchRoutingRules() {
      const rules = await ShipperService.listRules()
      this.hasNoRoutingRule = rules.length === 0
    },
    rateShipments(shipments) {
      // TODO: batches ??
      const promises = []
      shipments.forEach((shipment) => {
        if (shipment.processed !== true && !shipment.trackingId) {
          promises.push(this.rateShipment(shipment))
        }
      })
      Promise
        .all(promises)
        .finally(() => this.loading = false)
    },
    async rateShipment(shipment) {
      try {
        const results = await ShipperService.rateShipments([shipment])
        const result = results[0]
        const rates = lodash.groupBy(result.proposedCarrierRates, (r) => r.carrierName)
        shipment.proposedCarrierRates = []
        for (const group in rates) {
          shipment.proposedCarrierRates.push({header: group})
          shipment.proposedCarrierRates.push(...rates[group].map(rate => {
            return {
              ...rate,
              code: rate.code,
              text: `${rate.name} (${rate.price}$)`,
            }
          }))
        }
        shipment.selectedRate = result.selectedRate
        shipment.errorCode = result.errorCode
        shipment.errorDetails = result.errorDetails

        // This shouldn't happen but it seems to happen
        if (!shipment.selectedRate && !shipment.errorCode) {
          Sentry.captureMessage(`Missing rate for shipment ${shipment.id} without any error code`)
          shipment.errorCode = 'noRate'
        }
        const idx = this.shipments.findIndex(item => item.id === shipment.id)
        if (idx > -1) {
          this.$set(this.shipments, idx, shipment)
        } else {
          console.warn(`no shipment found with id ${shipment.id}`)
        }
      } catch (e) {
        shipment.errorCode = 'unexpected'
        shipment.errorDetails = e
      } finally {
        shipment.processed = true
      }
      return shipment
    },
    fulfillSelected() {
      this.selectedShipments = this.selectedItems.map(orderId => this.shipments.find(shipment => shipment.id === orderId))
      if (this.selectedShipments.some(shipment => this._.isEmpty(shipment))) {
        this.routing = false
        this.showErrorDialog(this.$t('errors.nullShipment'))
      } else {
        this.routing = true
      }
    },
    rowClasses(shipment) {
      if (shipment && shipment.errorCode) {
        return ['danger']
      } else if (this.selectedItems.indexOf(shipment.id) > -1) {
        return ['selected']
      }
      return ['white']
    },
    toggleSelectAll() {
      this.selectedItems = []
      if (this.selectAll) {
        for (let i = 0; i < this.shipments.length; i++) {
          const shipment = this.shipments[i]
          if (!shipment || shipment.errorCode) {
            continue
          }
          this.selectedItems.push(shipment.id)
        }
      }
    },
    routingDone() {
      this.selectedItems = []
      this.selectAll = false
      this.routing = false
      this.refresh()
    },
    deleteOrder(orderId) {
      this.$refs.confirmDialog.prompt()
        .then(result => {
          if (result === 'yes') {
            ShipperService.deleteOrder(orderId)
              .then(this.refresh)
              .catch(this.showErrorDialog)
          }
        })
    },
    getShipmentConnectorConfig(shipment) {
      const cc = this.configs[shipment.connectorConfigId]
      if (cc) {
        return cc.config
      }
      return {}
    },
    onChangePickup(shipment) {
      shipment.processed = false
      shipment.selectedRate = null
      shipment.pickupAddress = normalize(shipment.pickupAddress)
      // TODO: we should update the order's pickupAddress to reflect the selected change? and what if we receive an update from shopify ? how to keep the pickup location ?
      const idx = this.selectedItems.indexOf(shipment.id)
      const isSelected = idx > -1
      if (isSelected) {
        this.selectedItems.splice(idx, 1)
      }
      this.rateShipment(shipment)
        .then((shipment) => {
          if (isSelected && shipment.selectedRate) {
            this.selectedItems.push(shipment.id)
          }
        })
    }
  },
  i18n: {
    messages: {
      fr: {
        title: 'Non traités',
        errors: {
          alreadyFulfilled: 'Commande déjà traitée',
          noRate: 'Pas de prix disponible',
          cancelled: 'Commande annulée',
          noShippingAddress: 'Adresse de livraison manquante',
          noPickupAddress: 'Adresse de cueillette manquante',
          nullShipment: 'Il y a eu une erreur durant le traitement des envois. Rafraichissez la page et réessayez.',
          noShopify: 'Connexion échouée, veuillez vous reconnectez à ',
          noRoutingRule: 'Il n\'y a pas de règle de routage vers un transporteur configuré dans votre compte.',
          fix: 'Corriger'
        },
        loadMore: 'Charger plus ...',
        details: 'Détails',
        fulfill: 'Traiter',
        service: 'Service',
        deliveryAddress: 'Livraison',
        pickupAddress: 'Cueillette',
        error: 'Erreur',
        order: '# Commande',
        refresh: 'Refraîchir',
        reconnect: 'Reconnexion',
        search: 'Rechercher',
        create: 'Créer une commande',
        created: 'Créé le',
        confirm: {
          delete: {
            title: 'Confirm de supression',
            msg: 'Êtes-vous certain de vouloir supprimer cette commande ?',
          }
        },
        of: 'de',
        previous: 'Page précédente',
        next: 'Page suivante',
        connectorFilter: 'Connecteur source'
      },
      en: {
        title: 'Unfulfilled',
        errors: {
          alreadyFulfilled: 'Order already fulfilled',
          noRate: 'No price available',
          cancelled: 'Order cancelled',
          noShippingAddress: 'Missing shipping address',
          noPickupAddress: 'Missing pickup address',
          nullShipment: 'There was an error fulfilling shipments, refresh and try again.',
          noShopify: 'Connection Failed , try to reconnect to',
          noRoutingRule: 'There is no carrier routing rule configured in your account',
          fix: 'Fix'
        },
        loadMore: 'Load more ...',
        details: 'Details',
        fulfill: 'Fulfill',
        service: 'Service',
        deliveryAddress: 'Delivery',
        pickupAddress: 'Pickup',
        error: 'Error',
        order: 'Order #',
        refresh: 'Refresh',
        reconnect: 'Reconnect',
        create: 'Create an order',
        search: 'Search',
        created: 'Created',
        confirm: {
          delete: {
            title: 'Confirm removal',
            msg: 'Are you sure you want to delete this order ?',
          }
        },
        of: 'of',
        previous: 'Previous page',
        next: 'Next page',
        connectorFilter: 'Source connector'
      }
    }
  }
}
</script>

<style scoped>
  .dataRow .col {
    overflow: hidden;
    overflow-wrap: anywhere;
  }

  .danger {
    background-color: hsl(48, 100%, 67%) !important;
  }

  .selected {
    background-color: rgba(192, 192, 192, 0.89) !important;
  }
</style>
