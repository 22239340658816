/**
 * This class represents a proof of delivery at a certain step for a shipment
 */
export class ProofOfDelivery {
    /** @var {string} Url to the picture for the step */
    pictureUrl = ''

    /** @var {string} Url to signature for the step */
    signatureUrl = ''

    /** @var {string} Text of the signature */
    signatureText = ''

    constructor({signatureText = '', signatureUrl = '', pictureUrl = ''} = {}) {
      this.signatureText = signatureText
      this.signatureUrl = signatureUrl
      this.pictureUrl = pictureUrl
    }

    /** @returns {boolean} Does the step contains a signature image */
    hasSignature() {
      return this.signatureUrl !== ''
    }

    /** @returns {boolean} Does the step contains a picture */
    hasPicture() {
      return this.pictureUrl !== ''
    }

    /** @returns {boolean} Does the step contains any info */
    hasInfos() {
      return this.hasSignature() || this.hasPicture()
    }
}