<!-- TODO: séparer chaque tab dans un component pour simplifier le UI ?? -->
<template>
  <v-container>
    <v-row :justify="'center'">
      <v-col cols="10">
        <v-card>
          <v-card-title>
            {{ $t('title') }}
          </v-card-title>
          <v-container>
            <v-tabs v-model="activeTab">
              <v-tab>{{ $t('tab.general') }}</v-tab>
              <v-tab v-if="$store.state.account.type === ACCOUNT_TYPE.CARRIER">
                {{ $t('tab.connector') }}
              </v-tab>
              <v-tab v-if="$store.state.account.type === ACCOUNT_TYPE.SHIPPER">
                {{ $t('tab.packingSlip') }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="activeTab">
              <!-- Account Prefs -->

              <v-tab-item>
                <v-container>
                  <v-chip class="font-weight-bold mt-4 mb-4">
                    {{ $t('general.mandatory') }}
                  </v-chip>
                  <v-form
                    ref="form"
                    v-model="valid"
                  >
                    <v-select
                      v-model="account.lang"
                      :items="langs"
                      :label="$t('general.lang')"
                      class="mb-4"
                      outlined
                      dense
                    />

                    <v-alert
                      v-if="$store.state.account.type === ACCOUNT_TYPE.CARRIER"
                      type="warning"
                      elevation="2"
                      class="mt-4 mb-6"
                    >
                      {{ $t('general.warn') }}
                    </v-alert>

                    <v-text-field
                      v-if="$store.state.account.type !== ACCOUNT_TYPE.DRIVER"
                      v-model="account.company"
                      :rules="[required]"
                      :label="$t('general.company')"
                      prepend-inner-icon="mdi-google-my-business"
                      append-icon="mdi-asterisk"
                      class="mb-4"
                      outlined
                      dense
                    />

                    <v-text-field
                      v-model="account.contactEmail"
                      :rules="[required, emailValid]"
                      prepend-inner-icon="mdi-email"
                      :label="$t('general.contactEmail')"
                      append-icon="mdi-asterisk"
                      class="mb-4"
                      outlined
                      dense
                    />
                    <v-text-field
                      v-model="account.contactPhone"
                      :rules="[required]"
                      :label="$t('general.contactPhone')"
                      prepend-inner-icon="mdi-phone"
                      append-icon="mdi-asterisk"
                      class="mb-4"
                      outlined
                      dense
                    />

                    <v-text-field
                      v-if="$store.state.account.type !== ACCOUNT_TYPE.CARRIER"
                      v-model="account.contactName"
                      :rules="[required]"
                      prepend-inner-icon="mdi-account-circle"
                      :label="$t('general.contactName')"
                      append-icon="mdi-asterisk"
                      class="mb-4"
                      outlined
                      dense
                    />

                    <AddressWidget
                      v-if="$store.state.account.type === ACCOUNT_TYPE.SHIPPER"
                      v-model="pickupAddress"
                      :label="$t('general.pickupAddress')"
                      required
                    />

                    <v-btn
                      color="primary"
                      :loading="saving"
                      :disabled="saving || !valid"
                      class="mt-4"
                      @click="save"
                    >
                      {{ $t('save') }}
                    </v-btn>
                  </v-form>
                </v-container>
              </v-tab-item>

              <!-- Connector -->
              <v-tab-item v-if="$store.state.account.type === ACCOUNT_TYPE.CARRIER">
                <v-row>
                  <v-col>
                    <v-chip class="font-weight-bold  mt-4 ml-3">
                      {{ $t('connector.help') }}
                    </v-chip>
                    <v-row>
                      <v-col>
                        <v-progress-circular
                          v-if="connectorsLoading"
                          color="primary"
                          indeterminate
                          size="50"
                        />
                        <ConnectorEdit
                          v-else
                          class="mt-4"
                          :connector="carrierConnector"
                          :edit.sync="carrierConnectorEditMode"
                          @edited="carrierConnector = $event"
                        />
                        <v-row
                          v-if="!carrierConnector"
                          class="ml-2"
                        >
                          <v-col>
                            <v-btn
                              color="primary"
                              @click.stop="addCarrierConnector"
                            >
                              {{ $t('connector.add') }}
                            </v-btn>
                            <ConnectorTypeDialog ref="connectorTypeDialog" />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>

              <!-- Settings -->
              <v-tab-item v-if="$store.state.account.type === ACCOUNT_TYPE.SHIPPER">
                <v-textarea
                  v-model="settings.packingSlipNote"
                  outlined
                  class="mt-4"
                  :label="$t('settings.packingSlipNote')"
                  :hint="$t('settings.packingSlipNoteHelp')"
                />
                <v-btn
                  color="primary"
                  class="mt-2"
                  :loading="savingSettings"
                  @click="savePackingSlipSettings"
                >
                  {{ $t('save') }}
                </v-btn>
              </v-tab-item>

            </v-tabs-items>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="showSnackbar">
      {{ snackMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import AccountService from '@/services/account'
import ConnectorEdit from '@/components/ConnectorEdit'
import {emailValid, required} from '@/utils/validation'
import AddressWidget from '@/components/AddressWidget'
import {ACCOUNT_TYPE} from '@/utils/constants'
import ShipperService from '@/services/shipper'
import ConnectorTypeDialog from '@/components/ConnectorTypeDialog'
import {normalize} from "@/utils/address";

export default {
  name: 'Account',

  components: {
    AddressWidget,
    ConnectorEdit,
    ConnectorTypeDialog,
  },
  props: {
    returnUrl: {
      type: String,
      default: '',
    }
  },
  inject: ['showErrorDialog'],

  i18n: {
    messages: {
      en: {
        title: 'My Account',
        tab: {
          general: 'General',
          connector: 'Shippers connection',
          packingSlip: 'Packing slips',
          pod: 'Proof of delivery',
        },
        general: {
          mandatory: 'Fields marked with an asterisk (*) are required.',
          lang: 'Language',
          warn: 'This information will be available on the package tracking page for all consignees.',
          contactEmail: 'Contact email',
          email: 'Email',
          contactPhone: 'Contact phone',
          contactName: 'Contact name',
          company: 'Company name',
          saved: 'Saved with success!',
          pickupAddress: 'Pickup address',
        },
        connector: {
          title: 'Connectors',
          add: 'Add a connector',
          shopify: 'Shopify',
          shipperApi: 'API',
          carrierApi: 'API',
          shipperManual: 'Manual',
          dummyShipper: 'Dummy (tests)',
          dummyCarrier: 'Dummy (tests)',
          courrierpro: 'ProgressionEXPRESS',
          uninstall: 'Are you sure you want to uninstall this connector: ',
          help: 'Configuration of your connection to EFFI shippers (who use the EFFI platform for shippers)',
        },
        settings: {
          packingSlipNote: 'Packing slip note',
          packingSlipNoteHelp: 'This note will appear at the bottom of the packing slip.'
        }
      },
      fr: {
        title: 'Mon compte',
        tab: {
          general: 'Général',
          connector: 'Connexion expéditeurs',
          packingSlip: 'Borderaux',
          pod: 'Preuves de livraison',
        },
        general: {
          mandatory: 'Les champs marqués d\'un astérisque (*) sont obligatoires.',
          lang: 'Langue',
          warn: 'Ces informations seront disponibles sur la page de suivi des colis pour tous les destinataires.',
          contactEmail: 'Courriel de contact',
          contactPhone: 'Téléphone de contact',
          contactName: 'Personne contact',
          email: 'Courriel',
          company: 'Nom d\'entreprise',
          pickupAddress: 'Adresse de cueillette',
          saved: 'Enregistré avec succès!',
        },
        connector: {
          title: 'Connecteurs',
          add: 'Ajouter un connecteur',
          shopify: 'Shopify',
          shipperApi: 'API',
          carrierApi: 'API',
          shipperManual: 'Manual',
          dummyShipper: 'Dummy (tests)',
          dummyCarrier: 'Dummy (tests)',
          courrierpro: 'ProgressionEXPRESS',
          uninstall: 'Êtes-vous certain de vouloir désinstaller ce connecteur: ',
          help: 'Configuration de votre connexion aux expéditeurs EFFI (qui utilisent la plateforme EFFI pour expéditeurs)',
        },
        settings: {
          packingSlipNote: 'Note de borderau d\'expédition',
          packingSlipNoteHelp: 'Cette note apparaîtra dans le bas des bordereaux d\'expédition.'
        }
      }
    }
  },

  data: () => ({
    ACCOUNT_TYPE,
    activeTab: null,
    showSnackbar: false,
    snackMsg: '',
    valid: false,
    saving: false,
    generatingToken: false,
    carrierConnector: null,
    carrierConnectorEditMode: false,
    connectorsLoading: false,
    langs: [
      {text: 'Français', value: 'fr'},
      {text: 'English', value: 'en'}
    ],
    pickupAddress: null,
    settings: {},
    savingSettings: false
  }),

  computed: {
    account() {
      return this.$store.state.account
    },
  },

  watch: {
    pickupAddress() {
      if (this.pickupAddress) {
        this.$store.state.account.pickupAddress = JSON.stringify(this.pickupAddress)
      } else {
        this.$store.state.account.pickupAddress = ''
      }
    }
  },

  beforeMount() {
    if (this.$store.state.account.pickupAddress) {
      try {
        this.pickupAddress = normalize(JSON.parse(this.$store.state.account.pickupAddress))
      } catch (e) {
        console.error(e)
      }
    }
  },

  mounted() {
    this.getSettings()
    if (this.$store.state.account.type === ACCOUNT_TYPE.CARRIER) {
      this.connectorsLoading = true
      ShipperService.listConnectors()
        .then((connectors) => {
          if (connectors && connectors.length > 0) {
            this.carrierConnector = connectors[0]
          }
        })
        .catch((error) => {
          console.error(error)
          this.showErrorDialog(error)
        })
        .finally(() => this.connectorsLoading = false)
    }
  },

  methods: {

    required: required,
    emailValid: emailValid,

    save() {
      this.$refs.form.validate()
      if (this.valid) {
        this.saving = true
        const account = this.$store.state.account
        AccountService.updatePrefs(account)
          .then(() => {
            this.snackMsg = this.$t('general.saved')
          })
          .catch((error) => {
            console.error(error)
            this.snackMsg = error.toString()
          }).finally(() => {
            this.saving = false
            this.showSnackbar = true
            if (this.returnUrl) {
              this.$router.push(this.returnUrl)
            }
          })
      }
    },

    savePackingSlipSettings() {
      this.savingSettings = true
      AccountService.saveSettings(this.settings)
        .catch(reason => this.showErrorDialog(reason))
        .finally(() => this.savingSettings = false)
    },

    async getSettings() {
      this.settings = await AccountService.getSettings() || {}
    },

    addCarrierConnector() {
      this.$refs.connectorTypeDialog.prompt().then((type) => {
        if (type) {
          this.carrierConnector = {
            connectorType: type,
            config: '{}'
          }
          this.carrierConnectorEditMode = true
        }
      })
    },
  }
}
</script>
