<template>
  <v-dialog
    v-model="hasNoAccountInfo"
    width="600"
    style="z-index: 10000"
  >
    <v-card>
      <v-card-title>{{ $t('title') }}</v-card-title>
      <v-card-text>
        <span v-html="$t('accountInfo.msg')" />
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          @click="hasNoAccountInfo = false"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          to="/account"
          color="primary"
        >
          {{ $t('accountInfo.action') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AccountService from '@/services/account'
export default {
  name: 'AccountInfoDialog',
  props: {},
  data() {
    return {
      hasNoAccountInfo: false,
    }
  },
  beforeMount() {
    this.checkAccountData()
  },
  methods: {
    async checkAccountData() {
      const account = await AccountService.get()
      const accountType = this.$store.state.account.type
      if (accountType === 'DRIVER'){
        this.hasNoAccountInfo = account.contactPhone === null || account.contactName === null
      } else if (accountType === 'CARRIER'){
        this.hasNoAccountInfo = account.contactEmail === null || account.contactPhone === null || account.company === null
      } else if (accountType === 'SHIPPER'){
        this.hasNoAccountInfo = account.contactName === null || account.contactPhone === null || account.contactEmail === null || account.pickupAddress === null
      }
    },
  },
  i18n: {
    messages: {
      fr: {
        title: 'Configuration',
        accountInfo: {
          msg: 'Il manque des informations pour compléter l\'inscription de votre compte. ' +
              '<br/><strong>Voulez-vous les ajouter maintenant?</strong>',
          action: 'Oui'
        }
      },
      en: {
        title: 'Configuration',
        accountInfo: {
          msg: 'There is currently missing information to complete your account. ' +
              '<br/><strong>Do you want to add account info now?</strong>',
          action: 'Yes',
        },
      }
    }
  },
}
</script>

<style scoped>

</style>
