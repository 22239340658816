<template>
  <div>
    <v-row
      v-if="type === CARRIER_CONNECTOR_TYPES.DUMMY"
      dense
    >
      <v-col>
        <v-card-text class="pa-2">
          There is no specific config, I AM A DUMB DUMMY
        </v-card-text>
      </v-col>
    </v-row>
    <div v-else>
      <v-row v-if="editMode">
        <v-col>
          <v-text-field
            v-model="config.clientNumber"
            :label="$t('clientNumber')"
            :rules="[required]"
            append-icon="mdi-asterisk"
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-row
        v-else
        dense
      >
        <v-col>
          <v-card-text class="pa-2">
            <strong>{{ $t('clientNumber') }}:</strong> {{ config.clientNumber || '' }}
          </v-card-text>
        </v-col>
      </v-row>
    </div>
    <div v-if="type === CARRIER_CONNECTOR_TYPES.COURRIER_PRO">
      <!-- TODO: lister les codes de service LIVE de CourrierPRO !!?? -->
      <v-row v-if="editMode">
        <v-col>
          <v-combobox
            v-model="config.serviceCodes"
            :label="$t('serviceCodes')"
            :rules="[required]"
            multiple
            chips
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-row
        v-else
        dense
      >
        <v-col>
          <v-card-text class="pa-2">
            <strong>{{ $t('serviceCodes') }}:</strong> {{ config.serviceCodes || '' }}
          </v-card-text>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {required} from '@/utils/validation'
import {CARRIER_CONNECTOR_TYPES} from '@/utils/constants'

export default {
  name: 'ShipperCarrierConfig',
  props: {
    type: {
      type: String,
      default: '',
    }, // Carrier related connector type,
    value: {
      type: String,
      default: '',
    }, // Config as JSON (specific to carrier type)
    editMode: Boolean,
  },
  data() {
    return {
      required,
      CARRIER_CONNECTOR_TYPES,
      config: this.value ? JSON.parse(this.value) : {},
    }
  },
  computed: {},
  watch: {
    value() {
      const c = JSON.stringify(this.config)
      if (c !== this.value) {
        this.config = this.value ? JSON.parse(this.value) : {}
      }
    },
    config: {
      deep: true,
      handler() {
        const c = JSON.stringify(this.config)
        if (c !== this.value) {
          this.$emit('input', c)
        }
      }
    }
  },
  methods: {},
  i18n: {
    messages: {
      en: {
        clientNumber: 'Client number',
        serviceCodes: 'Service codes',
      },
      fr: {
        clientNumber: 'Numéro de client',
        serviceCodes: 'Codes de service',
      }
    }
  },
}
</script>

<style scoped>

</style>
