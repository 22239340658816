<template>
  <v-app>
    <v-app-bar
      v-if="!$route.name || ($route.name && $route.name.indexOf('tracking') !== 0)"
      style="z-index: 10000"
      app
    >
      <div class="d-flex align-center ">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-6"
          contain
          :src="require('./assets/logo.png')"
          transition="scale-transition"
          width="75"
        />
      </div>

      <v-tabs
        v-if="$store.state.account"
        :key="$i18n.locale"
      >
        <v-tab
          v-for="(item, idx) in menuItems"
          :key="`menuItem${idx}`"
          :to="item.to"
        >
          {{ $t(item.title) }}
        </v-tab>
      </v-tabs>
      <v-spacer />

      <v-menu
        v-if="$store.state.user"
        offset-y
        z-index="100001"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>
              mdi-account-circle
            </v-icon>
            {{ username }}
          </v-btn>
        </template>
        <v-list
          dense
          nav
        >
          <v-list-item
            v-if="$store.state.impersonate"
            @click="returnAdmin"
          >
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('returnAdmin') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="$store.state.user"
            to="/profile"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('profile') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="$store.state.account && $store.state.account.type !== 'ADMIN' && (isAccountOwner || $store.state.impersonate)"
            to="/account"
          >
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('settings') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-else
        v-show="showLoginBtn"
        @click="login"
      >
        <v-icon>mdi-</v-icon>
        {{ $t('login') }}
      </v-btn>
      <v-menu
        offset-y
        z-index="100001"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              mdi-help-circle
            </v-icon>
          </v-btn>
        </template>
        <v-list
          dense
          nav
        >
          <v-list-item @click="showSupportDialog()">
            <v-list-item-icon class="mt-3 mr-3">
              <v-icon>mdi-handshake-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ma-1">
              <v-list-item-title>{{ $t('menu.support') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main class="main">
      <keep-alive include="Map, Shipments, ShipmentSelectMenu, RoutesDrawer, StopsList, RoutesMap">
        <router-view :key="$route.fullPath" />
      </keep-alive>

      <v-snackbar v-model="showSnackbar">
        {{ snackMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="showSnackbar = false"
          >
            {{ $t('close') }}
          </v-btn>
        </template>
      </v-snackbar>

      <!-- Global component to show error messages -->
      <ErrorDialog
        v-model="showError"
        :message="errorMessage"
      />
      <ContactSupportDialog
        v-model="showSupport"
        :details="errorMessage"
      />
      <ShopifyConsentDialog v-if="$store.state.account && $store.state.account.type === 'SHIPPER'" />
    </v-main>
  </v-app>
</template>

<style lang="scss">
  @import "scss/global.scss";
  @import "scss/utilities.scss";

  .main {
    background-color: #F2F2F2;
  }
  /** For the menu that opens from Map.vue and ShipmentMenu.vue */
  .shipment-menu {
    right: 0;
    left: initial !important;
    top: 64px !important;
    z-index: 1000 !important;
  }

  .shipment-select-menu {
    z-index: 1000 !important;
  }

  .shipment-select-menu .v-btn {
    font-family: 'Bai Jamjuree', 'Lato', 'Roboto', serif !important;
  }
</style>

<script>
import auth from '@/plugins/auth'
import ErrorDialog from '@/components/ErrorDialog'
import ContactSupportDialog from '@/components/ContactSupportDialog'
import ShopifyConsentDialog from '@/components/shipper/ShopifyConsentDialog.vue'
import {UPDATE_CONNECTORS} from "@/store"

export default {
  name: 'App',

  components: {
    ErrorDialog,
    ContactSupportDialog,
    ShopifyConsentDialog
  },

  i18n: {
    messages: {
      en: {
        menu: {
          shipments: 'Shipments',
          routes: 'Routes',
          shippers: 'Shippers',
          drivers: 'Drivers',
          users: 'Users',
          map: 'Map',
          dashboard: 'Home',
          accounts: 'Accounts',
          reports: 'Reports',
          config: 'Configuration',
          support: 'Contact support'
        },
        logout: 'Logout',
        profile: 'My profile',
        settings: 'Account settings',
        returnAdmin: 'Return to admin',
        login: 'Log in',
      },
      fr: {
        menu: {
          shipments: 'Envois',
          routes: 'Routes',
          shippers: 'Expéditeurs',
          drivers: 'Livreurs',
          users: 'Utilisateurs',
          map: 'Carte',
          dashboard: 'Accueil',
          accounts: 'Comptes',
          reports: 'Rapports',
          config: 'Configuration',
          support: 'Contacter le support'
        },
        logout: 'Déconnexion',
        profile: 'Mon profil',
        settings: 'Paramètres du compte',
        returnAdmin: 'Retour vers admin',
        login: 'Connexion',
      }
    }
  },
  data: () => ({
    showSnackbar: false,
    snackMsg: '',
    showError: false,
    showSupport: false,
    errorMessage: '',
    driverMenuTabs: [],
    errorDialogCallback: null,
    adminMenuTabs: [
      {title: 'menu.accounts', to: '/admin/accounts'},
      {title: 'menu.reports', to: '/admin/reports'},
    ],
    shipperMenuTabs: [
      {title: 'menu.dashboard', to: '/shipper/dashboard'},
      {title: 'menu.shipments', to: '/shipper/shipments/unfulfilled'},
      {title: 'menu.config', to: '/shipper/config'},
      {title: 'menu.users', to: '/shipper/users'},
    ],
    carrierMenuTabs: [],
  }),

  computed: {
    username() {
      let userName = this.$store.state.user ? this.$store.state.user.email : ''
      if (userName && this.$store.state.impersonate && this.$store.state.account) {
        userName = `${userName} (${this.$store.state.account.company || this.$store.state.account.contactEmail})`
      }
      return userName
    },
    menuItems() {
      if (!this.$store.state.account) {
        return []
      }
      switch (this.$store.state.account.type) {
      case 'CARRIER': return this.carrierMenuTabs
      case 'DRIVER': return this.driverMenuTabs
      case 'SHIPPER': return this.shipperMenuTabs
      case 'ADMIN': return this.adminMenuTabs
      default:
        return []
      }
    },
    lang() {
      return this.$store.state.user ? this.$store.state.user.lang : ''
    },
    isAccountOwner() {
      return this.$store.state.account.ownerId === this.$store.state.user.id
    },
    showLoginBtn() {
      return !this.$route.name || this.$route.name.indexOf('invitationResponse') === -1
    }
  },

  watch: {
    lang(val) {
      this.$vuetify.lang.current = val
    },
    showError(val) {
      if (!val && this.errorDialogCallback) {
        this.errorDialogCallback()
        this.errorDialogCallback = null
      }
    }
  },

  beforeMount() {
    if (this.$store.state.account && this.$store.state.account.type === 'ADMIN') {
      this.$vuetify.theme.dark = true
    }
    if (this.$store.state.account) {
      this.$store.dispatch(UPDATE_CONNECTORS)
    }
  },

  created() {
    this.$vuetify.lang.locales = {
      fr: {
        ...this.$vuetify.lang.locales.fr,
        noDataText: 'Aucune donnée'
      },
      en: {
        ...this.$vuetify.lang.locales.en,
        noDataText: 'No data'
      }
    }
  },

  provide: function() {
    return {
      showErrorDialog: this.showErrorDialog,
      showSupportDialog: this.showSupportDialog
    }
  },

  methods: {
    logout() {
      auth.logout()
    },
    showSnackBar(msg) {
      this.snackMsg = msg
      this.showSnackbar = true
    },
    showErrorDialog(error) {

      this.errorMessage = error.message ? error.message : error.toString()
      this.showError = true
      return new Promise((resolve) => {
        this.errorDialogCallback = resolve
      })
    },
    returnAdmin() {
      window.location.href = '/'
    },
    login() {
      auth.logIn()
    },
    showSupportDialog(withError) {
      this.errorMessage = withError
      this.showSupport = true
    }
  }
}
</script>
