<template>
  <v-dialog
    v-model="isActive"
    width="400"
    style="z-index: 9999"
  >
    <v-card>
      <v-card-title>{{ $t('title') }}</v-card-title>
      <v-divider />
      <v-card-text>
        <v-alert
          type="error"
          class="mt-2"
        >
          {{ message }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          @click="isActive=false"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ErrorDialog',
  props: {
    message: {
      type: String,
      default: '',
    },
    value: Boolean,
  },
  i18n: {
    messages: {
      fr: {
        title: 'Erreur'
      },
      en: {
        title: 'Error'
      }
    }
  },
  data() {
    return {
      isActive: Boolean(this.value),
    }
  },
  watch: {
    value (val) {
      this.isActive = Boolean(val)
    },
    isActive (val) {
      Boolean(val) !== this.value && this.$emit('input', val)
    },
  },
}
</script>

<style scoped>

</style>
