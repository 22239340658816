<template>
  <v-row>
    <v-col>
      <v-row v-if="!editMode">
        <v-card-text>
          URL:
          <v-btn
            :href="updatedConfig.url"
            plain
            text
            target="_blank"
          >
            {{ updatedConfig.url }}&nbsp;<v-icon>mdi-open-in-browser</v-icon>
          </v-btn>
          <br>
          API key:
          <code style="overflow-wrap: anywhere;">{{ updatedConfig.apiKey }}</code>
        </v-card-text>
      </v-row>
      <v-row v-else>
        <v-col class="pr-8">
          <v-form
            ref="form"
            v-model="isValid"
          >
            <v-text-field
              v-model="updatedConfig.url"
              placeholder="https://my.server.com/c2000rest"
              outlined
              dense
              append-icon="mdi-asterisk"
              :rules="[required, url]"
              label="API Url"
            />
            <v-textarea
              v-model="updatedConfig.apiKey"
              label="API Key"
              rows="3"
              :rules="[required]"
              auto-grow
              outlined
              dense
              append-icon="mdi-asterisk"
            />
            <v-text-field
              v-model="updatedConfig.numeroCompagnie"
              placeholder="1"
              label="Numéro de compagnie"
              :rules="[required, number]"
              type="number"
              outlined
              dense
              append-icon="mdi-asterisk"
            />
            <v-text-field
              v-model="updatedConfig.defaultClientNumber"
              placeholder="1234"
              :rules="[required]"
              label="Default client number (when not provided by shipper)"
              outlined
              dense
              append-icon="mdi-asterisk"
            />
            <!-- TODO: select with timezone list !!! -->
            <v-select
              v-model="updatedConfig.timeZone"
              placeholder="America/Montreal"
              :items="timezones"
              :rules="[required]"
              label="Server timezone"
              outlined
              dense
            />
            <v-combobox
              v-model="updatedConfig.defaultServiceCodes"
              label="Default service codes"
              :rules="[required]"
              multiple
              chips
              outlined
              dense
            />
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import tz from 'timezones.json'
import {number, required, url} from '@/utils/validation'

export default {
  name: 'CourrierPro',

  props: {
    editMode: Boolean,
    connectorConfigId: {
      type: String,
      default: () => '',
    },
    connectorConfig: {
      type: Object,
      default: () => ({
        manual: true,
        partiallyFulfilled: true,
        defaultDimensions: {},
      })
    }
  },

  i18n: {
    messages: {
      en: {},
      fr: {}
    }
  },

  data() {
    return {
      isValid: false,
      updatedConfig: this.connectorConfig,
    }
  },

  computed: {
    timezones() {
      const timezones = []
      tz.forEach(value => {
        timezones.push(...value.utc)
      })
      timezones.sort()
      return timezones
    }
  },

  methods: {
    required: required,
    number: number,
    url: url,
    valid() {
      this.$refs.form.validate()
      return this.isValid
    }
  }
}
</script>

<style scoped>

</style>