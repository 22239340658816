<template>
  <div>
    <v-dialog
        v-model="isNotConsented"
        width="600"
        style="z-index: 10000"
        persistent>
      <v-card>
        <v-card-title>{{ $t('title') }}</v-card-title>
        <v-card-text>
          <span v-html="$t('consent.msg')" />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
              @click="consent"
              color="primary"
          >
            {{ $t('consent.accept') }}
          </v-btn>
          <v-btn
              color="secondary"
              @click="confirmRefuse = true"
          >
            {{ $t('consent.refuse') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="confirmRefuse"
        style="z-index: 10000"
        persistent
        width="400">
      <v-card>
        <v-card-title>Confirmation</v-card-title>
        <v-card-text>
          <span v-html="$t('consent.confirm')" />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
              @click="refuse"
              color="primary"
          >
            {{ $t('yes') }}
          </v-btn>
          <v-btn
              color="secondary"
              @click="confirmRefuse = false"
          >
            {{ $t('no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AccountService from '@/services/account'
import ShipperService from "@/services/shipper";
import {UPDATE_ACCOUNT, UPDATE_CONNECTORS} from "@/store";
export default {
  name: 'ShopifyConsentDialog',
  props: {},
  data() {
    return {
      confirmRefuse : false,
    }
  },
  computed: {
    isNotConsented() {
      const connectors = this.$store.state.connectors
      const account = this.$store.state.account
      return connectors != null && connectors.length > 0 && connectors.findIndex((connector) => connector.connectorType === 'shopify') > -1 && !account.shopifyConsent
    },
  },
  methods: {
    async consent() {
      await AccountService.updateConsent()
      await this.$store.dispatch(UPDATE_ACCOUNT)
    },
    async refuse() {
      await ShipperService.deleteShopifyConnectors()
      this.confirmRefuse = false
      await this.$store.dispatch(UPDATE_CONNECTORS)
      window.location.hash = "/"
    }
  },
  i18n: {
    messages: {
      fr: {
        title: 'Accord de confidentialité',
        consent: {
          msg: 'Nous utiliserons, traiterons et divulguerons les renseignements fournis par les utilisateurs de Shopify et recueillis par EFFI dans le cadre des services et de l’utilisation de l’application, et ce, uniquement aux fins de la provision des services.<br/><br/>' +
              'Dans ce contexte, nous agissons comme un fournisseur de services, et conserverons et traiterons les renseignements personnels concernant vos clients et vos utilisateurs en votre nom et de façon sécuritaire pendant trente (30) jours.<br/><br/>' +
              'Consentez-vous à leur cueillette, leur divulgation et leur utilisation aux fins énoncées ?<br/><br/>' +
              '<strong>Dans le cas où vous ne consentez pas, votre intégration Shopify sera supprimée!</strong>',
          accept: "J'accepte",
          refuse: 'Je refuse',
          confirm: 'Cette action va supprimer tous vos connecteurs Shopify. Êtes-vous sûr de vouloir continuer ?'
        }
      },
      en: {
        title: 'Confidentiality agreement',
        consent: {
          msg: 'We will use, process and disclose information provided by Shopify users and collected by EFFI in connection with the Services and the use of the Application, solely for the purpose of providing the Services.<br/><br/>' +
              'In this context, we act as a service provider, and will retain and process personal information about your customers and users on your behalf in a secure manner for thirty (30) days.<br/><br/>' +
              'Do you consent to their collection, disclosure and use for the stated purposes?<br/><br/>' +
              '<strong>In case you do not consent, your Shopify integration will be removed!</strong>',
          accept: 'I agree',
          refuse: 'I refuse',
          confirm: 'This action will remove all your Shopify connectors. Are you sure you want to continue ?'
        },
      }
    }
  },
}
</script>