<template>
  <v-dialog
    v-model="isShow"
    class="dialog"
    width="1000"
    @click:outside="onCancel"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t('title') }}</span>
        <v-spacer />
        <v-icon @click="onCancel">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-divider />
      <v-row no-gutters>
        <v-col class="pa-3 pl-6">
          <span class="text-body-2">{{ $t(message) }}</span>
        </v-col>
      </v-row>
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col
            v-if="addresses.length > 1"
            cols="4"
            class="borders pa-1"
          >
            <v-list dense>
              <v-list-item-group
                v-model="selectedItem"
                mandatory
              >
                <v-list-item
                  v-for="(theAddress, i) in addresses"
                  :key="i"
                >
                  <v-list-item-icon>
                    <v-icon
                      v-if="theAddress.latitude && theAddress.longitude"
                      color="green"
                    >
                      mdi-check-circle
                    </v-icon>
                    <v-icon
                      v-else
                      color="orange"
                    >
                      mdi-alert
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ theAddress.address1 }}
                    </v-list-item-title>
                    <v-divider />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col :cols="addresses.length > 1 ? 8 : 12">
            <v-row no-gutters>
              <v-col class="borders pa-1">
                <v-row
                  no-gutters
                  class="mb-1"
                >
                  <v-col cols="9">
                    <v-text-field
                      v-model="address.address1"
                      :label="$t('address')"
                      dense
                      outlined
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                    class="pl-1"
                  >
                    <v-text-field
                      v-model="address.address2"
                      :label="$t('appt')"
                      dense
                      outlined
                      hide-details="auto"
                    />
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="mb-1"
                >
                  <v-col cols="6">
                    <v-text-field
                      v-model="address.city"
                      :label="$t('city')"
                      dense
                      outlined
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                    class="pl-1"
                  >
                    <v-text-field
                      v-model="address.province"
                      :label="$t('province')"
                      dense
                      outlined
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                    class="pl-1"
                  >
                    <v-text-field
                      v-model="address.postalcode"
                      :label="$t('postalCode')"
                      dense
                      outlined
                      hide-details="auto"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class="d-flex justify-center pb-1 mt-1">
                    <v-btn
                      color="primary"
                      @click="onManualPosition"
                    >
                      {{ $t('manual') }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="ml-1"
                      @click="onGeocode"
                    >
                      {{ $t('geocode') }}
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- TODO: Suggestions-->
              </v-col>
            </v-row>
            <v-row
              no-gutters
              class="mt-1"
            >
              <v-col class="borders">
                <l-map
                  ref="map"
                  style="height: 300px; width: 100%;"
                  :zoom.sync="zoom"
                  :options="{zoomControl: false}"
                  :center.sync="mapCenter"
                >
                  <l-tile-layer :url="url" />
                  <l-marker
                    v-if="address.latitude && address.longitude"
                    :key="address.latitude"
                    :lat-lng="[address.latitude, address.longitude]"
                    :draggable="true"
                    @dragend="onDragEnd"
                  />
                  <l-control position="topright">
                    <GoogleAutoComplete
                      ref="autoComplete"
                      :placeholder="$t('search')"
                      style="width: 300px;"
                      @selection="onPlaceSelected"
                    />
                  </l-control>
                  <l-control-zoom position="topright" />
                </l-map>
              </v-col>
            </v-row>
            <v-overlay
              absolute
              :value="geocoding"
              z-index="9999"
            >
              <v-progress-circular
                indeterminate
                color="red"
                size="40"
              />
            </v-overlay>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          :disabled="!isCompleted"
          @click="onComplete"
        >
          {{ $t('confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmDialog
      v-model="confirmAddressUpdate"
      :title="$t('confirmAddressUpdate.title')"
      :message="$t('confirmAddressUpdate.message')"
      @confirm="onConfirmAddressUpdate"
    />
  </v-dialog>
</template>

<style scoped>
  .dialog {
    z-index: 9998;
  }
  .borders {
    border-color: rgba(0, 0, 0, 0.12);
    border-width: 1px;
    border-style: solid;
  }
</style>

<script>
import {LMap, LTileLayer, LMarker, LControl, LControlZoom} from 'vue2-leaflet'
import {DEFAULT_MAP_URL} from '@/globals'
import GeocodeService from '@/services/geocode'
import GoogleAutoComplete from '@/components/GoogleAutoComplete'
import ConfirmDialog from '@/components/ConfirmDialog'
import {CANCEL} from '@/utils/errors'

export default {
  name: 'AddressGeocodeDialog',

  components: {
    LMap, LTileLayer, LMarker, LControl, LControlZoom,
    GoogleAutoComplete,
    ConfirmDialog,
  },

  inject: ['showErrorDialog'],

  i18n: {
    messages: {
      fr: {
        title: 'Positionnement d\'adresse',
        msg: {
          oneAddress: 'Veuillez géo-positionner l\'adresse',
        },
        manual: 'Positionner au centre de la carte',
        geocode: 'Positionner avec l\'adresse',
        confirm: 'Confirmer le géo-positionnement',
        city: 'Ville',
        province: 'Province',
        postalCode: 'Code postal',
        appt: 'Suite / appt.',
        address: 'Adresse',
        confirmAddressUpdate: {
          title: 'Mettre à jour l\'adresse?',
          message: 'Désirez-vous mettre à jour l\'adresse avec les informations de l\'endroit sélectionné?'
        },
        search: 'Chercher une adresse ...'
      },
      en: {
        title: 'Address geolocation',
        msg: {
          oneAddress: 'Please geocode the address',
        },
        manual: 'Place in the center of the map',
        geocode: 'Position with the address',
        confirm: 'Confirm geolocation',
        city: 'City',
        province: 'Province',
        postalCode: 'Postal code',
        appt: 'Suite / appt.',
        address: 'Address',
        confirmAddressUpdate: {
          title: 'Update address?',
          message: 'Do you want to update the address with the information of the selected location?'
        },
        search: 'Search address ...'
      }
    }
  },

  data() {
    return {
      addresses: [],
      address: {},
      suggestions: [],
      url: DEFAULT_MAP_URL,
      isShow: false,
      geocoding: false,
      selectedItem: 0,
      mapCenter: [46.3727442, -72.6658289],
      zoom: 8,
      message: '',
      resolveCallback: null,
      rejectCallback: null,
      confirmAddressUpdate: false,
    }
  },

  computed: {
    isCompleted() {
      let completed = true
      for (const address of this.addresses) {
        completed = completed && Boolean(address.latitude && address.longitude)
      }
      return completed
    }
  },

  watch: {
    selectedItem: function(newValue) {
      this.address = this.addresses[newValue]
    },
  },

  methods: {

    geocode(addresses, message) {
      this.isShow = true
      this.message = message
      this.addresses = addresses
      this.selectedItem = 0
      this.address = addresses[0]
      this.error = ''

      // Dirty fix d'une map dans un dialog ...
      setTimeout(() => {
        this.$refs.map.mapObject.invalidateSize()
      }, 100)
      // this.fetchSuggestions()

      // Return a promise and save callbacks for later use
      return new Promise((resolve, reject) => {
        this.resolveCallback = resolve
        this.rejectCallback = reject
      })
    },

    fetchSuggestions() {
      this.geocoding = true
      GeocodeService.searchAddress(this.address)
        .then((suggestions) => {
          console.log('Suggestions', suggestions)
          this.suggestions = suggestions

        })
        .catch((error) => {
          alert(error)
        })
        .finally(() => {
          this.geocoding = false
        })
    },

    onManualPosition() {
      // Get current map center
      const center = this.$refs.map.mapObject.getCenter()
      this.$set(this.address, 'latitude', center.lat)
      this.$set(this.address, 'longitude', center.lng)
      this.$set(this.addresses, this.selectedItem, this.address)
    },

    onGeocode() {
      this.geocoding = true
      GeocodeService.geocodeAddress(this.address, false)
        .then((address) => {
          this.$set(this.address, 'longitude', address.longitude)
          this.$set(this.address, 'latitude', address.latitude)
          this.$set(this.address, 'city', address.city)
          this.$set(this.address, 'country', address.country)
          // this.$set(this.address, 'country_code', address.country_code)
          this.$set(this.address, 'province', address.province)
          // this.$set(this.address, 'province_code', address.province_code)
          this.$set(this.address, 'postalcode', address.postalcode)
          this.$set(this.address, 'address2', address.address2)
          this.$set(this.address, 'address1', address.address1)
          this.$refs.map.mapObject.flyTo([this.address.latitude, this.address.longitude], 12)
        })
        .catch((error) => {
          this.address.longitude = null
          this.address.latitude = null
          this.showErrorDialog(error)
        })
        .finally(() => {
          this.$set(this.addresses, this.selectedItem, this.address)
          this.geocoding = false
        })
    },

    onDragEnd(event) {
      const newPosition = event.target.getLatLng()
      this.address.latitude = newPosition.lat
      this.address.longitude = newPosition.lng
      this.$set(this.addresses, this.selectedItem, this.address)
    },

    onCancel() {
      this.isShow = false
      this.rejectCallback(CANCEL)
    },

    onComplete() {
      this.isShow = false
      this.resolveCallback(this.addresses)
    },

    onPlaceSelected(place) {
      if (place && place.geometry) {
        this.$refs.map.mapObject.flyTo([place.geometry.location.lat, place.geometry.location.lng], 12)
        // Check if it is a precise address and ask user to use this address !!!
        for (const type of place.types) {
          if (type === 'street_address' || type === 'premise' || type === 'subpremise') {
            this.confirmAddressUpdate = true
          }
        }
      }
    },

    onConfirmAddressUpdate(result) {
      if (result === 'yes') {
        const place = this.$refs.autoComplete.placeDetails
        console.log(place)
        this.$set(this.address, 'longitude', place.geometry.location.lng)
        this.$set(this.address, 'latitude', place.geometry.location.lat)
        // TODO: devrait faire ca dans le backend ???
        this.$set(this.address, 'address1', '')
        for (const component of place.address_components) {
          for (const type of component.types) {
            switch (type) {
            case 'street_address':
              this.$set(this.address, 'address1', component.long_name)
              break
            case 'street_number': {
              const civic = component.long_name
              if (this.address.address1) {
                this.$set(this.address, 'address1', `${civic} ${this.address.address1}`)
              } else {
                this.$set(this.address, 'address1', civic)
              }
              break
            }
            case 'route': {
              const street = component.long_name
              if (this.address.address1) {
                this.$set(this.address, 'address1', `${this.address.address1} ${street}`)
              } else {
                this.$set(this.address, 'address1', street)
              }
              break
            }
            case 'room':
              this.$set(this.address, 'address2', component.long_name)
              break
            case 'locality':
              this.$set(this.address, 'city', component.long_name)
              break
            case 'administrative_area_level_1':
              this.$set(this.address, 'province', component.long_name)
              this.$set(this.address, 'provinceCode', component.short_name)
              break
            case 'country':
              this.$set(this.address, 'country', component.long_name)
              this.$set(this.address, 'countryCode', component.short_name)
              break
            case 'postalcode':
              this.$set(this.address, 'postalcode', component.long_name)
              break
            }
          }
        }
      }
    }
  }
}
</script>
