<template>
  <v-alert
    :value="alert"
    prominent
    type="error"
    dismissible
    @click="dismiss"
  >
    <v-row align="center">
      <v-col class="grow">
        {{ errorMsg || $t('genericError') }}
      </v-col>
      <v-col
        v-if="withSupportReport"
        class="shrink"
      >
        <v-btn @click="reportError">
          {{ $t('reportError') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>
<script>
export default {
  name: 'ErrorAlert',
  inject: ['showSupportDialog'],
  props: {
    errorMsg: {
      type: String,
      default: ''
    },
    technicalErrorDetails: {
      type: String,
      default: ''
    },
    withSupportReport: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      alert: false,
    }
  },

  watch: {
    errorMsg(){
      this.alert = Boolean(this.errorMsg)
    }
  },
  i18n: {
    messages: {
      en: {
        reportError: 'Report error',
        genericError: 'Une erreur est survenue.',
      },
      fr: {
        reportError: 'Rapporter le problème',
        genericError: 'Une erreur est survenue.',
      },
    }
  },
  methods: {
    reportError() {
      this.showSupportDialog(this.technicalErrorDetails)
      this.alert = false
      this.$emit('clearError')
    },
    dismiss() {
      this.$emit('clearError')
    }
  }
}
</script>
