<template>
  <div class="mx-12">
    <ErrorAlert
      style="margin-top: 15px"
      :error-msg="errorMsg"
      :with-support-report="technicalErrorDetails.withAction"
      :technical-error-details="technicalErrorDetails.details"
      @clearError="errorMsg = '' "
    />
    <div>
      <div class="list-container">
        <v-col>
          <h2>{{ $tc('list.title', total) }} </h2>
        </v-col>
        <v-spacer />
        <v-btn
          v-show="isAccountOwner"
          color="primary"
          style="margin-right: 10px"
          :to="`${invitePath}?inviteType=user&returnUrl=${$route.path}`"
        >
          {{ $t('invite') }}
        </v-btn>
      </div>
      <SimpleList
        :items="items"
        :total="total"
        :loading="loading"
        item-key="email"
        :headers="headers"
        :empty-set-text="$t('list.emptyset')"
        @fetchRequested="fetchUsers"
      >
        <template
          v-if="isAccountOwner"
          v-slot:extra-header
        >
          <div style="width: 40px" />
        </template>
        <template
          v-slot:item.name="{item}"
        >
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="item.userId === accountOwnerId"
                v-bind="attrs"
                v-on="on"
              >
                mdi-key-variant
              </v-icon>
            </template>
            <span>{{ $t('list.ownerTooltip') }}</span>
          </v-tooltip>
        </template>
        <template
          v-if="isAccountOwner"
          v-slot:extra-controls="{item}"
        >
          <div
            style="display: flex;justify-content: flex-end"
          >
            <v-menu
              left
              offset-y
              open-on-hover
              rounded="lg"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-btn
                    :disabled="item.userId === accountOwnerId || item.removed === 1 || (item.invitationStatus !== 1 && item.invitationStatus !== 5)"
                    text
                    @click="transferOwnership(item)"
                  >
                    {{ $t('list.makeOwner') }}
                  </v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn
                    :disabled="item.userId === accountOwnerId || item.removed === 1"
                    text
                    @click="deleteUser(item)"
                  >
                    {{ $t('list.deactivate') }}
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </SimpleList>
    </div>
    <ConfirmDialog
      ref="userActionsConfirmDialog"
      :message="$t(confirmMessage, {name: targetedUser.name})"
    />
  </div>
</template>

<script>
import SimpleList from '../components/SimpleList'
import ConfirmDialog from '../components/ConfirmDialog'
import ErrorAlert from '@/components/ErrorAlert'
import AccountService from '@/services/account'
export default {
  name: 'Users',
  components: {SimpleList, ConfirmDialog, ErrorAlert},
  props: {
    invitePath: String,
    listUsers: Function,
    deactivateUser: Function,
  },
  data: () => ({
    items: [],
    errorMsg: '',
    total: 0,
    targetedUser: {name: ''},
    loading: true,
    confirmMessage: 'deleteConfirm',
    userOwnerId: null,
    technicalErrorDetails: {withAction: false, details: ''},
  }),
  computed: {
    headers() {
      return [
        {text: this.$t('list.name'), value: 'name', width: 352},
        {text: this.$t('list.phone'), value: 'phone', width: 352},
        {text: this.$t('list.email'), value: 'email', width: 352},
        {text: this.$t('list.lang'), value: 'lang', width: 188},
        {text: this.$t('list.invitationStatus'), value: 'invitationStatus', width: 352, formatter: this.formatInvitationStatus},
      ]
    },
    accountOwnerId(){
      return this.$store.state.account.ownerId
    },
    isAccountOwner() {
      return (this.$store.state.account.ownerId === this.$store.state.user.id) || this.$store.state.impersonate
    }
  },
  mounted() {
    this.userOwnerId = this.$store.state.account.ownerId
    this.fetchUsers({}, 50, 1)
  },
  methods: {
    formatInvitationStatus(invStatus) {
      return this.$t(`list.status${invStatus}`)
    },
    transferOwnership(user) {
      this.targetedUser = user
      this.confirmMessage = 'transferOwnerShipConfirm'
      this.$refs.userActionsConfirmDialog.prompt()
        .then(value => {
          if (value === 'yes') {
            AccountService.transferAccountOwnerShip(this.$store.state.account.id, user.userId).then((newOwnerId) => {
              this.userOwnerId = newOwnerId
            }).catch((error) => {
              this.technicalErrorDetails = {details: error.toString()}
              this.technicalErrorDetails.withAction = true
              this.errorMsg = this.$i18n.t('transferOwnerShipError')
            })
          }
        })

    },
    deleteUser(user) {
      this.targetedUser = user
      this.confirmMessage = 'deleteConfirm'
      this.$refs.userActionsConfirmDialog.prompt()
        .then(value => {
          if (value === 'yes') {
            this.loading = true
            this.deactivateUser(user.id).then(() => {
              const deleteIndex = this.items.findIndex(item => (item.id === user.id))
              this.items.splice(deleteIndex, 1)
            }).catch((error) => {
              this.technicalErrorDetails = {details: error.toString()}
              this.technicalErrorDetails.withAction = true
              this.errorMsg = this.$i18n.t('deleteError')
            }).finally(() =>
                this.loading = false
            )
          }
        })
    },
    fetchUsers(searchParams, limit, page) {
      this.loading = true
      this.listUsers(searchParams, limit, (page - 1) * limit).then(data => {
        this.items = data.users
        this.total = data.total
      }).catch((error) => {
        this.technicalErrorDetails = {details: error.toString()}
        this.technicalErrorDetails.withAction = true
      }).finally(() => {
        this.loading = false
      })
    },
  },
  i18n: {
    messages: {
      fr: {
        invite: 'Inviter',
        list: {
          title: 'Liste des utilisateurs | Liste des utilisateurs ({n})',
          email: 'Courriel',
          name: 'Nom',
          phone: 'Téléphone',
          lang: 'Langue',
          deactivate: 'Désactiver',
          makeOwner: 'Mettre propriétaire',
          invitationStatus: 'Invitation',
          emptyset: 'Aucun utilisateurs trouvé',
          status0: 'Invitation envoyée',
          status1: 'Invitation acceptée',
          status2: 'Invitation refusée',
          status3: 'Invitation en attente',
          status4: 'Invitation en erreur (doublon)',
          status5: '-',
          ownerTooltip: 'Propriétaire',
          you: 'Vous',
        },
        transferOwnerShipError: 'Une erreur est survenue lors du transfert de la propriété du compte.',
        deleteError: 'Une erreur est survenue lors de la désactivation de l\'utilisateur.',
        search: {
          query: 'Rechercher',
        },
        transferOwnerShipConfirm: 'Vous êtes sur le point de <b>transférer la propriété</b> de ce compte EFFI à l’utilisateur <b>{name}</b>. En transférant la propriété du compte à un autre utilisateur, vous n’aurez plus accès à la section « Paramètres » et vous ne pourrez plus gérer la propriété du compte. Voulez-vous continuer?',
        deleteConfirm: 'Vous êtes sur le point de <b>désactiver</b> l’utilisateur <b>{name}</b>.<br>Cet utilisateur n’aura donc plus accès à votre compte EFFI et ne sera plus affiché dans la liste des utilisateurs.<br>Voulez-vous continuer?'
      },
      en: {
        invite: 'Invite',
        list: {
          title: 'Users list | Users list ({n})',
          unlink: 'Unlink',
          edit: 'Edit',
          number: 'Number',
          you: 'You',
          emptyset: 'No user found',
          deactivate: 'Deactivate',
          makeOwner: 'Make owner',
          name: 'Name',
          phone: 'Phone',
          lang: 'Lang',
          email: 'Email',
          invitationStatus: 'Invitation',
          status0: 'Invitation sent',
          status1: 'Invitation accepted',
          status2: 'Invitation declined',
          status3: 'Invitation pending',
          status4: 'Invitation error (duplicate)',
          status5: '-',
          ownerTooltip: 'Owner',
        },
        search: {
          query: 'Search',
        },
        transferOwnerShipError: 'An error occured when transfering account ownership.',
        deleteError: 'An error occured while deactivating the user.',
        transferOwnerShipConfirm: 'You\'re about to <b>transfer the ownership</b> of this EFFI account to user <b>{name}</b>.<br>By transferring the account ownership to another user, you will no longer have access to the "Settings" section and you will no longer be able to manage the ownership of the account.<br>Do you want to continue?',
        deleteConfirm: 'You\'re about to <b>deactivate</b> user <b>{name}</b>. This user will therefore no longer have access to your EFFI account and will no longer be displayed in the list of users. Do you want to continue?'
      }
    }
  },
}
</script>

<style scoped>
  .list-container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    padding: 0;
    list-style: none;
  }
  .section-title {
    font-weight: bold;
  }

  .selected {
    background-color: #D6D9D9D9
  }

</style>