import ShipperService from '@/services/shipper'
import {v4 as uuidv4} from 'uuid'

const printJobs = {} // print jobs 'cache'

export const PrintJobType = {
  LABEL: 'LABEL',
  SLIP: 'SLIP'
}

export const PrintService = {

  async getPrintStatus(jobId) {
    const job = printJobs[jobId]
    try {
      const result = await ShipperService.getPrintShipmentsStatus(job.objectKey)
      if (result === true) {
        const url = await ShipperService.getPrintShipmentsUrl(job.objectKey)
        job.resolve(url)
        delete printJobs[jobId]
      } else {
        job.timeoutNumber = setTimeout(() => this.getPrintStatus(jobId), 1000)
      }
    } catch (e) {
      console.error(e)
      job.reject(e)
    }
  },

  async startPrint(type, trackingIds) {
    return new Promise((resolve, reject) => {
      ShipperService.startPrintShipments(trackingIds, type)
        .then(objectKey => {
          const jobId = uuidv4()
          const job = {objectKey, resolve, reject}
          printJobs[jobId] = job
          job.timeoutNumber = setTimeout(() => this.getPrintStatus(jobId), 1000)
        })
        .catch(reason => {
          console.error(reason)
          reject(reason)
        })
    })
  },

}
export default PrintService