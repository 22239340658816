import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// Global messages !!! Do not use this if only for one component!
const messages = {
  en: {
    save: 'Save',
    close: 'Close',
    cancel: 'Cancel',
    edit: 'Edit',
    delete: 'Delete',
    add: 'Add',
    yes: 'Yes',
    no: 'No',
    validations: {
      required: 'This field is required.',
      email: 'Email is not valid.',
      number: 'Invalid number.',
      url: 'Invalid url.',
      currency: 'Invalid amount.',
      maxLength: 'The entered value is too long.'
    },
    status: {
      TOPLAN: 'To plan',
      PLANNED: 'Planned',
      PICKEDUP: 'Picked up',
      ONTHEWAY: 'On the way',
      PROBLEM: 'Problem',
      DELIVERED: 'Delivered',
      ERROR: 'Error',
      task: {
        0: 'To do',
        1: 'Done',
        2: 'Problem',
      },
      stop: {
        0: 'To sequence',
        1: 'To do',
        2: 'Done',
        3: 'Start',
        4: 'End',
        5: 'Problem'
      }
    },
    statusCode: {
      XB: 'Information received by the carrier',
      X4: 'Order picked up by the carrier',
      AF: 'Carrier Departed Pick-up Location with Shipment',
      X6: 'Order in course of delivery',
      AH: 'Driver attempted delivery',
      D1: 'Order delivered',
      A3: 'Shipment returned to sender',
      AG: 'Scheduled Delivery',
      AP: 'Delivery not completed',
      CA: 'Shipment canceled',
      SD: 'Shipment Delayed',
      SC: 'Scan out problem',
      OD: 'Out for delivery',
      BC: 'Order returned to warehouse',
    },
    searchNoData: 'No matched results',
    today: 'Today',
    errors: {
      generic: 'An error has occurred'
    },
    connector: {
      carrierApi: 'API',
      courrierpro: 'CourrierPRO',
      dummyCarrier: 'Dummy',
      effi: 'Effi',
      shopify: 'Shopify',
      shipperManual: 'Manual',
      dummyShipper: 'Dummy',
      shipperApi: 'API',
    }
  },
  fr: {
    save: 'Enregistrer',
    close: 'Fermer',
    cancel: 'Annuler',
    edit: 'Modifier',
    delete: 'Supprimer',
    add: 'Ajouter',
    yes: 'Oui',
    no: 'Non',
    validations: {
      required: 'Ce champ est obligatoire.',
      email: 'Le courriel n\'est pas valide.',
      number: 'Numéro invalide.',
      url: 'URL invalide.',
      currency: 'Montant invalide.',
      maxLength: 'La valeur entrée est trop longue.'
    },
    status: {
      TOPLAN: 'À planifier',
      PLANNED: 'Planifié',
      PICKEDUP: 'Cueilli',
      ONTHEWAY: 'En route',
      PROBLEM: 'Problème',
      DELIVERED: 'Livré',
      ERROR: 'En erreur',
      task: {
        0: 'À compléter',
        1: 'Complétée',
        2: 'Problème',
      },
      stop: {
        0: 'À séquencer',
        1: 'À compléter',
        2: 'Complété',
        3: 'Début',
        4: 'Fin',
        5: 'Problème'
      }
    },
    statusCode: {
      XB: 'Informations reçues par le transporteur',
      X4: 'Commande cueillie par le transporteur',
      AF: 'Le transporteur a quitté le lieu de cueillette avec l\'envoi',
      X6: 'Commande en cours de livraison',
      AH: 'Tentative de livraison effectuée',
      D1: 'Commande livrée',
      A3: 'Commande retournée à l\'expéditeur',
      AG: 'Livraison planifiée',
      AP: 'Livraison non effectuée',
      CA: 'Envoi annulé',
      SD: 'Envoi retardé',
      SC: 'Problème de scan de sortie',
      OD: 'En cours de livraison',
      BC: 'Commande retournée à l’entrepôt'
    },
    today: 'Aujourd\'hui',
    errors: {
      generic: 'Une erreur est survenue lors de l\'opération'
    },
    connector: {
      carrierApi: 'API',
      courrierpro: 'CourrierPRO',
      dummyCarrier: 'Dummy',
      effi: 'Effi',
      shopify: 'Shopify',
      shipperManual: 'Manuel',
      dummyShipper: 'Dummy',
      shipperApi: 'API',
    }
  }
}

// VueI18n instance
const i18n = new VueI18n({
  locale: getLocale(),
  silentFallbackWarn: true,
  messages
})

export default i18n

export function getLocale() {
  let lang = window.navigator.languages ? window.navigator.languages[0] : null
  lang = lang || window.navigator.language || window.navigator.userLanguage
  let shortLang = lang
  if (shortLang.indexOf('-') !== -1) {
    shortLang = shortLang.split('-')[0]
  }
  if (shortLang.indexOf('_') !== -1) {
    shortLang = shortLang.split('_')[0]
  }
  return shortLang.includes('fr') || shortLang.includes('en') ? shortLang : 'en'
}

export function changeLocale(locale) {
  console.log(`Setting locale to ${locale}`)
  i18n.locale = locale
  Vue.dayjs.locale(locale)
}