
<template>
  <v-row justify="center">
    <v-snackbar v-model="showSnackBar">
      {{ $t('success') }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="showSnackBar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="isActive"
      width="600"
      style="z-index: 10000"
    >
      <v-card
        v-if="isActive"
      >
        <v-card-title class="mx-1">
          {{ $t('title') }}
        </v-card-title>
        <v-card-text
          class="pb-0"
        >
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-container>
              <v-row
                id="textFields"
                class="pt-2"
              >
                <v-text-field
                  v-model="accountInfo.company"
                  :rules="[required]"
                  :label="$t('general.company')"
                  append-icon="mdi-asterisk"
                  prepend-inner-icon="mdi-google-my-business"
                  outlined
                  dense
                  style="width: 100%"
                />
                <v-text-field
                  v-model="accountInfo.contactEmail"
                  :rules="[required, emailValid]"
                  prepend-inner-icon="mdi-email"
                  :label="$t('general.contactEmail')"
                  append-icon="mdi-asterisk"
                  outlined
                  dense
                  style="width: 100%"
                />
                <v-text-field
                  v-model="accountInfo.contactName"
                  :rules="[required]"
                  prepend-inner-icon="mdi-account-circle"
                  :label="$t('general.contactName')"
                  append-icon="mdi-asterisk"
                  outlined
                  style="width: 100%"
                  dense
                />
                <v-textarea
                  v-model="problemInfo"
                  :rules="[required]"
                  outlined
                  rows="5"
                  :label="$t('problem')"
                  :hint="$t('problemHint')"
                />
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            class="mb-4"
            @click="isActive = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            class="mb-4"
            @click="sendSupportMail"
          >
            {{ $t('send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AccountService from '@/services/account'
import {required, emailValid} from '@/utils/validation'

export default {

  name: 'ContactSupportDialog',

  props: {
    value: Boolean,
    details: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      isActive: Boolean(this.value),
      showSnackBar: false,
      accountInfo: {},
      valid: false,
      problemInfo: '',
    }
  },

  watch: {
    value (val) {
      this.isActive = Boolean(val)
      this.problemInfo = ''
      if (this.isActive && this.details) {
        this.problemInfo = this.$i18n.t('technicalProblemInfo') + this.details
      }
    },
    async isActive (val) {
      this.accountInfo = await AccountService.get()
      // TODO: Set email to user instead of account ?
      Boolean(val) !== this.value && this.$emit('input', val)
    },
  },
  async beforeMount() {
    if (this.$store.state.account) { // si on est pas logged in, ça sers a rien caller ça, ça fait une erreur de login required!
      this.accountInfo = await AccountService.get()
      if (this.accountInfo === '') {
        this.accountInfo = {}
      }
    }
  },
  methods: {
    required: required,
    emailValid: emailValid,
    async sendSupportMail(){
      this.$refs.form.validate()
      const ticket = {
        'contactName': this.accountInfo.contactName,
        'companyName': this.accountInfo.company,
        'contactEmail': this.accountInfo.contactEmail,
        'accountType': this.accountInfo.type || 'Sans Connexion',
        'details': this.problemInfo
      }
      if (this.valid){
        const resp = await AccountService.sendSupportTicket(ticket)
        this.isActive = false
        this.showSnackBar = true
        return resp
      }
    }
  },
  i18n: {
    messages: {
      fr: {
        send: 'Envoyer',
        title: 'Contacter le support',
        success: 'Un billet a été ouvert, vous serez contacté sous peu.',
        problem: 'Décrivez votre problème ici!',
        problemHint: 'N\'omettez aucun détail!',
        general: {
          contactEmail: 'Courriel de contact',
          contactName: 'Personne contact',
          company: 'Nom d\'entreprise',
        },
        technicalProblemInfo: 'Voici les détails de l\'erreur détectée :\n'

      },
      en: {
        send: 'Send',
        title: 'Contact support',
        success: 'A ticket has been opened, you will be contacted shortly.',
        problem: 'Tell us about your problem here!',
        problemHint: 'Give us every little detail!',
        general: {
          contactEmail: 'Contact email',
          contactName: 'Contact name',
          company: 'Company name',
        },
        technicalProblemInfo: 'Here are the details of the detected error: \n'
      }
    }
  },
}

</script>

<style scoped>
  #textFields >>> textarea
  {
    margin-right: 2px;
    margin-bottom: 3px;
    scrollbar-color: #CBCBCB white;
  }
  #textFields >>> textarea::-webkit-scrollbar{
    background-color: white
  }
  #textFields >>> textarea::-webkit-scrollbar-thumb{
    background-color: #CBCBCB
  }
</style>