<template>
  <v-row>
    <v-col>
      <v-chip v-if="editMode">
        No specific parameters ... it is dummy!
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DummyShipper',

  props: {
    editMode: Boolean,
    connectorConfigId: {
      type: String,
      default: () => '',
    },
    connectorConfig: {
      type: Object,
      default: () => ({
        manual: true,
        partiallyFulfilled: true,
        defaultDimensions: {},
      })
    }
  },

  data() {
    return {
      isValid: false,
      updatedConfig: this.connectorConfig,
    }
  },

  mounted() {
    this.isValid = true
  },

  methods: {
    valid() {
      return this.valid
    }
  }
}
</script>

<style scoped>

</style>
