<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          @click="testCrash"
        >
          Test Crash
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Sentry',
  methods: {
    testCrash() {
      throw new Error('Test crash!')
    }
  }
}
</script>

<style scoped>

</style>