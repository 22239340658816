import lodash from 'lodash'

// Backward compatibility conversion with old address formats ...
export const normalize = (address) => {
  const adr = lodash.cloneDeep(address)
  if (adr.address_line_1) {
    adr.address1 = adr.address_line_1
    delete adr.address_line_1
  }
  if (adr.address_line_2) {
    adr.address2 = adr.address_line_2
    delete adr.address_line_2
  }
  if (adr.postal_code) {
    adr.postalcode = adr.postal_code
    delete adr.postal_code
  }
  if (adr.zip) {
    adr.postalcode = adr.zip
    delete adr.zip
  }
  if (adr.name) {
    adr.contact = adr.name
    delete adr.name
  }
  return adr
}