import Vue from 'vue'
import Vuex from 'vuex'
import browserLang from 'browser-lang'
import getters from './getters'
import {changeLocale} from '@/plugins/i18n'
import * as Sentry from '@sentry/vue'
import ShipperService from "@/services/shipper";
import AccountService from "@/services/account";

Vue.use(Vuex)

// initial state
const state = () => ({
  account: null,
  user: null,
  impersonate: null,
  connectors: null,
})

// actions
export const UPDATE_CONNECTORS = 'updateConnectors'
export const UPDATE_ACCOUNT = 'updateAccount'
const actions = {
  updateConnectors(context) {
    ShipperService.listConnectors().then(connectors => {
      context.commit(SET_CONNECTORS, connectors)
    })
  },
  updateAccount(context) {
    AccountService.get().then(account => {
      context.commit(SET_CURRENT_ACCOUNT, account)
    })
  }
}

// mutations
export const SET_IMPERSONATE = 'setImpersonate'
export const SET_CURRENT_ACCOUNT = 'setCurrentAccount'
export const SET_CURRENT_USER = 'setCurrentUser'
export const SET_NEW_ACCOUNT_OWNER = 'setAccountOwner'
export const SET_CONNECTORS = 'setConnectors'
const mutations = {
  setCurrentAccount(state, account) {
    state.account = account
    Sentry.setUser(account)
  },
  setCurrentUser(state, user) {
    // Resolve current locale !!!
    let lang = user.lang || this.account?.lang
    if (!lang) {
      lang = browserLang({
        languages: ['fr', 'en'],
        fallback: 'fr'
      })
    }
    changeLocale(lang)
    state.user = user
  },
  setImpersonate(state, impersonate) {
    state.impersonate = impersonate
  },
  setAccountOwner(state, userId) {
    state.account.ownerId = userId
  },
  setConnectors(state, connectors) {
    state.connectors = connectors
  }
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
})
