<template>
  <div>
    <v-row class="mt-4">
      <v-col>
        <h2>{{ $t('title') }}</h2>
      </v-col>
      <v-col
        class="text-right"
        cols="10"
      >
        <v-btn
          v-if="cancelableTrackingIds.length > 0"
          color="primary"
          :disabled="loading"
          :loading="loading"
          class="ml-2"
          @click="cancelShipments"
        >
          <v-icon>mdi-cancel</v-icon>
          {{ $t('cancel') }} ({{ cancelableTrackingIds.length }})
        </v-btn>
        <v-btn
          v-if="selectedItems.length > 0"
          color="primary"
          :disabled="loading"
          :loading="printing"
          class="ml-2"
          @click="print(PrintJobType.SLIP)"
        >
          <v-icon>mdi-print</v-icon>
          {{ $t('print.slip') }} ({{ selectedItems.length }})
        </v-btn>
        <v-btn
          v-if="selectedItems.length > 0"
          color="primary"
          :disabled="loading"
          :loading="printing"
          class="ml-2"
          @click="print(PrintJobType.LABEL)"
        >
          <v-icon>mdi-print</v-icon>
          {{ $t('print.label') }} ({{ selectedItems.length }})
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :disabled="loading"
              style="cursor: pointer;"
              v-bind="attrs"
              @click="refresh()"
              v-on="on"
            >
              mdi-replay
            </v-icon>
          </template>
          <span>{{ $t('refresh') }}</span>
        </v-tooltip>
      </v-col>
      <v-spacer />
      <v-col cols="4">
        <v-row
          dense
          justify="end"
          class="mt-0"
        >
          <v-text-field
            v-model="search"
            :placeholder="$t('search')"
            class="mr-2"
            dense
            prepend-inner-icon="mdi-magnify"
            solo
          />
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-row
          justify="end"
          class="mt-0"
        >
          <DatePicker
            v-model="createdAtDate"
            width="10"
            clearable
            :outlined="false"
            solo
            :label="$t('dateFilter')"
            :disabled="loading"
          />
        </v-row>
      </v-col>
      <v-col cols="1">
        <v-row
          class="mt-0"
        >
          <v-menu
            v-model="statusMenu"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-down</v-icon>
                {{ $t('statusFilter') }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group
                v-model="statuses"
                multiple
              >
                <v-list-item
                  v-for="s in statusFilter"
                  :key="s"
                  :value="s"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active" />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t(`status.${s}`) }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      class="font-weight-bold"
      dense
    >
      <v-col
        cols="2"
      >
        <ColumnHeader
          v-model="sort"
          :label="$t('trackingId')"
          sort-key="tracking_id"
        />
      </v-col>
      <v-col
        cols="1"
      >
        <ColumnHeader
          v-model="sort"
          :label="$t('refNumber')"
          sort-key="shipper_ref"
        />
      </v-col>
      <v-col
        cols="2"
      >
        <ColumnHeader
          v-model="sort"
          :label="$t('carrier')"
          sort-key="ca.company"
        />
      </v-col>
      <v-col
        cols="2"
      >
        <ColumnHeader
          v-model="sort"
          :label="$t('destination')"
          sort-key="to_address"
        />
      </v-col>
      <v-col
        cols="2"
      >
        <ColumnHeader
          v-model="sort"
          :label="$t('currentStatus')"
          sort-key="status"
        />
      </v-col>
      <v-col
        cols="1"
      >
        <ColumnHeader :label="$t('proofTitle')" />
      </v-col>
      <v-col
        cols="1"
      >
        <ColumnHeader
          v-model="sort"
          :label="$t('update')"
          sort-key="event.created"
        />
      </v-col>
      <v-col class="justify-end d-flex">
        <v-checkbox
          v-model="selectAll"
          class="mt-0 mr-2"
          @click="toggleSelectAll"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="shipments.length > 5"
      class="mb-1 mt-0"
      dense
    >
      <v-col cols="6">
        <v-btn
          color="primary"
          :disabled="offset === 0"
          @click="offset -= limit; fetchShipments()"
        >
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t('previous') }}
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        class="text-right"
      >
        <v-btn
          color="primary"
          :disabled="shipments.length < limit"
          @click="offset += limit; fetchShipments()"
        >
          {{ $t('next') }}
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="loading"
      class="justify-center"
    >
      <v-progress-circular
        color="primary"
        size="50"
      />
    </v-row>
    <v-row
      v-for="(shipment, idx) in shipments"
      :key="idx"
      class="dataRow rounded align-center mb-2"
      dense
      :class="rowClasses(idx)"
    >
      <v-col cols="2">
        <v-btn
          text
          target="_blank"
          :to="`/tracking/${shipment.shipment_tracking.trackingId}`"
        >
          <code class="primary--text"><TextHighlight :queries="[search]">{{ shipment.shipment_tracking.trackingId }}</TextHighlight></code>
        </v-btn>
      </v-col>
      <v-col
        cols="1"
        class="d-flex align-center"
      >
        <v-img
          class="shrink ml-4 mr-1"
          :src="`/connector/${shipment.connector_config.connectorType}.png`"
          max-width="24"
          position=""
        />
        <div v-if="shipment.connector_config.connectorType === 'shopify'">
          <a
            :href="`https://${JSON.parse(shipment.connector_config.config).storeName}.myshopify.com/admin/orders/${shipment.shipment_tracking.shipperId}`"
            target="_blank"
            class="primary--text"
          >
            <strong><TextHighlight :queries="[search]">{{ shipment.shipment_tracking.shipperRef }}</TextHighlight></strong>
          </a>
        </div>
        <!-- TODO: if shipperManual (or api?): link to view order (read only) -->
        <div v-else>
          <strong><TextHighlight :queries="[search]">{{ shipment.shipment_tracking.shipperRef }}</TextHighlight></strong>
        </div>
      </v-col>
      <v-col cols="2">
        {{ shipment.account.company || shipment.account.email }} (<TextHighlight :queries="[search]">
          {{ shipment.shipment_tracking.carrierId }}
        </TextHighlight>)
      </v-col>
      <v-col
        class="font-weight-bold "
        cols="2"
      >
        {{ shipment.shipment_tracking.toAddress }}
      </v-col>
      <v-col cols="2">
        <span
          class="text-body-2 rounded pa-1"
          :style="`background-color: ${statusColor(shipment.shipment_tracking.status)}`"
        >
          {{ $t(`status.${shipment.shipment_tracking.status}`) }}
        </span>
      </v-col>
      <v-col cols="1">
        <template>
          <div class="text-left">
            <ProofOfDeliveriesPopup
              :tracking-id="shipment.shipment_tracking.trackingId"
              :tracking-event="shipment.shipment_tracking_event"
              width="500"
            />
          </div>
        </template>
      </v-col>
      <v-col cols="1">
        {{ $date(shipment.shipment_tracking_event.created).format('YYYY-MM-DD HH:mm') }}
      </v-col>
      <v-col>
        <v-container class="d-inline-flex justify-end">
          <v-checkbox
            v-model="selectedItems"
            :value="idx"
            class="mt-0 ml-1"
            hide-details
            dense
          />
        </v-container>
      </v-col>
      <v-row
        v-if="!isEmpty(shipment.fulfillment_error) && shipment.shipment_tracking.status != 'ERROR'"
        dense
      >
        <v-col class="pl-6 pr-6">
          <v-alert type="warning">
            <v-row align="center">
              <v-col class="d-flex align-top">
                <span class="mr-2">{{ $t(`${shipment.connector_config.connectorType}_fulfillError`, {details: shipment.fulfillment_error.details}) }}</span>
                <v-btn
                  :disabled="shipment.isRetrying"
                  :loading="shipment.isRetrying"
                  color="primary"
                  @click="manualFulfill(idx, shipment.fulfillment_error, shipment.shipment_tracking.trackingId)"
                >
                  {{ $t('manualFulfill') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-row>
    <v-row v-if="shipments.length > 0 || offset > 0">
      <v-col cols="6">
        <v-btn
          color="primary"
          :disabled="offset === 0"
          @click="offset -= limit; fetchShipments()"
        >
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t('previous') }}
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        class="text-right"
      >
        <v-btn
          color="primary"
          :disabled="shipments.length < limit"
          @click="offset += limit; fetchShipments()"
        >
          {{ $t('next') }}
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <ConfirmDialog
      ref="confirmDialog"
      :title="$t('confirm.cancel.title')"
      :message="$t('confirm.cancel.msg')"
    />
  </div>
</template>

<script>
import ColumnHeader from '@/components/ColumnHeader'
import ShipperService from '@/services/shipper'
import TextHighlight from 'vue-text-highlight'
import {debounce, isEmpty as _isEmpty} from 'lodash'
import {PrintJobType, PrintService} from '@/services/print'
import ConfirmDialog from '@/components/ConfirmDialog'
import {COLORS} from '@/utils/constants'
import ProofOfDeliveriesPopup from '@/components/ProofOfDeliveriesPopup'
import DatePicker from '@/components/DatePicker'
import dayjs from 'dayjs'

export default {
  name: 'ShipperShipmentsFulfilled',
  inject: ['showErrorDialog'],
  components: {ConfirmDialog, ColumnHeader, TextHighlight, ProofOfDeliveriesPopup, DatePicker},
  data() {
    return {
      PrintJobType,
      dialog: false,
      loading: false,
      printing: false,
      proofOfDelivery: {},
      createdAtDate: '',
      date: '',
      tz: 0,
      limit: 25,
      offset: 0,
      search: '',
      searchTrackingId: '',
      sort: 'event.created DESC',
      selectedItems: [],
      shipments: [],
      statusMenu: false,
      statuses: [],
      statusFilter: ['SUBMITTED', 'RECEIVED', 'TRANSIT', 'DELIVERED', 'ERROR'],
      selectAll: false
    }
  },
  computed: {
    cancelableTrackingIds() {
      return this.selectedItems.reduce((accumulator, idx) => {
        if (this.shipments[idx].shipment_tracking.status === 'SUBMITTED') {
          accumulator.push(this.shipments[idx].shipment_tracking.trackingId)
        }
        return accumulator
      }, [])
    }
  },
  watch: {
    sort: 'fetchShipments',
    search: 'debounceSearch',
    statuses: 'fetchShipments',
    createdAtDate: 'filterDate'
  },
  mounted() {
    this.fetchShipments()
  },
  methods: {
    isEmpty(obj) {
      return _isEmpty(obj)
    },
    debounceSearch: debounce(function() {
      this.selectedItems = []
      this.offset = 0
      this.fetchShipments()
    }, 750),
    refresh() {
      this.selectedItems = []
      this.shipments = []
      this.fetchShipments()
    },
    async fetchShipments() {
      this.loading = true
      try {
        this.selectAll = false
        this.selectedItems = []
        this.shipments = await ShipperService.getFulfilled(this.limit, this.offset, this.search, this.sort, this.date, this.statuses ? this.statuses.join(',') : '')
        window.scrollTo(0, 0)
      } catch (e) {
        this.showErrorDialog(e)
      } finally {
        this.loading = false
      }
    },
    rowClasses(index) {
      if (this.selectedItems.indexOf(index) > -1) {
        return ['selected']
      }
      return ['white']

    },
    toggleSelectAll() {
      this.selectedItems = []
      if (this.selectAll === true) {
        for (let i = 0; i < this.shipments.length; i++) {
          this.selectedItems.push(i)
        }
      }
    },
    async manualFulfill(index, fulfillmentError, trackingId) {
      this.$set(this.shipments[index], 'isRetrying', true)
      const success = await ShipperService.retryFulfillment(fulfillmentError.orderId, trackingId)
      if (success) {
        this.$set(this.shipments[index], 'fulfillment_error', {})
      }
      this.$set(this.shipments[index], 'isRetrying', false)
    },
    print(type) {
      this.printing = true
      PrintService.startPrint(type, this.selectedItems.map(idx => this.shipments[idx].shipment_tracking.trackingId))
        .then(url => window.open(url, '_blank'))
        .catch(reason => this.showErrorDialog(reason))
        .finally(() => this.printing = false)
    },
    statusColor(status) {
      switch (status) {
      case 'SUBMITTED': return COLORS.EFFI_ORANGE
      case 'RECEIVED': return COLORS.EFFI_BLUE
      case 'TRANSIT': return COLORS.EFFI_PURPLE
      case 'DELIVERED': return COLORS.EFFI_GREEN
      case 'ERROR': return COLORS.EFFI_RED
      default:
        return 'lightgray'
      }
    },

    async filterDate(){
      this.tz = new Date().getTimezoneOffset()
      let date = ''
      let isFetched = false
      const shipment = await ShipperService.getFulfilled(this.limit, this.offset, this.search, this.sort, date, this.statuses ? this.statuses.join(',') : '')
      for (let ctr = 0; ctr < shipment.length; ctr++){
        if (dayjs(shipment[ctr].shipment_tracking_event.created).format('YYYY-MM-DD') === dayjs(this.createdAtDate).format('YYYY-MM-DD')){
          this.date = dayjs(shipment[ctr].shipment_tracking_event.created).add(this.tz, 'm').format('YYYY-MM-DD')
          date = this.date
          isFetched = true
        } else {
          date = ' '
        }
      }
      if (!isFetched){
        if (dayjs(this.date).format('YYYY-MM-DD') !== dayjs(this.createdAtDate).format('YYYY-MM-DD')){
          this.date = date
          this.fetchShipments()
        } else {
          this.date = ''
          this.refresh()
        }
      } else {
        this.fetchShipments()
        this.date = ''
      }
    },
    cancelShipments() {
      this.$refs.confirmDialog.prompt()
        .then(result => {
          if (result === 'yes') {
            ShipperService.cancelShipments(this.cancelableTrackingIds)
              .then(this.refresh)
              .catch(this.showErrorDialog)
          }
        })
    },
  },
  i18n: {
    messages: {
      fr: {
        title: 'Traités',
        trackingId: '# de suivi',
        refresh: 'Rafraîchir',
        search: 'Rechercher',
        refNumber: '# de référence',
        carrier: 'Transporteur',
        destination: 'Destination',
        currentStatus: 'Statut',
        dateFilter: 'Filtrer par date',
        update: 'Dernière maj',
        manualFulfill: 'Retraiter',
        'shopify_fulfillError': 'Shopify n\'a pas traité la commande. Voici quelques détails: {details}',
        'shipperManual_fulfillError': 'We didn\'t successfully create the fulfillment. Here some details: {details}',
        'dummyShipper_fulfillError': 'We didn\'t successfully create the fulfillment. Here some details: {details}',
        statusFilter: 'Statut',
        status: {
          SUBMITTED: 'Soumis au transporteur',
          RECEIVED: 'Cueilli',
          TRANSIT: 'En route',
          DELIVERED: 'Livré',
          ERROR: 'Annulé',
        },
        proofTitle: 'Preuve',
        proof: 'Consultez les preuves',
        proofShipment: 'Preuve lié a l\'endroit de',
        proofDelivery: 'Preuve de livraison',
        proofPickUp: 'Preuve de ceuillette',
        proofPhoto: 'Photo de livraison',
        signature: 'Signature de :',
        print: {
          label: 'Imprimer étiquettes',
          slip: 'Imprimer bordereaux'
        },
        confirm: {
          cancel: {
            title: 'Annuler les envois',
            msg: 'Êtes-vous certain de vouloirs annuler les envois sélectionnés ? (seulement ceux au statut "Soumis au transporteur" peuvent être annulés)',
          }
        },
        previous: 'Page précédente',
        next: 'Page suivante',
      },
      en: {
        title: 'Fulfilled',
        trackingId: 'Tracking #',
        refresh: 'Refresh',
        search: 'Search',
        refNumber: 'Reference #',
        carrier: 'Carrier',
        destination: 'Destination',
        currentStatus: 'Status',
        dateFilter: 'Filter by date',
        update: 'Last update',
        manualFulfill: 'Retry fulfillment',
        'shopify_fulfillError': 'Shopify didn\'t successfully create the fulfillment. Here some details: {details}',
        'shipperManual_fulfillError': 'We didn\'t successfully create the fulfillment. Here some details: {details}',
        'dummyShipper_fulfillError': 'We didn\'t successfully create the fulfillment. Here some details: {details}',
        statusFilter: 'Status',
        status: {
          SUBMITTED: 'Submitted to carrier',
          RECEIVED: 'Picked up',
          TRANSIT: 'On the way',
          DELIVERED: 'Delivered',
          ERROR: 'Canceled',
        },
        proofTitle: 'Proof',
        proof: 'See Proof',
        proofShipment: 'Proof linked to',
        proofDelivery: 'Proof of Delivery',
        proofPickUp: 'Proof of Pick-Up',
        proofPhoto: 'Photo of delivery',
        signature: 'Sign by :',
        print: {
          label: 'Print labels',
          slip: 'Print packing slips'
        },
        confirm: {
          cancel: {
            title: 'Cancel shipments',
            msg: 'Are you sure you want to cancel the selected shipments ? (only those with the status "Submitted to carrier" can be canceled)',
          }
        },
        previous: 'Previous page',
        next: 'Next page',
      }
    }
  }
}
</script>

<style scoped>
  .dataRow .col {
    overflow: hidden;
    overflow-wrap: anywhere;
  }
  .selected {
    background-color: rgba(192, 192, 192, 0.89) !important;
  }
</style>