<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            Reports
          </v-col>
          <v-spacer />
        </v-row>
      </v-card-title>
      <v-row>
        <v-col cols="6">
          <v-autocomplete
            v-model="selectedAccounts"
            class="ml-2"
            :items="accounts"
            label="Account"
            item-value="id"
            multiple
            chips
            item-text="contactEmail"
          >
            <template
              slot="item"
              slot-scope="itemData"
            >
              {{ itemData.item.company || itemData.item.contactEmail }} ({{ itemData.item.type }})
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="2">
          <DatePicker
            v-model="dateFrom"
            label="From"
            width="175px"
            clearable
          />
        </v-col>
        <v-col cols="2">
          <DatePicker
            v-model="dateTo"
            label="To"
            width="175px"
            clearable
          />
        </v-col>
      </v-row>
      <v-data-table
        disable-pagination
        hide-default-footer
        :item-key="`${Math.random()}`"
        :headers="headers"
        :items="data"
        group-by="carrierId"
        :loading="loading"
      >
        <template v-slot:group.header="{items, isOpen, toggle}">
          <th colspan="2">
            <table>
              <tr>
                <td style="padding-right: 10px">
                  <v-icon
                    @click="toggle"
                  >
                    {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                  </v-icon>
                </td>
                <td style="padding-right: 10px">
                  Carrier: {{ carriers[items[0].carrierId].name }} - {{ carriers[items[0].carrierId].email }}
                </td>
                <td style="padding-right: 10px">
                  Shipper Count {{ carriers[items[0].carrierId].shipperCount }}
                </td>
                <td>Total Packages {{ carriers[items[0].carrierId].deliveryCount }}</td>
              </tr>
            </table>
          </th>
        </template>
        <template v-slot:item.name="{ item }">
          {{ item.name }} - {{ item.email }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import TrackingService from '@/services/tracking'
import AccountService from '@/services/account'
import DatePicker from '@/components/DatePicker'
export default {
  name: 'Reports',
  inject: ['showErrorDialog'],
  components: {
    DatePicker,
  },
  data() {
    return {
      data: [],
      accounts: [],
      selectedAccounts: [],
      carriers: {},
      loading: false,
      dateFrom: '',
      dateTo: '',
      headers: [
        {
          text: 'Shipper',
          align: 'start',
          value: 'name',
          groupable: false,
        },
        {text: 'Deliveries', value: 'deliveryCount', align: 'right'},
      ],

    }
  },
  watch: {
    selectedAccounts() {
      this.fetchReportData()
    },
    dateFrom(){
      if (this.dateFrom && this.dateTo) {
        this.fetchReportData()
      }
    },
    dateTo(){
      if (this.dateFrom && this.dateTo) {
        this.fetchReportData()
      }
    },
  },
  mounted() {
    this.dateFrom = this.$date().startOf('month').format('YYYY-MM-DD')
    this.dateTo = this.$date().endOf('month').format('YYYY-MM-DD')
    this.fetchReportData()
    AccountService.list(1000, 0, '', 'CARRIER,SHIPPER').then((data) => {
      this.accounts = data
    })
  },
  methods: {
    fetchReportData() {
      this.loading = true
      const searchParams = {
        accountIds: this.selectedAccounts,
        dateFrom: this.$date(this.dateFrom).format('YYYY-MM-DD'),
        dateTo: this.$date(this.dateTo).format('YYYY-MM-DD')
      }
      TrackingService.getInvoicingReportData(searchParams).then((data) => {
        this.data = data.lines
        this.carriers = data.carrierLines.reduce((map, obj) => {
          map[obj.id] = obj
          return map
        }, {})
      }).catch(reason => this.showErrorDialog(reason))
        .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>