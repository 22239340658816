<template>
  <v-container>
    <div>
      <ErrorAlert
        :error-msg="errorMsg"
        :with-support-report="technicalErrorDetails.withAction"
        :technical-error-details="technicalErrorDetails.details"
        @clearError="errorMsg = '' "
      />
      <v-row :justify="'center'">
        <v-col cols="8">
          <v-card>
            <v-card-title>
              {{ tt('title', {type: inviteType}) }}
            </v-card-title>
            <v-divider class="ml-4 mr-4" />
            <v-container class="ml-4 pr-12">
              <v-form
                ref="form"
                v-model="formValid"
              >
                <v-select
                  v-model="invitation.lang"
                  :label="$t('lang')"
                  :rules="[required]"
                  :items="langs"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="invitation.email"
                  :label="$t('email')"
                  :rules="[required, emailValid]"
                  outlined
                  dense
                  append-icon="mdi-asterisk"
                />
                <v-text-field
                  v-model="name"
                  :label="$t('name')"
                  outlined
                  dense
                />
                <v-text-field
                  v-show="inviteType === 'user'"
                  v-model="phone"
                  :label="$t('phone')"
                  outlined
                  dense
                />
                <!-- TODO: custom message ??? -->
                <v-row class="mt-4">
                  <v-col class="d-flex justify-end">
                    <v-btn
                      :to="returnUrl"
                      outlined
                      class="mr-4"
                      style="background-color: white"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      :loading="sending"
                      :disabled="sending"
                      @click="send"
                    >
                      {{ $t('send') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="showSnackbar">
        {{ snackMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="showSnackbar = false"
          >
            {{ $t('close') }}
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import {emailValid, required} from '@/utils/validation'
import ErrorAlert from '@/components/ErrorAlert'

export default {
  name: 'Invitation',
  components: {ErrorAlert},
  props: {
    returnUrl: {
      type: String,
      default: '/drivers',
    },
    inviteType: {
      type: String,
      default: 'driver',
    },
    invite: Function,
  },
  i18n: {
    messages: {
      en: {
        title: 'Invite a new {type}',
        lang: 'Language',
        email: 'Email',
        send: 'Send',
        sent: 'Invitation sent!',
        name: 'Name',
        duplicateEmail: 'A {type} with this email address already exists. Please enter a different email address.',
        genericError: 'Something went wrong.',
        reportError: 'Report error',
        driver: 'driver',
        user: 'user',
        phone: 'Phone',
      },
      fr: {
        title: 'Inviter un nouveau {type}',
        lang: 'Langue',
        email: 'Courriel',
        send: 'Envoyer',
        sent: 'Invitation envoyée!',
        name: 'Nom',
        duplicateEmail: 'Un {type} avec cette adresse courriel existe déjà. Veuillez entrer une adresse courriel différente.',
        genericError: 'Une erreur est survenue.',
        reportError: 'Rapporter le problème',
        driver: 'livreur',
        user: 'utilisateur',
        phone: 'Téléphone'
      }
    }
  },
  data() {
    return {
      showSnackbar: false,
      snackMsg: '',
      formValid: false,
      sending: false,
      invitation: {
        lang: this.$i18n.locale,
        email: '',
        extraValues: {},
      },
      name: '',
      phone: '',
      errorMsg: '',
      technicalErrorDetails: {withAction: false, details: ''},
      connectorType: '',
      langs: [
        {text: 'Français', value: 'fr'},
        {text: 'English', value: 'en'}
      ]
    }
  },

  methods: {
    required: required,
    emailValid: emailValid,
    send() {
      this.$refs.form.validate()
      if (this.formValid) {
        this.sending = true
        this.invitation.extraValues['type'] = this.inviteType
        if (this.name) {
          this.invitation.extraValues['name'] = this.name
        }
        if (this.phone) {
          this.invitation.extraValues['phone'] = this.phone
        }
        this.invite(this.invitation)
          .then(() => {
            this.$router.push(this.returnUrl)
          })
          .catch((error) => {
            this.technicalErrorDetails = {details: error.toString()}
            if (error.toString().toUpperCase().includes('Duplicate entry'.toUpperCase())) {
              this.errorMsg = this.tt('duplicateEmail', {type: this.inviteType})
            } else {
              this.technicalErrorDetails.withAction = true
              this.errorMsg = this.$i18n.t('genericError')
            }
          })
          .finally(() => this.sending = false)
      }
    },
    tt(translation, params) {
      const translatedOpts = {}
      for (const [key, value] of Object.entries(params)) {
        translatedOpts[key] = this.$i18n.t(value)
      }
      return this.$i18n.t(translation, translatedOpts)
    },
  }
}
</script>

