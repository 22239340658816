<template>
  <v-container>
    <v-tabs v-model="activeTab" background-color="transparent" centered fixed-tabs>
      <v-tab to="/shipper/shipments/unfulfilled">
        <v-icon class="mr-1">mdi-package-variant</v-icon>
        {{ $t('title.unfulfilled') }}
      </v-tab>
      <v-tab to="/shipper/shipments/fulfilled">
        <v-icon class="mr-1">mdi-package-variant-closed</v-icon>
        {{ $t('title.fulfilled') }}
      </v-tab>
    </v-tabs>
    <router-view />
  </v-container>
</template>

<script>
export default {
  name: "ShipperShipments",
  data: () => ({
    activeTab: '/shipper/shipments/unfulfilled',
  }),
  i18n: {
    messages: {
      fr: {
        title: {
          unfulfilled: 'Non traités',
          fulfilled: 'Traités',
        },
      },
      en: {
        title: {
          unfulfilled: 'Unfulfilled',
          fulfilled: 'Fulfilled',
        },
      }
    }
  }
}
</script>

<style scoped>

</style>