<template>
  <v-row>
    <v-col>
      <v-row
        v-if="!editMode"
        dense
      >
        <v-col>
          <v-card-text class="pa-2">
            <strong>{{ $t('carrier') }}:</strong> {{ updatedConfig.carrierAccountName }}
          </v-card-text>
        </v-col>
      </v-row>
      <div v-else>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="carrierAccountId"
              :label="$t('carrier')"
              :loading="loading"
              :items="carriers"
              :hide-no-data="isInDebounce"
              :search-input.sync="search"
              :rules="[required]"
              item-text="name"
              item-value="id"
              clearable
              hide-details="auto"
              no-filter
              outlined
              dense
            />
          </v-col>
        </v-row>
      </div>
      <ShipperCarrierConfig
        v-model="shipperConfig.config"
        :type="carrierConnectorType"
        :edit-mode="editMode"
      />
    </v-col>
  </v-row>
</template>

<script>
import ShipperService from '@/services/shipper'
import AccountService from '@/services/account'
import {required} from '@/utils/validation'
import ShipperCarrierConfig from '@/components/connectors/ShipperCarrierConfig'
import lodash from 'lodash'

export default {
  name: 'SingleCarrier',
  components: {
    ShipperCarrierConfig
  },
  props: {
    editMode: Boolean,
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  inject: ['showErrorDialog'],
  data() {
    return {
      required,
      updatedConfig: lodash.cloneDeep(this.config),
      shipperConfig: {},
      carrierAccountId: this.config ? this.config.carrierAccountId : null,
      isInDebounce: false,
      loading: false,
      search: '',
      carriers: this.config && this.config.carrierAccountId ? [{
        id: this.config.carrierAccountId,
        name: this.config.carrierAccountName,
      }] : [],
      carrierConnectorType: null,
    }
  },

  watch: {
    search(val) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      this.isInDebounce = true
      this.debounce = setTimeout(() => {
        if (val && val.length > 2) {
          this.querySelections(val)
        }
        this.isInDebounce = false
      }, 500)
    },
    carrierAccountId() {
      if (this.carrierAccountId) {
        const carrier = this.carriers.find(carrier => carrier.id === this.carrierAccountId)
        this.updatedConfig.carrierAccountId = carrier.id
        this.updatedConfig.carrierAccountName = carrier.name
        this.getCarrierConnectorType()
        this.fetchShipperCarrierConfig()
      } else {
        this.carrierConnectorType = null
        this.updatedConfig.carrierAccountId = null
        this.updatedConfig.carrierAccountName = ''
      }
    },
  },

  mounted() {
    this.isValid = true
    if (this.carrierAccountId) {
      this.getCarrierConnectorType()
      this.fetchShipperCarrierConfig()
    }
  },

  methods: {
    getCarrierConnectorType() {
      if (this.carrierAccountId) {
        ShipperService.getCarrierConnectorType(this.carrierAccountId)
          .then(value => this.carrierConnectorType = value)
          .catch(reason => this.showErrorDialog(reason))
      }
    },
    fetchShipperCarrierConfig() {
      if (this.carrierAccountId) {
        ShipperService.getShipperCarrierConfig(this.carrierAccountId)
          .then(value => this.shipperConfig = value ? value : {
            shipperAccountId: this.$store.state.account.id,
            carrierAccountId: this.carrierAccountId,
            config: '{}',
          })
          .catch(reason => this.showErrorDialog(reason))
      }
    },
    querySelections (val) {
      this.loading = true
      AccountService.searchCarriers(val)
        .then((result) => {
          this.carriers = []
          if (result && result.length > 0) {
            for (const account of result) {
              this.carriers.push({
                id: account.id,
                name: account.company || account.email,
              })
            }
          }
        })
        .finally(() => this.loading = false)
    },
    reset() {
      this.carrierAccountId = this.config ? this.config.carrierAccountId : null
    },
    getConfig() {
      return JSON.stringify(this.updatedConfig)
    },
    saveCarrierConfigs() {
      ShipperService.saveShipperCarrierConfig(this.shipperConfig)
        .catch(reason => this.showErrorDialog(reason))
    }
  },

  i18n: {
    messages: {
      fr: {
        carrier: 'Transporteur',
      },
      en: {
        carrier: 'Carrier',
      }
    }
  }
}
</script>

<style scoped>

</style>
