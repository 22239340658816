<template>
  <v-menu
    ref="datePicker"
    v-model="datePicker"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :disabled="disabled"
        class="mb-2"
        :style="`width: ${width}`"
        prepend-inner-icon="mdi-calendar"
        dense
        :label="label"
        :clearable="clearable"
        readonly
        :outlined="outlined"
        :solo="solo"
        hide-details
        :value="value"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="date"
      scrollable
      show-adjacent-months
      :min="minDate"
      :max="maxDate"
      :locale="$i18n.locale"
      @change="onDateChange(date)"
    >
      <v-row
        justify="center"
        dense
        no-gutters
      >
        <v-btn
          color="primary"
          plain
          @click="onToday"
        >
          {{ $t('today') }}
        </v-btn>
      </v-row>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '150px',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      datePicker: false,
      date: this.value,
    }
  },
  watch: {
    value(val) {
      if (val) {
        const date = this.parseDate(val)
        if (this.date !== date) {
          this.date = date
        }
      }
    },
    date: function (val) {
      if (val !== this.parseDate(this.value)) {
        this.$emit('input', val)
      }
    },
  },
  methods: {
    onDateChange(date) {
      this.$refs.datePicker.save(date)
      this.$emit('change', date)
    },
    onToday() {
      this.date = this.$date(new Date()).format('YYYY-MM-DD')
      this.onDateChange(this.date)
    },
    parseDate: function (dateTime) {
      if (!dateTime) {
        return ''
      }
      return this.$date(dateTime).format('YYYY-MM-DD')
    },
  },
}
</script>

<style scoped>

</style>
