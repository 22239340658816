import axios from 'axios'
import auth from '@/plugins/auth'
import config from '@/services/config'
import {getTimezone} from '@/utils/tz'
import {errorHandler} from '@/services/utils'

const ShipperService = {

  async listConnectors() {
    try {
      const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/connector`, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async connectorTypes() {
    const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/connector/types`, {headers: await auth.getAuthHeader()})
    if (response.status >= 300) {
      throw Error(`Get connector types failed: ${response.statusText}`)
    }
    return response.data
  },

  async createConnector(connector) {
    try {
      const response = await axios.post(`${config.SHIPPER_SERVICE_URL}/connector`, connector, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async updateConnectorConfig(connectorId, connectorConfig) {
    try {
      const response = await axios.put(`${config.SHIPPER_SERVICE_URL}/connector/${connectorId}`, connectorConfig, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async getConnector(connectorId) {
    try {
      const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/connector/${connectorId}`, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async deleteConnector(connectorId) {
    try {
      const response = await axios.delete(`${config.SHIPPER_SERVICE_URL}/connector/${connectorId}`, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async deleteShopifyConnectors() {
    try {
      const response = await axios.delete(`${config.SHIPPER_SERVICE_URL}/connector/shopify`, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async getConnectorType(connectorId){
    try {
      const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/connector/${connectorId}/type`)
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async testConnector(connectorId) {
    try {
      const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/connector/${connectorId}/test`, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async shopifyStoreTaken(storeName, connectorConfigId) {
    try {
      // eslint-disable-next-line max-len
      const alreadyExist = await axios.get(`${config.SHIPPER_SERVICE_URL}/connector/shopify/exist`, {headers: await auth.getAuthHeader(), params: {storeName, connectorConfigId}})
      return alreadyExist.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async getShopifyAuthorizeUrl(storeName) {
    const response = await axios.get('/shopify/authorize', {headers: await auth.getAuthHeader(), params: {storeName}})
    if (response.status >= 300) {
      throw Error(`Test connector failed: ${response.statusText}`)
    }
    return response.data
  },

  async refreshShopifyLocations(connectorConfigId) {
    const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/connector/shopify/locations/refresh`, {headers: await auth.getAuthHeader(), params: {connectorConfigId}})
    if (response.status >= 300) {
      throw Error(`Test connector failed: ${response.statusText}`)
    }
    return response.data
  },

  async ruleTypes() {
    const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/rule/types`, {headers: await auth.getAuthHeader()})
    if (response.status >= 300) {
      throw Error(`Get rule types failed: ${response.statusText}`)
    }
    return response.data
  },

  async listRules() {
    const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/rule`, {headers: await auth.getAuthHeader()})
    if (response.status >= 300) {
      throw Error(`Get rules failed: ${response.statusText}`)
    }
    return response.data
  },

  async saveRule(rule) {
    const response = await axios.post(`${config.SHIPPER_SERVICE_URL}/rule`, rule, {headers: await auth.getAuthHeader()})
    if (response.status >= 300) {
      throw Error(`Save rule failed: ${response.statusText}`)
    }
    return response.data
  },

  async deleteRule(ruleId) {
    const response = await axios.delete(`${config.SHIPPER_SERVICE_URL}/rule/${ruleId}`, {headers: await auth.getAuthHeader()})
    if (response.status >= 300) {
      throw Error(`Delete rule failed: ${response.statusText}`)
    }
    return response.data
  },

  async getShipperCarrierConfig(carrierId) {
    const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/rule/carrier/config`, {headers: await auth.getAuthHeader(), params: {carrierId}})
    if (response.status >= 300) {
      throw Error(`Get shipper config failed: ${response.statusText}`)
    }
    return response.data
  },

  async saveShipperCarrierConfig(carrierConfig) {
    const response = await axios.post(`${config.SHIPPER_SERVICE_URL}/rule/carrier/config`, carrierConfig, {headers: await auth.getAuthHeader()})
    if (response.status >= 300) {
      throw Error(`Save shipper config failed: ${response.statusText}`)
    }
    return response.data
  },

  async getCarrierConnectorType(carrierId) {
    const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/rule/carrier/type`, {headers: await auth.getAuthHeader(), params: {carrierId}})
    if (response.status >= 300) {
      throw Error(`getCarrierConnectorType failed: ${response.statusText}`)
    }
    return response.data
  },

  async lastThreeMonths() {
    const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/shipment/lastthreemonths`, {headers: await auth.getAuthHeader()})
    if (response.status >= 300) {
      throw Error(`Last three months stats failed: ${response.statusText}`)
    }
    return response.data
  },

  defaultTimeout: 2 * 60 * 1000,

  async getAvailableShipments(offset, batchUid, orderIds, search, sort, limit, connectorId) {
    const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/shipment/unfulfilled`, {
      headers: await auth.getAuthHeader(),
      params: {offset, batchUid, orderIds, search, sort, limit, connectorId},
      timeout: this.defaultTimeout,
    })
    if (response.status >= 300) {
      throw Error(`getAvailableShipments failed: ${response.statusText}`)
    }
    return response.data
  },

  async retryFulfillment(orderId, trackingId) {
    try {
      await axios.post(`${config.SHIPPER_SERVICE_URL}/shipment/manualfulfillment`, {orderId, trackingId},
        {headers: await auth.getAuthHeader()}
      )
      return true
    } catch (e) {
      return false
    }
  },

  async rateShipments(shipments) {
    const response = await axios.post(`${config.SHIPPER_SERVICE_URL}/shipment/rate`, shipments, {
      headers: await auth.getAuthHeader(),
      timeout: this.defaultTimeout,
    })
    if (response.status >= 300) {
      throw Error(`Rate shipments failed: ${response.statusText}`)
    }
    return response.data
  },

  async routeShipments(shipments) {
    const tz = getTimezone()
    const response = await axios.post(`${config.SHIPPER_SERVICE_URL}/shipment/route`, shipments, {
      headers: await auth.getAuthHeader(),
      timeout: this.defaultTimeout,
      params: {tz}
    })
    if (response.status >= 300) {
      throw Error(`Route shipments failed: ${response.statusText}`)
    }
    return response.data
  },

  async cancelShipments(shipments) {
    const response = await axios.post(`${config.SHIPPER_SERVICE_URL}/shipment/cancel`, shipments, {headers: await auth.getAuthHeader()})
    if (response.status >= 300) {
      throw Error(`cancelShipments failed: ${response.statusText}`)
    }
    return response.data
  },

  async getFulfilled(limit, offset, search, sort, date, statuses) {
    const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/shipment/fulfilled`, {
      headers: await auth.getAuthHeader(),
      params: {limit, offset,  search, sort, date, statuses},
    })
    if (response.status >= 300) {
      throw Error(`getFulfilled failed: ${response.statusText}`)
    }
    return response.data
  },

  async startPrintShipments(trackingIds, type) {
    const response = await axios.post(`${config.SHIPPER_SERVICE_URL}/shipment/print/start`, trackingIds, {
      headers: await auth.getAuthHeader(),
      timeout: this.defaultTimeout,
      params: {type},
    })
    if (response.status >= 300) {
      throw Error(`Start print failed: ${response.statusText}`)
    }
    return response.data
  },

  async getPrintShipmentsStatus(objectKey) {
    const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/shipment/print/status`, {
      headers: await auth.getAuthHeader(),
      params: {objectKey},
    })
    if (response.status >= 300) {
      throw Error(`getPrintShipmentsStatus failed: ${response.statusText}`)
    }
    return response.data
  },

  async getPrintShipmentsUrl(objectKey) {
    const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/shipment/print/url`, {
      headers: await auth.getAuthHeader(),
      params: {objectKey},
    })
    if (response.status >= 300) {
      throw Error(`getPrintShipmentsUrl failed: ${response.statusText}`)
    }
    return response.data
  },

  //
  // Orders
  //

  async createOrder(order) {
    const response = await axios.post(`${config.SHIPPER_SERVICE_URL}/order/create`, order, {headers: await auth.getAuthHeader()})
    if (response.status >= 300) {
      throw Error(`createOrder failed: ${response.statusText}`)
    }
    return response.data
  },

  async editOrder(orderId, order) {
    const response = await axios.post(`${config.SHIPPER_SERVICE_URL}/order/${orderId}`, order, {headers: await auth.getAuthHeader()})
    if (response.status >= 300) {
      throw Error(`editOrder failed: ${response.statusText}`)
    }
    return response.data
  },

  async getOrder(orderId) {
    const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/order/${orderId}`, {headers: await auth.getAuthHeader()})
    if (response.status >= 300) {
      throw Error(`getOrder failed: ${response.statusText}`)
    }
    return response.data
  },

  async deleteOrder(orderId) {
    const response = await axios.delete(`${config.SHIPPER_SERVICE_URL}/order/${orderId}`, {headers: await auth.getAuthHeader()})
    if (response.status >= 300) {
      throw Error(`deleteOrder failed: ${response.statusText}`)
    }
    return response.data
  },

  async searchAddress(search) {
    const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/order/address/search`, {
      headers: await auth.getAuthHeader(),
      params: {search},
    })
    if (response.status >= 300) {
      throw Error(`searchAddress failed: ${response.statusText}`)
    }
    return response.data
  },

  async saveAddress(address) {
    const response = await axios.post(`${config.SHIPPER_SERVICE_URL}/order/address/save`, address, {
      headers: await auth.getAuthHeader(),
    })
    if (response.status >= 300) {
      throw Error(`saveAddress failed: ${response.statusText}`)
    }
    return response.data
  },

  async listUsers(searchParams, limit, offset) {
    try {
      const response = await axios.get(`${config.SHIPPER_SERVICE_URL}/user/list`, {
        headers: await auth.getAuthHeader(),
        params: {
          ...searchParams,
          limit,
          offset,
        }
      })
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async deactivateUser(id) {
    try {
      const response = await axios.post(`${config.SHIPPER_SERVICE_URL}/user/deactivate`, {id}, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw Error(e.response.data.message || e.response.data)
    }
  },

  async invite(inviteRequest) {
    try {
      const response = await axios.post(`${config.SHIPPER_SERVICE_URL}/invite`, inviteRequest, {
        headers: await auth.getAuthHeader(),

      })
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  }
}

export default ShipperService