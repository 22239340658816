<template>
  <div>
    <v-row class="mt-4">
      <v-col>
        <h2>{{ $t('title') }}</h2>
      </v-col>
      <v-col>
        <v-progress-circular
          v-if="processing"
          indeterminate
          color="primary"
          size="50"
        />
      </v-col>
      <v-spacer />
      <v-col
        cols="6"
        class="text-right"
      >
        <v-btn
          color="primary"
          :disabled="processing"
          @click="$emit('completed')"
        >
          <v-icon>mdi-back</v-icon>
          {{ $t('back') }}
        </v-btn>
        <v-btn
          v-if="trackingIds.length > 0"
          color="primary"
          :disabled="processing"
          class="ml-2"
          @click="printSlips"
        >
          <v-icon>mdi-print</v-icon>
          {{ $t('print.slip') }} ({{ trackingIds.length }})
        </v-btn>
        <v-btn
          v-if="trackingIds.length > 0"
          color="primary"
          :disabled="processing"
          class="ml-2"
          @click="printLabels()"
        >
          <v-icon>mdi-print</v-icon>
          {{ $t('print.label') }} ({{ trackingIds.length }})
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="pt-1">
        <v-divider />
      </v-col>
    </v-row>
    <v-row
      class="font-weight-bold"
      dense
    >
      <v-col>
        <ColumnHeader :label="$t('order')" />
      </v-col>
      <v-col>
        <ColumnHeader :label="$t('service')" />
      </v-col>
      <v-col>&nbsp;</v-col>
      <v-col>
        <ColumnHeader :label="$t('tracking')" />
      </v-col>
    </v-row>
    <v-row
      v-for="(result, idx) in routingResults"
      :key="idx"
      class="white rounded align-center mb-2"
      dense
    >
      <v-col class="pa-2">
        <v-row>
          <v-col class="d-flex align-center">
            <v-img
              class="shrink ml-4"
              :src="`/connector/${result.shipment.connectorType}.png`"
              max-width="24"
              position=""
            />
            <div>
              <strong>#{{ result.shipment.externalNumber }}</strong>
            </div>
          </v-col>
          <v-col>
            {{ result.shipment.selectedRate.name }} ({{ result.shipment.selectedRate.price }}$)
          </v-col>
          <v-col>
            <v-icon
              v-if="result.success === true"
              color="success"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              v-else-if="result.success === false"
              class="warning--text"
            >
              mdi-alert
            </v-icon>
            <v-progress-circular
              v-else
              color="primary"
              size="24"
              indeterminate
            />
          </v-col>
          <v-col>
            <code v-if="result.trackingId">
              {{ result.trackingId }}
            </code>
          </v-col>
        </v-row>
        <v-row v-if="result.error">
          <v-col class="pl-6 pr-6">
            <v-alert
              type="error"
              max-height="75"
              style="overflow: hidden"
            >
              {{ result.error }}
            </v-alert>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="primary"
              :disabled="processing"
              @click="retry(result, idx)"
            >
              {{ $t('retry') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ShipperService from '@/services/shipper'
import {PrintService, PrintJobType} from '@/services/print'
import ColumnHeader from '@/components/ColumnHeader'

export default {
  name: 'ShipperShipmentsRoute',
  inject: ['showErrorDialog'],
  components: {ColumnHeader},
  props: {
    shipments: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      processing: false,
      trackingIds: [],
      routingResults: [],
    }
  },
  mounted() {
    this.startRouting()
  },
  methods: {
    startRouting() {
      this.processing = true
      const promises = []
      this.shipments.forEach((shipment, idx) => {
        const routingResult = {shipment}
        this.routingResults.push(routingResult)
        promises.push(this.routeShipment(routingResult, idx))
      })
      Promise.all(promises).finally(() => {
        this.processing = false
        if (this.trackingIds.length > 0) {
          this.printLabels()
        }
      })
    },
    async routeShipment(routingResult, idx) {
      try {
        const results = await ShipperService.routeShipments([routingResult.shipment])
        if (results) {
          const result = results[0]
          routingResult.trackingId = result.trackingId
          routingResult.error = result.error
          routingResult.success = result.success
          if (routingResult.success && result.trackingId) {
            this.trackingIds.push(result.trackingId)
          }
        } else {
          routingResult.error = 'empty response!'
          routingResult.success = false
        }
      } catch (e) {
        routingResult.error = e.toString()
        routingResult.success = false
      }
      this.$set(this.routingResults, idx, routingResult)
    },
    retry(routingResult, idx) {
      this.processing = true
      delete routingResult.success
      delete routingResult.error
      this.routeShipment(routingResult, idx)
        .then(() => {
          if (routingResult.success && routingResult.trackingId) {
            this.printLabels([routingResult.trackingId])
          }
        })
        .finally(() => this.processing = false)
    },
    printSlips() {
      this.processing = true
      PrintService.startPrint(PrintJobType.SLIP, this.trackingIds)
        .then(url => window.open(url, '_blank'))
        .catch(reason => this.showErrorDialog(reason))
        .finally(() => this.processing = false)
    },
    printLabels(trackingIds) {
      this.processing = true
      PrintService.startPrint(PrintJobType.LABEL, trackingIds && Array.isArray(trackingIds) ? trackingIds : this.trackingIds)
        .then(url => window.open(url, '_blank'))
        .catch(reason => this.showErrorDialog(reason))
        .finally(() => this.processing = false)
    },
  },
  i18n: {
    messages: {
      fr: {
        title: 'Traitement',
        service: 'Service',
        order: 'Commande',
        tracking: '# de suivi',
        back: 'Retour',
        retry: 'Réessayer',
        print: {
          label: 'Imprimer étiquettes',
          slip: 'Imprimer bordereaux'
        },
      },
      en: {
        title: 'Fulfillment',
        service: 'Service',
        order: 'Order',
        tracking: 'Tracking #',
        back: 'Back',
        retry: 'Retry',
        print: {
          label: 'Print labels',
          slip: 'Print packing slips'
        },
      },
    }
  }
}
</script>

<style scoped>

</style>