<template>
  <v-container
    fill-height
    fluid
    class="primary signup"
  >
    <v-row align="center">
      <v-col>
        <v-row justify="center">
          <span class="white--text text-h2 font-weight-bold pb-5">
            {{ $t('title') }}
          </span>
        </v-row>
        <v-row justify="center">
          <v-col cols="2">
            <v-card class="fill-height">
              <v-card-text style="text-align: center;padding-bottom: 38px">
                {{ $t('shipperMsg') }}
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  color="primary"
                  @click="signUpAs('shipper')"
                >
                  {{ $t('shipper') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card>
              <v-card-text style="text-align: center;">
                {{ $t('carrierMsg') }}
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  color="primary"
                  @click="signUpAs('carrier')"
                >
                  {{ $t('carrier') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from '@/plugins/auth'
import AccountService from '@/services/account'
import {getLocale} from '@/plugins/i18n'

export default {
  name: 'SignUp',
  inject: ['showErrorDialog'],
  i18n: {
    messages: {
      fr: {
        title: 'Que voulez-vous faire avec Effi ?',
        driver: 'Livreur',
        driverMsg: 'Je veux livrer des colis pour des transporteurs',
        shipper: 'Expéditeur',
        shipperMsg: 'Je veux expédier des colis',
        carrier: 'Transporteur',
        carrierMsg: 'Je veux gérer la logistique de mes colis à livrer',
      },
      en: {
        title: 'What do you want to do with Effi ?',
        driver: 'Driver',
        driverMsg: 'I want to deliver packages for carriers',
        shipper: 'Shipper',
        shipperMsg: 'I want to ship packages',
        carrier: 'Carrier',
        carrierMsg: 'I want to manage the logistics of my packages to be delivered',
      }
    }
  },
  methods: {
    async signUpAs(accountType) {
      // First check if we are logged in (valid token), if yes, we are probably just missing the account type on our account
      let token
      try {
        token = await auth.getToken()
      } catch (e) {
        console.warn('')
      }
      if (token) {
        // Create an account and go to home page
        try {
          await AccountService.create({
            type: accountType.toUpperCase(),
            lang: getLocale()
          })
          await auth.refreshToken()
          window.location.href = '/'
        } catch (e) {
          this.showErrorDialog(e)
        }
      } else {
        // Redirect to login with state (see auth.handleAuthCallback for the return flow ...)
        return auth.logIn(accountType, true)
      }
    }
  }
}
</script>

<style scoped>
  .signup {
    background-image: url("../assets/pexels-artem-podrez-5025669.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
