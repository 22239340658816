<template>
  <div
    v-if="address"
    :class="{singleLine: singleLine}"
  >
    <div v-if="address.address1 || address.address2">
      <span v-if="address.address1">{{ address.address1 }}</span>
      <span v-if="address.address2"> {{ address.address2 }}</span>
    </div>
    <div v-if="address.city || address.province">
      <span v-if="singleLine">, </span>
      <span>{{ address.city }} <span v-if="address.province">({{ address.province }})</span></span>
    </div>
    <div v-if="address.postalcode">
      <span v-if="singleLine">, </span>
      <span>{{ address.postalcode }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddressRender',
  props: {
    singleLine: Boolean,
    address: {
      type: Object,
      default: () => ({})
    },
  }
}
</script>

<style scoped>
  div.singleLine > div {
    display: inline;
  }
</style>
