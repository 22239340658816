<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            Accounts
          </v-col>
          <v-spacer />
          <v-col cols="6">
            <v-row>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row>
        <v-col cols="6">
&nbsp;
        </v-col>
        <v-col>
          Account type:
        </v-col>
        <v-col>
          <v-checkbox
            v-model="types"
            label="Carrier"
            value="CARRIER"
            class="ma-0"
          />
        </v-col>
        <v-col>
          <v-checkbox
            v-model="types"
            label="Shipper"
            value="SHIPPER"
            class="ma-0"
          />
        </v-col>
        <v-col>
          <v-checkbox
            v-model="types"
            label="Driver"
            value="DRIVER"
            class="ma-0"
          />
        </v-col>
      </v-row>
      <v-data-table
        disable-pagination
        hide-default-footer
        :headers="headers"
        :items="accounts"
        :loading="loading"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn @click="doImpersonate(item.id)">
            <v-icon>mdi-admin_panel_settings</v-icon>
            Impersonate
          </v-btn>
          <v-btn
            class="ml-1"
            @click="account = item; showConfirmDelete = true;"
          >
            <v-icon>mdi-admin_panel_settings</v-icon>
            Delete
          </v-btn>
        </template>
        <template v-slot:footer>
          <div class="v-data-footer pa-3 justify-center">
            <div>
              <v-btn
                :disabled="!hasMoreAccounts"
                class="my-2"
                @click="loadMore"
              >
                Load more
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="showConfirmDelete"
      :width="400"
      persistent
      style="z-index: 9999"
    >
      <v-card v-if="account">
        <v-card-title>
          Delete account
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              Cette action est irréversible. Êtes-vous certain de vouloir suprimer le compte <strong>{{ account.type }}
                {{ account.company || account.contactEmail }}</strong> ?
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Pour confirmer, veuillez inscrire le courriel du propriétaire du compte !
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="accountOwnerEmail"
                dense
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            @click="showConfirmDelete = false; accountOwnerEmail = null; account = null;"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :disabled="accountOwnerEmail !== account.owner.email"
            @click="deleteAccount(account.id)"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="showImpersonateMsg"
        :width="400"
        persistent
        style="z-index: 9999"
    >
      <v-card>
        <v-card-title>
          Sélectionner un compte
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              Pour continnuer avec l'opération demandée, vous devez sélectionner un compte et faire un 'Impersonate'
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              color="primary"
              @click="showImpersonateMsg = false;"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AccountService from '@/services/account'

export default {
  name: 'Accounts',

  props:{
    impersonate: {
      type: String,
      default: () => ''
    }
  },

  data() {
    return {
      search: '',
      debounce: null,
      hasMoreAccounts: false,
      accounts: [],
      types: [],
      loading: true,
      offset: 0,
      limit: 25,
      showConfirmDelete: false,
      account: null,
      accountOwnerEmail: null,
      showImpersonateMsg: this.impersonate === 'true',
      headers: [
        {text: 'Company', value: 'company'},
        {text: 'Email', value: 'contactEmail'},
        {text: 'Owner email', value: 'owner.email'},
        {text: 'Type', value: 'type'},
        {text: 'Actions', value: 'actions', sortable: false, width: '250px'},
      ]
    }
  },

  watch: {
    search() {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      this.debounce = setTimeout(() => {
        this.filter()
      }, 500)
    },
    types() {
      this.filter()
    }
  },

  mounted() {
    this.fetchAccounts()
  },

  methods: {
    fetchAccounts() {
      if (this.loading) {
        console.warn('loading already in progress !')
      }
      this.loading = true
      const types = this.types.join()
      AccountService.list(this.limit, this.offset, this.search, types).then((data) => {
        this.accounts = this.accounts.concat(data)
        this.loading = false
        this.hasMoreAccounts = data.length >= this.limit
      }).catch(reason => {
        this.loading = false
        alert(reason) // TODO: un beau popup d'erreur!
      })
    },

    filter() {
      this.offset = 0 // reset offset when searching
      this.accounts = []
      this.fetchAccounts()
    },

    loadMore() {
      this.offset += this.limit
      this.fetchAccounts()
    },

    deleteAccount(accountId) {
      const me = this
      AccountService.delete(accountId)
        .then(() => {
          me.showConfirmDelete = false
          me.account = null
          me.accountOwnerEmail = null
          me.accounts = []
          me.fetchAccounts()
        })
        .catch(reason => {
          console.error(reason)
          alert(reason)
        })
    },

    doImpersonate(accountId) {
      let url = `/?impersonate=${accountId}#`
      if (localStorage.impersonateGoToPath) {
        url += localStorage.impersonateGoToPath
        delete localStorage.impersonateGoToPath
      } else {
        url += '/'
      }
      window.location.href = url;
    }
  }
}
</script>

<style scoped>

</style>
