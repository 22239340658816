import i18n from '@/plugins/i18n'

export const required = (value) => {
  return (Boolean(value) && (typeof value !== 'string' || value.length > 0)) || i18n.t('validations.required')
}

export const emailValid = (value) => {
  return !value || /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || i18n.t('validations.email')
}

export const number = (v) => {
  return !v || /^\d+(\.\d+)?$/.test(v) || i18n.t('validations.number')
}

export const currency = (v) => {
  return !v || !isNaN(parseFloat(v)) || i18n.t('validations.currency')
}

export const url = (v) => {
  return !v || /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(v) || i18n.t('validations.url')
}

export const maxLength = max => v => !v || v.length <= max || i18n.t('validations.maxLength')
