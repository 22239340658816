import axios from 'axios'
import {Auth0Client} from '@auth0/auth0-spa-js'
import AccountService from '@/services/account'
import store, {SET_CURRENT_USER} from '@/store/index'
import {SET_CURRENT_ACCOUNT} from '@/store'
import qs from 'qs'
import * as Sentry from '@sentry/vue'

const AUTH0_DOMAIN = 'effiapp.us.auth0.com'
const AUTH0_ISSUER = `https://${AUTH0_DOMAIN}`
const AUTH0_CLIENT_ID = '8bkrwTC82CWYDnl8vlb6YT0FS5FPOGTX'
const auth0Client = new Auth0Client({
  audience: 'https://api.effiapp.com',
  domain: AUTH0_DOMAIN,
  client_id: AUTH0_CLIENT_ID,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
})

export default {

  async changePassword() {
    const user = await auth0Client.getUser()
    const response = await axios.post(`${AUTH0_ISSUER}/dbconnections/change_password`, {
      'client_id': AUTH0_CLIENT_ID,
      'connection': 'Username-Password-Authentication',
      'email': user.email,
    })
    if (response.status >= 300) {
      throw Error(`Request failed: ${response.statusText}`)
    }
    return response.data
  },

  async isLoggedIn() {
    try {
      const token = await auth0Client.getTokenSilently({ignoreCache: false})
      if (token) {
        if (!store.state.user) {
          try {
            const user = await AccountService.getUser()
            if (!user) {
              return false
            }
            store.commit(SET_CURRENT_USER, user)
            // Refresh token in case user meta data has been set (account created or matched) !!
            await this.refreshToken()
          } catch (e) {
            console.error(e)
            alert(e.toString())
            // TODO: error page !!!
          }
        }
        if (!store.state.account) {
          try {
            const account = await AccountService.get()
            if (!account) {
              return false
            }
            store.commit(SET_CURRENT_ACCOUNT, account)
            // Refresh token in case user meta data has been set (account created or matched) !!
            await this.refreshToken()
          } catch (e) {
            console.error(e)
            alert(e.toString())
            // TODO: error page !!!
          }
        }
      }
      return Boolean(token)
    } catch (e) {
      console.log('why login request bruh')
      console.warn(e.toString())
      return false
    }
  },

  async logIn(state, signup) {
    return await auth0Client.loginWithRedirect({
      redirect_uri: window.location.origin + process.env.BASE_URL,
      prompt: 'login',
      appState: state,
      screen_hint: signup === true ? 'signup' : null,
    })
  },

  logout(options) {
    let logoutOptions = options
    if (!options) {
      logoutOptions = {returnTo: window.location.origin}
    }
    Sentry.setUser(null)
    console.log(logoutOptions)
    auth0Client.logout(logoutOptions)
  },

  async getToken() {
    return await auth0Client.getTokenSilently()
  },

  async refreshToken() {
    return await auth0Client.getTokenSilently({
      ignoreCache: true
    })
  },

  async getUser() {
    return await auth0Client.getUser()
  },

  async handleAuthCallback() {
    const location = window.location
    if (!location.search) {
      return
    }
    const query = qs.parse(location.search.replace('?', ''))
    if (!query.code || !query.state) {
      return
    }
    return await auth0Client.handleRedirectCallback(window.location.url)
  },

  async getAuthHeader() {
    // TODO: catch exception and redirect to login ???
    const token = await this.getToken()
    const headers = {'Authorization': `Bearer ${token}`}
    if (store.state.impersonate) {
      headers['X-Effi-Account-Id'] = store.state.impersonate
    }
    return headers
  }
}
