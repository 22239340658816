import axios from 'axios'

const ACCOUNT_SERVICE_URL = ''
const API_SERVICE_URL = ''
const CARRIER_SERVICE_URL = ''
const SHIPPER_SERVICE_URL = ''
const TRACKING_SERVICE_URL = ''
const GEOSERVER_URL = ''
const ENV_TYPE = 'DEV'

export default {
  ACCOUNT_SERVICE_URL,
  API_SERVICE_URL,
  CARRIER_SERVICE_URL,
  SHIPPER_SERVICE_URL,
  TRACKING_SERVICE_URL,
  GEOSERVER_URL,
  ENV_TYPE,
  async loadConfig() {
    const configs = (await axios.get('/config/config.json')).data
    for (const conf in configs) {
      this[conf] = configs[conf]
    }
  },
}
