import axios from 'axios'
import config from '@/services/config'
import auth from '@/plugins/auth'
import {errorHandler} from '@/services/utils'

export default {
  async get(id) {
    try {
      const response = await axios.get(`${config.ACCOUNT_SERVICE_URL}/invitation/${id}`)
      return response.data
    } catch (e) {
      console.log(e)
      throw errorHandler(e)
    }
  },
  async update(invitation) {
    try {
      const response = await axios.patch(`${config.ACCOUNT_SERVICE_URL}/invitation/${invitation.id}`, invitation )
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },
  async acceptInvitation(invitationId) {
    try {
      const response = await axios.patch(`${config.ACCOUNT_SERVICE_URL}/invitation/${invitationId}/accept`, null, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },
}