export const ROUTE_STATUS = {
  DISPATCHED: 'DISPATCHED',
  SEQUENCED: 'SEQUENCED',
  TO_PLAN: 'TO_PLAN',
}

export const TASK_TYPE = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
  SCANOUT: 'scanout',
  RETURN: 'return',
}

export const TASK_STATUS = {
  TODO: 0,
  DONE: 1,
  PROBLEM: 2,
}

export const STOP_STATUS = {
  TO_SEQUENCE: 0,
  TO_COMPLETE: 1,
  DONE: 2,
  START: 3,
  END: 4,
  PROBLEM: 5,
}

export const SHIPPER_LINK_STATUS = {
  NONE: 0,
  ACCEPTED: 1,
  DECLINED: 2,
  PENDING: 3,
}

export const COLORS = {
  EFFI_BLUE: '#84CEF5',
  EFFI_GREEN: '#66FF76',
  EFFI_HIGHLIGHT: '#FFF5D3',
  EFFI_ORANGE: '#FFBA55',
  EFFI_DEEP_ORANGE: '#f85b07',
  EFFI_PURPLE: '#b49ef6',
  EFFI_RED: '#ec4e4e',
  EFFI_YELLOW: '#fae74d',
  LIGHTGRAY: '#D3D3D3',
  MARKER_GRAY: '#A8A8A8',
  MARKER_GREEN: '#00A83E',
  PRIMARY: '#EC3321',
}

export const CARRIER_CONNECTOR_TYPES = {
  API: 'carrierApi',
  COURRIER_PRO: 'courrierpro',
  DUMMY: 'dummyCarrier',
  EFFI: 'effi',
}

export const SHIPPER_CONNECTOR_TYPES = {
  API: 'shipperApi',
  DUMMY: 'dummyShipper',
  MANUAL: 'shipperManual',
  SHOPIFY: 'shopify',
}

export const ACCOUNT_TYPE = {
  ADMIN: 'ADMIN',
  CARRIER: 'CARRIER',
  DRIVER: 'DRIVER',
  SHIPPER: 'SHIPPER',
}
