import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from '@/plugins/router'
import store, {SET_IMPERSONATE} from '@/store'
import auth from '@/plugins/auth'
import i18n, {getLocale} from '@/plugins/i18n'
import VueDayjs from 'vue-dayjs-plugin'
import qs from 'qs'
import 'leaflet/dist/leaflet.css'
import {Icon} from 'leaflet'
import config from '@/services/config'
import AccountService from '@/services/account'
import InvitationService from '@/services/invitation'
import base64 from 'base-64'
import utf8 from 'utf8'
import VueApexCharts from 'vue-apexcharts'
import * as Sentry from '@sentry/vue'
import {Integrations} from '@sentry/tracing'
import _ from 'lodash'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjsFr from 'dayjs/locale/fr'
import localizedFormat from 'dayjs/plugin/localizedFormat'

Vue.use(VueApexCharts)
Vue.use(VueDayjs)
Vue.dayjs.extend(customParseFormat)
Vue.dayjs.extend(localizedFormat)
Vue.dayjs.locale(dayjsFr, null, true)
Vue.dayjs.locale(i18n.locale)

Vue.config.productionTip = false

Vue.prototype._ = _

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

// TODO: intermediate loading 'mask' !!!??

async function init() {
  const redirectLoginResult = await auth.handleAuthCallback()
  await config.loadConfig()
  // commenter ce if en dev pour tester sentry
  const useSentry = config.ENV_TYPE !== 'DEV'
  if (useSentry) {
    Sentry.init({
      Vue,
      dsn: 'https://af3a505a3d75416dab7e7a57b4d881d4@o861418.ingest.sentry.io/5821414',
      integrations: [new Integrations.BrowserTracing()],
      logErrors: true,
      environment: config.ENV_TYPE,
      release: process.env.VUE_APP_BITBUCKET_BUILD_NUMBER || 'DEV',

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.5,
    })
  }
  if (redirectLoginResult) {
    let redirectUrl = '/'
    if (redirectLoginResult.appState) {
      // Is it an invitation or a standard sign up (just account type)
      if (redirectLoginResult.appState.length < 10) {
        // Check if we already have an account ...
        let account = null
        // wrap getCurrentAccount with try-catch to allow account creation after backend validation
        try {
          account = await AccountService.get()
          if (!account) {
            await AccountService.create({
              type: redirectLoginResult.appState.toUpperCase(),
              lang: getLocale()
            })
          }
        } catch (e) {
          // If no account and user found for identity, create an account...
          if (e.toString() === 'Error: No account and no user matched your identity!!' && account === null) {
            await AccountService.create({
              type: redirectLoginResult.appState.toUpperCase(),
              lang: getLocale()
            })
          } else {
            console.error(e)
          }
        }
      } else {
        const state = JSON.parse(utf8.decode(base64.decode(redirectLoginResult.appState)))
        if (state.InvitationId) {
          const account = await InvitationService.acceptInvitation(state.InvitationId)
          // Special driver case ...
          if (account.type === 'DRIVER') {
            redirectUrl = `/#/driver/welcome/${state.InvitationId}`
          }
        } else {
          throw new Error('Invalid state!')
        }
      }
    } else {
      // TODO: ensure a user is created with the token identity ??
    }
    // Refresh token in case user meta data has been set (account created or matched) !!
    await auth.refreshToken()
    window.location.href = redirectUrl // reload page with URL (no weird login query params) ...
  } else {
    // Check if we are impersonating an account (must have impersonate permission in user token for it to work)
    const query = qs.parse(location.search.replace('?', ''))
    if (query.impersonate) {
      store.commit(SET_IMPERSONATE, query.impersonate)
    }
    // Set account in store !!
    await auth.isLoggedIn()
    new Vue({
      i18n,
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
}
init().catch(reason => {
  // TODO: error page !!!???
  if (config.ENV_TYPE !== 'DEV') {
    Sentry.captureException(reason)
  }
  console.error(reason)
  alert(reason)
  window.location.href = '/'
})
