<template>
  <v-container>
    <div style="display:flex; justify-content: center">
      <v-card
        v-if="invitation.status === 2"
        width="500px"
        elevation="2"
        outlined
      >
        <v-card-title style="word-break: break-word;">
          {{ $t('invitation.declineMsg', { name: invitee.company || invitee.email }) }}
        </v-card-title>
        <v-card-text>
          {{ invitation.status === 0 ? $t(`invitation.${invitationType}_msg`, { name: invitee.company || invitee.email }) : $t(`invitation.${invitation.status}_status`) }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            text
            color="primary"
            @click="goSignup"
          >
            {{ invitation.status === 0 ? $t("invitation.accept") : $t("invitation.login") }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        v-else
        width="500px"
        elevation="2"
        outlined
      >
        <v-card-title style="word-break: break-word;">
          {{ $t('invitation.title', { name: invitee.company || invitee.email }) }}
        </v-card-title>
        <v-card-text>
          {{ invitation.status === 0 ? $t(`invitation.${invitationType}_msg`, { name: invitee.company || invitee.email }) : $t(`invitation.${invitation.status}_status`) }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            text
            color="primary"
            @click="goSignup"
          >
            {{ invitation.status === 0 ? $t("invitation.accept") : $t("invitation.login") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import InvitationService from '@/services/invitation'
import qs from 'qs'
import auth from '@/plugins/auth'

export default {
  name: 'InvitationResponse',
  data: () => ({
    invitation: {},
    state: '',
    invitationType: '',
    publicUrl: '',
    invitee: {},
  }),
  async created() {
    const isloggedIn = await auth.isLoggedIn()
    if (isloggedIn) {
      auth.logout({returnTo: window.location})
    }
    if (this.$route.params.id) {
      this.fetchInvitation()
    }
  },
  methods: {
    goSignup() {
      auth.logIn(this.invitation.status === 0 ? this.state : null, true)
    },
    async fetchInvitation() {
      try {
        const resp = await InvitationService.get(this.$route.params.id)
        this.invitation = resp.invitation
        this.state = resp.state
        this.invitationType = resp.invitationType
        this.invitee = resp.invitee
        this.publicUrl = resp.publicUrl
        this.$i18n.locale = this.invitation.lang
        const query = qs.parse(location.search.replace('?', ''))
        if (this.invitation.status === 0 && query.r === 'declined') {
          this.invitation.status = 2
          await this.updateInvitation(this.invitation)
        }
      } catch (e) {
        this.error = e
      }
    },
    async updateInvitation(invitation) {
      try {
        await InvitationService.update(invitation)
      } catch (e) {
        console.log(e)
        this.error = e
      }
    },
  },
  i18n: {
    messages: {
      fr: {
        invitation: {
          login: 'Connexion',
          declineMsg: 'Le refus a bien été enregisté, {name} a été averti.',
          accept: 'Accepter et continuer à la connexion',
          '1_status': 'Cette invitation a été acceptée',
          '2_status': 'Cette invitation a été refusée',
          '3_status': 'Cette invitation a été annulée',
          title: '{name} aimerait vous inviter à utiliser EFFI !',
          SHIPPER_msg: '{name} aimerait vous inviter à lui envoyer des livraisons par EFFI.',
          DRIVER_msg: '{name} aimerait pouvoir vous envoyer des colis à partir de EFFI.',
          USER_msg: '{name} vous invite dans son compte EFFI.',
        }
      },
      en: {
        invitation: {
          login: 'Log in',
          decline: 'Decline',
          declineMsg: 'The refusal has been registered, {name} has been notified.',
          accept: 'Accept and continue to login',
          '1_status': 'This invitation has been accepted !',
          '2_status': 'This invitation has been declined !',
          '3_status': 'This invitation has been canceled !',
          title: '{name} would like to invite you to use EFFI !',
          SHIPPER_msg: '{name} would like to invite you to send him packages with EFFI',
          DRIVER_msg: '{name} would like to be able to send you deliveries from EFFI',
          USER_msg: '{name} invites you into their EFFI account.',
        }
      }
    }
  }
}
</script>

<style scoped>

</style>