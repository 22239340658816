<template>
  <v-container>
    <v-row :justify="'center'">
      <v-col cols="10">
        <v-card>
          <v-card-title>
            {{ $t('title') }}
          </v-card-title>
          <v-container class="pa-8">
            <v-row class="mb-2">
              <v-col>
                <strong>{{ $t('email') }}:</strong> {{ $store.state.user.email }}
                <!-- TODO: allo user to change his email / login ?!!! -->
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-select
                  v-model="user.lang"
                  :items="langs"
                  :label="$t('lang')"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-btn
                  color="primary"
                  :loading="saving"
                  :disabled="saving || !user.lang"
                  @click="save"
                >
                  {{ $t('save') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-alert
                  v-if="$store.state.impersonate"
                  type="warning"
                  elevation="2"
                >
                  {{ $t('password.warning') }}
                </v-alert>
                <v-btn
                  color="primary"
                  class="mb-2"
                  @click="changePassword"
                >
                  {{ $t('password.title') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-dialog
              v-model="showChangePassword"
              width="500"
            >
              <v-card>
                <v-card-title>
                  <span>{{ $t('password.title') }}</span>
                  <v-spacer />
                  <v-icon @click="showChangePassword = false">
                    mdi-close
                  </v-icon>
                </v-card-title>
                <v-card-text>
                  {{ $t('password.email') }}
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    color="primary"
                    @click="showChangePassword = false"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from '@/plugins/auth'
import lodash from 'lodash'
import AccountService from '@/services/account'
import {SET_CURRENT_USER} from '@/store'

export default {
  name: 'Profile',
  inject: ['showErrorDialog'],
  data: function () {
    return {
      saving: false,
      showChangePassword: false,
      langs: [
        {text: 'Français', value: 'fr'},
        {text: 'English', value: 'en'}
      ],
      user: lodash.clone(this.$store.state.user),
    }
  },
  methods: {
    save() {
      this.saving = true
      AccountService.updateUser(this.user)
        .then(() => {
          if (this.user.lang) {
            this.$store.commit(SET_CURRENT_USER, this.user)
          }
        })
        .catch((error) => {
          console.error(error)
          this.showErrorDialog(error)
        }).finally(() => {
          this.saving = false
        })
    },
    changePassword() {
      auth.changePassword()
        .then(() => this.showChangePassword = true)
        .catch((err) => this.showErrorDialog(err))
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'My Profile',
        lang: 'Language',
        email: 'Email',
        password: {
          title: 'Change my password',
          email: 'Please check your email to process with password change.',
          warning: 'You are currently impersonating a user, this will not change their password but your admin user password!'
        },
      },
      fr: {
        title: 'Mon profil',
        lang: 'Langue',
        email: 'Courriel',
        password: {
          title: 'Changer mon mot de passe',
          email: 'Veuillez vérifier votre courriel pour les instructions de changement de mot de passe.',
          warning: 'Vous agissez présentement en tant qu\'un autre utilisateur, si vous changez le password ce sera le votre qui sera changé!'
        },
      }
    }
  }
}
</script>

<style scoped>

</style>