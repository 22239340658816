<template>
  <v-dialog
    v-model="isActive"
    width="450"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ $t('title') }}
      </v-card-title>
      <v-divider />

      <v-container v-if="loading">
        <v-progress-circular
          size="50"
          color="primary"
        />
      </v-container>
      <v-container v-else-if="error">
        <v-alert type="danger">
          {{ error }}
        </v-alert>
      </v-container>
      <v-list v-else>
        <v-list-item-group
          v-model="selectedType"
          color="primary"
        >
          <v-list-item
            v-for="(item, i) in types"
            :key="i"
            :value="item"
          >
            <v-list-item-avatar>
              <v-img
                :alt="item"
                :src="`./rule/${item}.png`"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(`rule.${item}`) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="isActive = false"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ShipperService from '@/services/shipper'

export default {
  name: 'RoutingRuleTypeDialog',

  props: {
    value: {
      type: String,
      default: '',
    },
    show: Boolean,
  },

  i18n: {
    messages: {
      en: {
        title: 'Select rule type',
        rule: {
          singlecarrier: 'Single carrier'
        }
      },
      fr: {
        title: 'Sélection du type de règle',
        rule: {
          singlecarrier: 'Transporteur unique'
        }
      }
    }
  },

  data() {
    return {
      isActive: Boolean(this.show),
      loading: false,
      types: [],
      error: null,
      selectedType: this.value,
    }
  },

  watch: {
    show (val) {
      if (this.isActive !== val) {
        this.isActive = val
      }
    },
    isActive (val) {
      if (Boolean(val) !== this.show) {
        this.$emit('update:show', val)
      }
    },
    selectedType: function(val) {
      this.selectedType = null
      this.$emit('input', val)
      this.isActive = false
      this.$emit('update:show', false)
    },
  },

  mounted() {
    this.loading = true
    ShipperService.ruleTypes()
      .then((types) => this.types = types)
      .catch((error) => this.error = error.toString())
      .finally(() => this.loading = false)
  },
}
</script>

<style scoped>

</style>
