import axios from 'axios'
import auth from '@/plugins/auth'
import {errorHandler} from '@/services/utils'

export const GeocodeService = {
  async geocodeAddress(address) {
    try {
      const response = await axios.post('/address/geocode', address, {
        headers: await auth.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async searchAddress(address) {
    try {
      const response = await axios.post('/address/search', address, {
        headers: await auth.getAuthHeader()
      })
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async autoComplete(search, sessiontoken) {
    try {
      const response = await axios.get('/address/complete', {
        headers: await auth.getAuthHeader(),
        params: {search: search, sessiontoken: sessiontoken}
      })
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async placeDetails(placeId, sessiontoken) {
    try {
      const response = await axios.get('/address/details', {
        headers: await auth.getAuthHeader(),
        params: {placeId, sessiontoken}
      })
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  }

}
export default GeocodeService