import axios from 'axios'
import auth from '@/plugins/auth'
import config from '@/services/config'
import {errorHandler} from '@/services/utils'
import {ProofOfDelivery} from '@/models/tracking/proofOfDelivery'

const urlFromUri = data => ({
  ...data,
  signatureUrl: data.signatureUri ? config.TRACKING_SERVICE_URL + '/' + data.signatureUri : '',
  pictureUrl: data.pictureUri ? config.TRACKING_SERVICE_URL + '/' + data.pictureUri : ''
})

export default {

  async getEvents(trackingId) {
    try {
      const response = await axios.get(`${config.TRACKING_SERVICE_URL}/${trackingId}/reperage-evts`, {})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async getTracking(trackingId) {
    try {
      const response = await axios.get(`${config.TRACKING_SERVICE_URL}/${trackingId}`, {})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async getInvoicingReportData(params) {
    try {
      const response = await axios.get(`${config.TRACKING_SERVICE_URL}/deliveriesReport`, {headers: await auth.getAuthHeader(), params})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async addNotification(trackingId, recipient, type, triggers) {
    try {
      const response = await axios.post(`${config.TRACKING_SERVICE_URL}/${trackingId}/notification`, {
        recipient, type, triggers
      }, {})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  /**
   * Get the proof of delivery content of the related pod identifier
   * @param trackingId The identifier of the tracking
   * @param podId The identifier of the pod
   * @returns {Promise<ProofOfDelivery>} The proof of delivery
   */
  async getPod(trackingId, podId) {
    try {
      const response = await axios.get(`${config.TRACKING_SERVICE_URL}/${trackingId}/pod/${podId}`, {headers: await auth.getAuthHeader()})
      return new ProofOfDelivery(urlFromUri(response.data || {}))
    } catch (e) {
      throw errorHandler(e)
    }
  },

  /**
   * Get the latest proof of deliveries for the specified tracking id
   * @param trackingId The identifier of the tracking
   * @returns {Promise<{delivery: ProofOfDelivery, pickup: ProofOfDelivery}>} Map containing the proof of deliveries
   *  for both the pickup and the delivery
   */
  async getLatestPod(trackingId) {
    try {
      const response = await axios.get(`${config.TRACKING_SERVICE_URL}/${trackingId}/latestPod`)
      return {
        pickup: new ProofOfDelivery(urlFromUri(response.data.pickup || {})),
        delivery: new ProofOfDelivery(urlFromUri(response.data.delivery || {})),
      }
    } catch (e) {
      throw errorHandler(e)
    }
  }
}
