<template>
  <v-dialog
    v-model="isActive"
    :width="width"
    persistent
    style="z-index: 9999"
  >
    <v-card>
      <v-card-title>
        {{ title ? title : $t('title') }}
      </v-card-title>
      <v-card-text>
        <p v-html="message" />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          @click="onConfirm('no')"
        >
          {{ $t('no') }}
        </v-btn>
        <v-btn
          color="primary"
          @click="onConfirm('yes')"
        >
          {{ $t('yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    value: Boolean,
    width: {
      type: Number,
      default: 400
    }
  },
  i18n: {
    messages: {
      fr: {
        title: 'Confirmation',
        yes: 'Oui',
        no: 'Non',
      },
      en: {
        title: 'Confirmation',
        yes: 'Yes',
        no: 'No',
      }
    }
  },

  data() {
    return {
      isActive: Boolean(this.value),
      callback: null,
    }
  },

  watch: {
    value (val) {
      this.isActive = Boolean(val)
    },
    isActive (val) {
      Boolean(val) !== this.value && this.$emit('input', val)
    },
  },

  methods: {
    onConfirm(result) {
      this.$emit('confirm', result)
      this.isActive = false
      if (this.callback) {
        this.callback(result)
      }
    },
    prompt() {
      this.isActive = true
      return new Promise((resolve) => {
        this.callback = resolve
      })
    },
  }

}
</script>

<style scoped>

</style>
