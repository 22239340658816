<template>
  <v-container style="background-color: white; max-width: 850px; height: 100%">
    <v-snackbar v-model="showSnackBar">
      {{ $t('trackingSearchError') }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="showSnackBar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-row
      class="mb-10"
      justify="end"
    >
      <v-btn
        color="primary"
        class="lang-btn"
        @click="changeLang"
      >
        {{ $i18n.locale !== 'fr' ? 'FRANÇAIS' : 'ENGLISH' }}
      </v-btn>
    </v-row>
    <v-row
      justify="center"
      class="mb-10"
    >
      <v-img
        alt="Logo"
        class="shrink mr-6"
        contain
        :src="require('../assets/logo.png')"
        transition="scale-transition"
        width="250"
      />
    </v-row>
    <div v-if="!_.isEmpty(tracking)">
      <v-row
        justify="center"
        class="mb-10"
      >
        <div
          class="text-h5 font-weight-bold"
          v-text="shipmentStatusTitle"
        />
      </v-row>
      <v-row justify="center">
        <div
          style="font-size: 150%"
        >
          {{ $t('trackingNumber') }}
        </div>
      </v-row>
      <v-row
        justify="center"
        class="mb-10"
      >
        <div
          style="color: #EA352C; font-size: 110%"
          class="font-weight-bold"
        >
          {{ trackingId }}
        </div>
      </v-row>
      <v-row
        justify="center"
      >
        <ve-stepper
          alt-labels
          flat
          width="100%"
          :value="shipmentStatus"
        >
          <v-stepper-header>
            <template v-for="step in steps">
              <ve-stepper-step
                :key="`${step.index}-step`"
                :icon="step.index === shipmentStatus ? step.icon : ''"
                notext
                :color="iconColor(step.index)"
                :complete-icon="(step.index === 4 && shipmentStatus === 4) ? 'mdi-check-all' : '$complete'"
                :step="step.index"
                :complete="step.index < shipmentStatus || shipmentStatus === 4"
              >
                <strong v-if="step.index === shipmentStatus">
                  {{ $t(`status.${step.name}`) }}
                </strong>
                <span v-else>
                  {{ $t(`status.${step.name}`) }}
                </span>
              </ve-stepper-step>
              <v-divider
                v-if="step.index !== 4"
                :key="`${step.index}-divider`"
                :style="'border-color:' + dividerColor(step.index)"
              />
            </template>
          </v-stepper-header>
        </ve-stepper>
      </v-row>
      <v-row
        v-if="shipmentStatus !== DELIVERED && !notificationEmail"
        justify="center"
        class="mb-2"
      >
        <v-col class="shrink">
          <v-btn
            text
            color="blue"
            class="text-decoration-underline font-weight-bold"
            @click="showNotificationForm = true"
          >
            {{ $t('notification.add') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-if="showNotificationForm"
        justify="center"
        class="mb-8"
      >
        <v-col cols="8">
          <v-form
            ref="notificationForm"
            v-model="notificationFormValid"
          >
            <v-row
              justify="center"
              dense
            >
              <v-col cols="8">
                <v-text-field
                  v-model="notification.recipient"
                  dense
                  hide-details="auto"
                  solo
                  outlined
                  placeholder="example@email.com"
                  append-icon="mdi-email"
                  :rules="[required, emailValid]"
                  :label="$t('email')"
                />
              </v-col>
            </v-row>
            <v-row
              justify="center"
              dense
            >
              <v-col cols="6">
                <v-btn
                  color="primary"
                  class="mr-2"
                  :disabled="!notificationFormValid"
                  :loading="notificationLoading"
                  @click="addNotification"
                >
                  {{ $t('save') }}
                </v-btn>
                <v-btn
                  :disabled="notificationLoading"
                  @click="showNotificationForm = false"
                >
                  {{ $t('cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row
        v-if="notificationEmail"
        justify="center"
        class="mb-2"
      >
        <v-col style="text-align: center; color: mediumseagreen">
          <span v-html="$t('notification.success', {email: notificationEmail})" />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="mb-10"
      >
        <v-expansion-panels
          style="width: 70%"
        >
          <v-expansion-panel>
            <v-expansion-panel-header style="">
              <strong>{{ $t('history') }}</strong>
              <template v-slot:actions>
                <v-icon color="primary">
                  $expand
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              color="rgba(0,0,0,0.12)"
            >
              <v-row
                v-for="(event, idx) in events"
                :key="idx"
              >
                <v-col
                  cols="1"
                  class="check-tracking"
                >
                  <v-icon
                    :color="getStatusCodeColor(event.statusCode)"
                  >
                    {{ getStatusCodeIcon(event.statusCode) }}
                  </v-icon>
                </v-col>
                <v-col class="tracking-detail">
                  <div>
                    <span class="h5">{{ $t(`statusCode.${event.statusCode}`) }}</span>
                    <div class="caption">
                      {{ $date(event.created).format('LLLL') }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <v-row
        justify="center"
        style="padding-bottom: 15px"
      >
        <div style="width: 70%">
          {{ $t('aboutCarrier') }}<br>
          <strong>{{ $t('carrier') }}</strong>: {{ carrier.company }}<br>
          <strong>{{ $t('phone') }}</strong>: <a :href="`tel:${carrier.contactPhone}`">{{ carrier.contactPhone }}</a><br>
          <strong>{{ $t('email') }}</strong>: <a :href="`mailto:${carrier.contactEmail || carrier.email}`">
            {{ carrier.contactEmail || carrier.email }}
          </a>
          <span>
            <br>
            {{ $t('refNumber') }}: <strong>{{ tracking.carrierId }}</strong>
          </span>
        </div>
      </v-row>
      <v-row
        justify="center"
        class="mb-10"
      >
        <div style="width: 70%">
          {{ $t('aboutShipper') }}<br>
          <div v-if="tracking && tracking.shipperAccountId">
            <strong>{{ $t('shipper') }}</strong>: {{ shipper.company }}<br>
            <strong>{{ $t('phone') }}</strong>: <a :href="`tel:${shipper.contactPhone}`">{{ shipper.contactPhone }}</a><br>
            <strong>{{ $t('email') }}</strong>: <a :href="`mailto:${shipper.contactEmail || shipper.email}`">
              {{ shipper.contactEmail || shipper.email }}
            </a>
          </div>
        </div>
      </v-row>
    </div>
    <v-row justify="center">
      <div style="display: flex;flex-direction: column; width: 70%">
        <span style="padding-bottom: 15px"><v-icon>mdi-map-marker</v-icon>{{ trackingId ? $t('title.another') : $t('title') }}</span>
        <v-text-field
          v-model="search"
          solo
          :placeholder="$t('enterTracking')"
        >
          <template slot="append">
            <v-btn
              :loading="searching"
              color="primary"
              :disabled="search.length < 20"
              @click="onSearchTrackingId"
            >
              {{ $t('follow') }}
            </v-btn>
          </template>
        </v-text-field>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import TrackingService from '@/services/tracking'
import AccountService from '@/services/account'
import VeStepper from '@/components/VeStepper'
import VeStepperStep from '@/components/VeStepperStep'
import ShipperService from '@/services/shipper'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import {required, emailValid} from '@/utils/validation'
import {changeLocale} from '@/plugins/i18n'

export default {
  name: 'Tracking',
  inject: ['showErrorDialog'],
  components: {
    VeStepper,
    VeStepperStep,
  },
  data: () => ({
    SUBMITTED: 1,
    PICKEDUP: 2,
    ENROUTE: 3,
    DELIVERED: 4,
    trackingId: '',
    tracking: {},
    carrier: {},
    shipper: {},
    events: [],
    search: '',
    showSnackBar: false,
    connectorConfigType: '',
    searching: false,
    showNotificationForm: false,
    notificationFormValid: false,
    notificationLoading: false,
    notification: {
      recipient: '',
      type: 'email',
      triggers: 'ALL',
    },
    notificationEmail: '',
    steps: [
      {
        index: 1,
        name: 'order',
        status: 'SUBMITTED',
        icon: 'mdi-clipboard-check-outline',
        color: '#FCBD3F'
      },
      {
        index: 2,
        name: 'pickedup',
        status: 'RECEIVED',
        icon: 'mdi-package-variant-closed',
        color: '#FCBD3F'
      },
      {
        index: 3,
        name: 'transit',
        status: 'TRANSIT',
        icon: 'mdi-truck',
        color: '#FCBD3F'
      },
      {
        index: 4,
        name: 'delivered',
        status: 'DELIVERED',
        icon: '',
        color: '#FCBD3F'
      },
    ]
  }),
  computed: {
    shipmentStatus() {
      if (this.tracking) {
        switch (this.tracking.status) {
        case 'SUBMITTED': return this.SUBMITTED
        case 'RECEIVED': return this.PICKEDUP
        case 'TRANSIT': return this.ENROUTE
        case 'DELIVERED': return this.DELIVERED
        default: return -1
        }
      }
      return -1
    },
    shipmentStatusTitle() {
      const status = this.shipmentStatus
      if (status > -1 && this.events.length > 0) {
        const lastEvent = this.events.find(event => event.id === this.tracking.lastEventId)
        // Handle special "problem" cases
        if (lastEvent.statusCode === 'SD' && status === this.SUBMITTED) {
          return this.$t('titleStatus.5') // Pickup problem!
        } else if (lastEvent.statusCode === 'AH' && (status === this.RECEIVED || status === this.ENROUTE)) {
          return this.$t('titleStatus.6') // Delivery problem!
        }
        return this.$t(`titleStatus.${status}`, {carrier: this.carrier.company || this.carrier.contactEmail})

      }
      return ''
    }
  },
  beforeCreate() {
    this.$vuetify.theme.dark = false
  },
  beforeMount() {
    this.trackingId = this.$route.params.id
    this.fetchTrackingData()
  },
  i18n: {
    messages: {
      fr: {
        title: 'SUIVRE UNE COMMANDE',
        'title.another': 'Suivre une autre commande',
        follow: 'Repérer',
        aboutCarrier: 'Pour toute question concernant la livraison, contactez directement le transporteur.',
        aboutShipper: 'Pour toute question concernant votre commande, contactez directement l\'expéditeur.',
        carrier: 'Transporteur',
        shipper: 'Expéditeur',
        phone: 'Téléphone',
        email: 'Courriel',
        trackingNumber: 'Numéro de suivi',
        refNumber: 'Référence',
        eta: 'Attendu',
        currentStatus: 'Statut',
        trackingSearchError: 'Le numéro de suivi est erroné.',
        status: {
          order: 'Expédiée',
          pickedup: 'Cueillie',
          transit: 'En route',
          delivered: 'Livrée',
        },
        enterTracking: 'Numéro de suivi',
        history: 'Voir l\'historique de la livraison',
        titleStatus: {
          1: 'Commande envoyée chez {carrier}',
          2: 'Votre commande a été cueillie par le transporteur',
          3: 'Votre commande est en route',
          4: 'Votre commande a été livrée',
          5: 'Problème lors de la cueillette de votre commande',
          6: 'Problème avec la livraison de votre commande',
        },
        notification: {
          add: 'Je veux être informé des changements de statut avant la livraison',
          success: 'Vous recevrez toutes les notifications à <strong>{email}</strong>'
        }
      },
      en: {
        title: 'Track a shipment',
        'title.another': 'Track another shipment',
        follow: 'Track',
        aboutCarrier: 'For any question regarding delivery, contact the carrier directly.',
        aboutShipper: 'For any question regarding your order, contact the shipper directly.',
        carrier: 'Carrier',
        shipper: 'Shipper',
        phone: 'Phone',
        email: 'Email',
        trackingNumber: 'Tracking number',
        refNumber: 'Reference',
        eta: 'ETA',
        currentStatus: 'Status',
        trackingSearchError: 'There is an error in the tracking number.',
        status: {
          order: 'Shipped',
          pickedup: 'Picked up',
          transit: 'On the way',
          delivered: 'Delivered',
        },
        enterTracking: 'Enter your tracking number',
        history: 'View tracking history',
        titleStatus: {
          1: 'Order sent to {carrier}',
          2: 'Your order has been picked up by the carrier',
          3: 'Your order is on its way',
          4: 'Your order has been delivered',
          5: 'Problem with the pick up of your order',
          6: 'Problem with the delivery of your order',
        },
        notification: {
          add: 'I want to be notified of status changes before delivery',
          success: 'You will receive all notifications at <strong>{email}</strong>'
        }
      }
    }
  },
  methods: {
    required, emailValid,
    dividerColor(index) {
      if (this.shipmentStatus === this.DELIVERED ) {
        return '#6CBF5D'
      }
      if (index < this.shipmentStatus) {
        return '#FCBD3F'
      }
      return 'rgba(0,0,0,0.12)'
    },
    iconColor(index) {
      if (this.shipmentStatus === this.DELIVERED ) {
        return '#6CBF5D'
      }
      if (index <= this.shipmentStatus) {
        return '#FCBD3F'
      }
      return 'rgba(0,0,0,0.12)'
    },
    async setTracking() {
      this.tracking = await TrackingService.getTracking(this.trackingId)
    },
    async setEvents() {
      this.events = await TrackingService.getEvents(this.trackingId)
    },
    async setCarrierContactInfo() {
      this.carrier = await AccountService.getContactInfos(this.tracking.carrierAccountId)
    },
    async setShipperContactInfo() {
      if (this.tracking.shipperAccountId) {
        this.shipper = await AccountService.getContactInfos(this.tracking.shipperAccountId)
      }
    },
    async setCarrierConfig() {
      if (this.tracking.carrierConnectorConfigId) {
        this.connectorConfigType = await ShipperService.getConnectorType(this.tracking.carrierConnectorConfigId)
      }
    },
    fetchTrackingData() {
      const handleRejection = (rejects => {
        const rejectedReasons = rejects.map(v => v.reason)
        if (rejectedReasons.length > 0) {
          const reason = rejectedReasons.join('\n')
          console.warn(reason)
          this.showErrorDialog(rejectedReasons[0])
        }
      })
      if (this.trackingId) {
        Promise.allSettled([this.setTracking(), this.setEvents()]).then(values => {
          const rejects = values.filter(v => v.status === 'rejected')
          handleRejection(rejects)
          if (rejects.length > 0) {
            return
          }
          Promise.allSettled([this.setCarrierContactInfo(), this.setShipperContactInfo(), this.setCarrierConfig()]).then(infoValues => {
            handleRejection(infoValues.filter(v => v.status === 'rejected'))
          })
        })
      }
    },
    changeLang() {
      this.$i18n.locale = this.$i18n.locale === 'en' ? 'fr' : 'en'
      changeLocale(this.$i18n.locale)
    },
    onSearchTrackingId() {
      this.search = this.search.replace(/\s/g, '')
      // checks if the string is alphanumerical
      if (/^[a-z0-9]{20,21}$/i.test(this.search)){
        this.$router.push(`/tracking/${this.search}`).catch(err => {
          // Ignore redundant navigation error...
          if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')){
            throw err
          }
        })
      } else {
        this.showSnackBar = true
      }
    },
    getStatusFromStatusCode(statusCode) {
      switch (statusCode) {
      case 'XB':
      case 'AG':
      case 'SD':
        return this.SUBMITTED
      case 'SC':
      case 'X4':
        return this.PICKEDUP
      case 'AH':
      case 'X6':
      case 'AF':
        return this.ENROUTE
      case 'D1':
        return this.DELIVERED
      default:
        return this.SUBMITTED
      }
    },
    getStatusIcon(status) {
      switch (status) {
      case this.SUBMITTED: return 'mdi-clipboard-check'
      case this.PICKEDUP: return 'mdi-package-variant-closed'
      case this.ENROUTE: return 'mdi-truck'
      case this.DELIVERED: return 'mdi-home'
      default: return ''
      }
    },
    getStatusCodeIcon(statusCode) {
      switch (statusCode) {
      case 'SD':
      case 'SC':
      case 'AH':
        return 'mdi-alert'
      default:
        return 'mdi-check'
      }
    },
    getStatusCodeColor(statusCode) {
      switch (statusCode) {
      case 'SD':
      case 'SC':
      case 'AH':
        return 'warning'
      default:
        return '#6CBF5D'
      }
    },
    async addNotification() {
      if (this.$refs.notificationForm.validate()) {
        this.notificationLoading = true
        try {
          await TrackingService.addNotification(
            this.trackingId, this.notification.recipient, this.notification.type, this.notification.triggers
          )
          this.showNotificationForm = false
          this.notificationEmail = this.notification.recipient
          this.notification.recipient = ''
        } catch (e) {
          this.showErrorDialog(e)
        }
        this.notificationLoading = false
      }
    }
  }
}
</script>

<style scoped>
  .tracking {
    background: url('../assets/shutterstock_541216408_dark.jpg') center center;
    background-size: cover;
  }
  .trackingForm {
    background: url('../assets/shutterstock_171790508_dark.jpg') center center;
    background-size: cover;
  }
  .grey-bg {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .grey-light {
    background-color: lightgray;
  }
  .primary-bg {
    background-color: rgba(236, 51, 33, 0.8);
  }
  .center {
    text-align: center;
  }
  .right {
    text-align: right;
  }
  .v-divider {
    border-bottom-width: 5px !important;
  }
  .check-tracking {
    max-width: 5%;
  }
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .text-h5 {
      font-size: 17px !important;
    }
    .tracking-detail {
      margin-left: 5px;
    }
  }
  @media only screen and (max-width: 400px) {
    /* For mobile phones: */
    .text-h5 {
      font-size: 14px !important;
    }

  }

</style>
