<template>
  <v-dialog
    v-model="show"
    width="450"
    @click:outside="cancel"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ $t('title') }}
      </v-card-title>
      <v-divider />

      <v-container
        v-if="loading"
        align="center"
      >
        <v-progress-circular
          size="50"
          color="primary"
        />
      </v-container>
      <v-container v-else-if="error">
        <v-alert type="danger">
          {{ error }}
        </v-alert>
      </v-container>
      <v-list v-else>
        <v-list-item-group
          v-model="selectedType"
          color="primary"
        >
          <v-list-item
            v-for="(item, i) in types"
            :key="i"
            :value="item.name"
          >
            <v-list-item-avatar>
              <v-img
                :alt="item.name"
                :src="`./connector/${item.name}.png`"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.label" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="cancel"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ShipperService from '@/services/shipper'

export default {
  name: 'ConnectorTypeDialog',

  i18n: {
    messages: {
      en: {
        title: 'Select connector type'
      },
      fr: {
        title: 'Sélection du type de connecteur'
      }
    }
  },

  data: () => ({
    show: false,
    loading: false,
    types: [],
    callback: null,
    error: null,
    errorCallback: null,
    selectedType: null,
  }),

  watch: {
    selectedType: function(val) {
      this.show = false
      this.selectedType = null
      this.callback(val)
    },
  },

  methods: {

    cancel() {
      this.show = false
      this.callback(null)
    },

    prompt() {
      this.show = true
      this.loading = true
      ShipperService.connectorTypes()
        .then((types) => {
          this.types = types
        })
        .catch((error) => {
          this.error = error.toString()
        })
        .finally(() => {
          this.loading = false
        })
      return new Promise((resolve, reject) => {
        this.callback = resolve
        this.errorCallback = reject
      })
    }
  }
}
</script>

<style scoped>

</style>
