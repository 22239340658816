<template>
  <v-container>
    <v-row class="mt-4">
      <v-col>
        <h2>{{ $t('title') }}</h2>
      </v-col>
      <v-spacer />
      <v-col class="text-right">
        <v-btn
          v-if="hasManualConnector"
          to="/shipper/order/create"
          color="primary"
        >
          {{ $t('create') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="mt-2"
    >
      <apexcharts
        type="bar"
        height="450"
        width="750"
        :options="options"
        :series="series"
      />
    </v-row>
    <v-dialog
      v-model="hasNoConnector"
      width="400"
    >
      <v-card>
        <v-card-title>Configuration</v-card-title>
        <v-card-text>
          <span v-html="$t('noConnector.msg')" />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            @click="hasNoConnector = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            to="/shipper/config"
            color="primary"
          >
            {{ $t('noConnector.action') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="hasNoRoutingRule"
        width="400"
    >
      <v-card>
        <v-card-title>Configuration</v-card-title>
        <v-card-text>
          <span v-html="$t('noRoutingRule.msg')" />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
              color="primary"
              @click="hasNoRoutingRule = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
              to="/shipper/config?tab=rules"
              color="primary"
          >
            {{ $t('noRoutingRule.action') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AccountInfoDialog />
  </v-container>
</template>

<script>
import ShipperService from '@/services/shipper'
import VueApexCharts from 'vue-apexcharts'
import AccountInfoDialog from '@/components/AccountInfoDialog'

const today = new Date()

export default {
  name: 'ShipperDashboard',
  components: {
    AccountInfoDialog,
    apexcharts: VueApexCharts
  },
  i18n: {
    messages: {
      fr: {
        title: 'Tableau de bord',
        create: 'Créer une commande',
        graphTitleLabel: 'Livraisons des 3 derniers mois',
        yaxisLabel: '# Livraisons',
        seriesLabels: {
          delivered: 'Livraisons',
        },
        months: {
          0: 'Janvier',
          1: 'Février',
          2: 'Mars',
          3: 'Avril',
          4: 'Mai',
          5: 'Juin',
          6: 'Juillet',
          7: 'Août',
          8: 'Septembre',
          9: 'Octobre',
          10: 'Novembre',
          11: 'Décembre',
        },
        noConnector: {
          msg: 'Il n\'y a pas de connecteur configuré dans votre compte.<br/><strong>Voulez-vous en ajouter un maintenant?</strong>',
          action: 'Oui emmenez-moi là-bas maintenant',
        },
        noRoutingRule: {
          msg: 'Il n\'y a pas de règle de routage vers un transporteur configuré dans votre compte.<br/><strong>Voulez-vous en ajouter une maintenant?</strong>',
          action: 'Oui emmenez-moi là-bas maintenant',
        },
        accountInfo: {
          title: 'Informations manquantes',
          msg: 'Certaines informations nécessaires sont manquantes.',
          action: 'Redirigez-moi vers mon compte',
        }
      },
      en: {
        title: 'Dashboard',
        create: 'Create an order',
        graphTitleLabel: 'Shipments last 3 Months',
        yaxisLabel: '# Shipments',
        seriesLabels: {
          delivered: 'Shipments',
        },
        months: {
          0: 'January',
          1: 'February',
          2: 'March',
          3: 'April',
          4: 'May',
          5: 'June',
          6: 'July',
          7: 'August',
          8: 'September',
          9: 'October',
          10: 'November',
          11: 'December',
        },
        noConnector: {
          msg: 'There is no connector configured in your account.<br/><strong>Do you want to add one now?</strong>',
          action: 'Yes take me there now',
        },
        noRoutingRule: {
          msg: 'There is no carrier routing rule configured in your account.<br/><strong>Do you want to add one now?</strong>',
          action: 'Yes take me there now',
        }
      }
    }
  },
  data: () => ({
    series: [],
    hasNoRoutingRule: false,
  }),
  computed: {
    connectors() {
      return this.$store.state.connectors
    },
    hasManualConnector() {
      return this.connectors != null && this.connectors.findIndex((connector) => connector.connectorType === 'shipperManual') > -1
    },
    hasNoConnector() {
      return this.connectors != null && this.connectors.length === 0
    },
    options() {
      const last3Months = []
      for (let i = 0; i < 3; i++) {
        last3Months.push(this.$t(`months.${today.getMonth() - i}`))
      }
      return {
        chart: {
          type: 'bar',
        },
        title: {
          text: this.$t('graphTitleLabel'),
          offsetX: 15
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        series: this.series,
        xaxis: {
          categories: last3Months.reverse()
        },
        yaxis: {
          title: {
            text: this.$t('yaxisLabel'),
          }
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val
            }
          }
        }
      }
    },
  },
  beforeMount() {
    this.fetchStats()
    this.checkConnectors()
  },
  methods: {
    async checkConnectors() {
      const rules = await ShipperService.listRules()
      this.hasNoRoutingRule = rules.length === 0
    },
    async fetchStats() {
      const stats = await ShipperService.lastThreeMonths()
      const counts = stats ? stats.map(stat => stat.orderCount) : []
      this.series = [{
        name: this.$t('seriesLabels.delivered'),
        data: counts,
        color: '#EC3321'
      }]
    }
  }
}
</script>

<style scoped>

</style>
