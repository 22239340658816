<template>
  <v-container>
    <v-row
      v-if="!routingRule"
      justify="center"
    >
      <v-col v-if="!loading">
        <v-btn
          color="primary"
          @click="selectRuleType = true"
        >
          {{ $t('rule.add') }}
        </v-btn>
        <RoutingRuleTypeDialog
          v-model="selectedRuleType"
          :show.sync="selectRuleType"
        />
      </v-col>
      <v-col
        v-else
        class="shrink"
      >
        <v-progress-circular
          color="primary"
          indeterminate
          size="50"
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-card>
          <v-row>
            <v-col cols="2">
              <v-img
                class="shrink ml-4"
                :src="`./rule/${routingRule.type}.png`"
                max-width="75"
              />
            </v-col>
            <v-col>
              <v-card-title class="pl-0">
                {{ $t('rule.' + routingRule.type) }}
              </v-card-title>
              <v-form
                ref="form"
                v-model="isValid"
              >
                <component
                  :is="routingRule.type"
                  ref="configEditor"
                  :config="JSON.parse(routingRule.config)"
                  :edit-mode="editMode"
                  class="mr-2"
                />
              </v-form>
              <v-row
                v-if="editMode"
                class="mt-2 mb-2"
              >
                <v-col>
                  <v-btn
                    color="primary"
                    :loading="processing"
                    @click="save"
                  >
                    {{ $t('save') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="ml-4"
                    @click="cancelEdit"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-else
                class="mt-2 mb-2"
              >
                <v-btn
                  color="primary"
                  @click="editMode = true"
                >
                  {{ $t('edit') }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="ml-4"
                  :loading="processing"
                  :disabled="processing"
                  @click="deleteRule"
                >
                  {{ $t('delete') }}
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShipperService from '@/services/shipper'
import RoutingRuleTypeDialog from '@/components/RoutingRuleTypeDialog'
import SingleCarrier from '@/components/rules/SingleCarrier'

export default {
  name: 'RoutingRuleEdit',
  inject: ['showErrorDialog'],
  components: {
    RoutingRuleTypeDialog,
    singlecarrier: SingleCarrier,
  },
  i18n: {
    messages: {
      en: {
        rule: {
          add: 'Add a routing rule',
          singlecarrier: 'Single carrier',
        }
      },
      fr: {
        rule: {
          add: 'Ajouter une règle de routage',
          singlecarrier: 'Transporteur unique',
        }
      }
    }
  },
  data: () => ({
    loading: false,
    isValid: false,
    processing: false,
    selectRuleType: false,
    selectedRuleType: null,
    routingRule: null,
    carrierConfig: null,
    editMode: false,
  }),
  watch: {
    selectedRuleType(val) {
      if (val && (!this.routingRule || !this.routingRule.type)) {
        this.routingRule = {
          accountId: this.$store.state.account.id,
          index: 0,
          type: val,
          config: '{}',
        }
        this.editMode = true
      }
    },
  },
  mounted() {
    ShipperService.listRules()
      .then((rules) => {
        if (rules && rules.length > 0) {
          this.routingRule = rules[0]
        }
      })
      .catch((error) => {
        console.error(error)
        this.showErrorDialog(error)
      })
  },
  methods: {
    cancelEdit() {
      if (!this.routingRule.id) // create mode cancel ??
      {
        this.routingRule = null
      }
      if (this.$refs.configEditor) {
        this.$refs.configEditor.reset()
      }
      this.editMode = false
      this.selectedRuleType = null
    },
    save() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.routingRule.config = this.$refs.configEditor.getConfig()
      this.processing = true
      ShipperService.saveRule(this.routingRule)
        .then(value => {
          this.routingRule = value
          this.editMode = false
          this.$refs.configEditor.saveCarrierConfigs()
        })
        .catch(reason => this.showErrorDialog(reason))
        .finally(() => this.processing = false)
    },
    deleteRule() {
      this.processing = true
      ShipperService.deleteRule(this.routingRule.id)
        .then(() => {
          this.editMode = false
          this.routingRule = null
          // TODO: delete related carrier shipper configs ???
        })
        .catch((error) => this.showErrorDialog(error))
        .finally(() => this.processing = false)
    },
  },

}
</script>

<style scoped>

</style>
