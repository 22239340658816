<template>
  <v-row>
    <v-col>
      <v-row v-if="!editMode">
        <v-card-text>
          URL:
          <v-btn
            :href="updatedConfig.baseUri"
            plain
            text
            target="_blank"
          >
            {{ updatedConfig.baseUri }}&nbsp;<v-icon>mdi-open-in-browser</v-icon>
          </v-btn>
          <br>
          API key:
          <code style="overflow-wrap: anywhere;">{{ updatedConfig.token }}</code>
        </v-card-text>
      </v-row>
      <v-row v-else>
        <v-col class="pr-8">
          <v-form
            ref="form"
            v-model="isValid"
          >
            <v-text-field
              v-model="updatedConfig.baseUri"
              placeholder="https://my.server.com/effi/api/v1"
              :rules="[required, url]"
              outlined
              dense
              append-icon="mdi-asterisk"
              label="API Base Url"
            />
            <v-textarea
              v-model="updatedConfig.token"
              label="API token"
              rows="3"
              :rules="[required]"
              auto-grow
              outlined
              dense
              append-icon="mdi-asterisk"
            />
            <v-text-field
              v-model="updatedConfig.version"
              placeholder="1.0"
              :rules="[required]"
              label="API Version"
              outlined
              dense
              append-icon="mdi-asterisk"
            />
            <v-checkbox
                v-model="updatedConfig.debug"
                label="Debug"
                outlined
                dense
            />
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {required, url} from '@/utils/validation'

export default {
  name: 'CarrierApi',

  props: {
    editMode: Boolean,
    connectorConfigId: {
      type: String,
      default: () => '',
    },
    connectorConfig: {
      type: Object,
      default: () => ({
        manual: true,
        partiallyFulfilled: true,
        defaultDimensions: {},
      })
    }
  },

  data() {
    return {
      isValid: false,
      updatedConfig: this.connectorConfig,
    }
  },

  methods: {
    required,
    url,
    valid() {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style scoped>

</style>
