import axios from 'axios'
import auth from '@/plugins/auth'
import config from '@/services/config'
import qs from 'qs'
import store, {SET_NEW_ACCOUNT_OWNER} from '@/store/index'
import {errorHandler} from '@/services/utils'

export const AccountService = {

  async get() {
    try {
      const response = await axios.get(`${config.ACCOUNT_SERVICE_URL}/current`, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async getUser() {
    try {
      const response = await axios.get(`${config.ACCOUNT_SERVICE_URL}/user/current`, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async getContactInfos(accountId) {
    try {
      const response = await axios.get(`${config.ACCOUNT_SERVICE_URL}/${accountId}/contact`, {})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async getSettings() {
    try {
      const response = await axios.get(`${config.ACCOUNT_SERVICE_URL}/settings`, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async saveSettings(settings) {
    try {
      const response = await axios.post(`${config.ACCOUNT_SERVICE_URL}/settings`, settings, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async create(account) {
    try {
      const response = await axios.post(`${config.ACCOUNT_SERVICE_URL}/create`, account, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async updatePrefs(account) {
    try {
      const response = await axios.patch(`${config.ACCOUNT_SERVICE_URL}/prefs`, account, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async updateUser(user) {
    try {
      const response = await axios.patch(`${config.ACCOUNT_SERVICE_URL}/user`, user, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async updateAccountType(type) {
    try {
      const response = await axios.patch(`${config.ACCOUNT_SERVICE_URL}/type`, type, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async updateConsent() {
    try {
      const response = await axios.patch(`${config.ACCOUNT_SERVICE_URL}/consent`, "", {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async list(limit, offset, search, types) {
    if (!limit) {
      limit = 50
    }
    if (!offset) {
      offset = 0
    }
    try {
      const response = await axios.get(`${config.ACCOUNT_SERVICE_URL}/admin/list`, {
        headers: await auth.getAuthHeader(),
        params: {
          limit,
          offset,
          search,
          types
        },
        paramsSerializer: params => {
          return qs.stringify(params, {indices: false})
        }
      })
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async searchCarriers(search) {
    try {
      const response = await axios.get(`${config.ACCOUNT_SERVICE_URL}/search/carrier`, {
        headers: await auth.getAuthHeader(),
        params: {search}
      })
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async sendSupportTicket(ticket) {
    try {
      const response = await axios.post(`${config.ACCOUNT_SERVICE_URL}/ticket`, ticket)
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async transferAccountOwnerShip(accountId, userId) {
    try {
      const response = await axios.post(`${config.ACCOUNT_SERVICE_URL}/${accountId}/transferOwnership`, {userId}, {headers: await auth.getAuthHeader()})
      store.commit(SET_NEW_ACCOUNT_OWNER, response.data)
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  },

  async delete(accountId) {
    try {
      const response = await axios.delete(`${config.ACCOUNT_SERVICE_URL}/admin/${accountId}/delete`, {headers: await auth.getAuthHeader()})
      return response.data
    } catch (e) {
      throw errorHandler(e)
    }
  }
}
export default AccountService