<template>
  <div style="display: inline;">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
      z-index="10000"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="time"
          :label="label"
          :rules="rules"
          :error="error"
          prepend-inner-icon="mdi-clock-time-four-outline"
          readonly
          outlined
          hide-details="auto"
          dense
          v-bind="attrs"
          :disabled="disabled"
          :append-icon="clearable ? 'mdi-close' : ''"
          v-on="on"
          @click:append="time=''"
        />
      </template>
      <v-time-picker
        v-if="menu"
        v-model="time"
        :disabled="disabled"
        full-width
        :format="format"
        :allowed-hours="allowedHours"
        :allowed-minutes="allowedMinutes"
        @click:minute="$refs.menu.save(time)"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'TimePickerField',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: '',
    },
    disabled: Boolean,
    rules: {
      type: Array,
      default: () => [],
    },
    otherTime: {
      type: String,
      default: '',
    },
    isBefore: Boolean,
    isAfter: Boolean,
    clearable: Boolean,
    error: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      time: this.value || '',
      menu: false,
    }
  },
  computed: {
    otherTimeHours(){
      if (this.otherTime && this.otherTime.indexOf(':') !== -1){
        return this.otherTime.split(':')[0]
      }
      return ''
    },
    timeHours(){
      if (this.time && this.time.indexOf(':') !== -1){
        return this.time.split(':')[0]
      }
      return ''
    },
    otherTimeMinutes(){
      if (this.otherTime && this.otherTime.indexOf(':') !== -1) {
        return this.otherTime.split(':')[1]
      }
      return ''
    }
  },
  watch: {
    value(val) {
      this.time = val
    },
    time: function (val) {
      if (val !== this.value) {
        this.$emit('input', this.time)
      }
    }
  },
  methods: {
    allowedHours(value){
      if (!this.otherTime){
        return true
      }
      return this.isBefore ? value >= this.otherTimeHours : value <= this.otherTimeHours
    },
    allowedMinutes(value){
      if (this.timeHours !== this.otherTimeHours || this.timeHours === ''){
        return true
      }
      return this.isBefore ? value > this.otherTimeMinutes : value < this.otherTimeMinutes
    }
  }
}
</script>

<style scoped>

</style>
