<template>
  <div
    class="px-2"
    :style="`width: ${width}px`"
    @click="onSort"
  >
    <slot
      :isSorted="isSorted"
      :isSortedReverse="isSortedReverse"
    >
      <span>{{ label }}</span>
      <v-icon v-if="isSorted">
        mdi-chevron-up
      </v-icon>
      <v-icon v-else-if="isSortedReverse">
        mdi-chevron-down
      </v-icon>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'ColumnHeader',
  props: {
    width: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    sortKey: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },

  computed: {
    reverseKey() {
      return `${this.sortKey} DESC`
    },
    isSorted() {
      return this.sortKey && this.value === this.sortKey
    },
    isSortedReverse() {
      return this.sortKey && this.value === this.reverseKey
    }
  },

  methods: {
    onSort() {
      if (this.sortKey) { // means "sortable"
        if (this.isSorted) {
          this.$emit('input', this.reverseKey)
        } else {
          this.$emit('input', this.sortKey)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
