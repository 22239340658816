<template>
  <Users :list-users="listUsers" :deactivate-user="deactivateUser" invite-path="/shipper/users/invite" />
</template>

<script>
import Users from "@/pages/Users"
import ShipperService from '@/services/shipper'

export default {
  components: {Users},
  methods: {
    listUsers: ShipperService.listUsers,
    deactivateUser: ShipperService.deactivateUser,
  }
}
</script>