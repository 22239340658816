<template>
  <span v-if="hasInfos">
    <a
      class="primary--text text-decoration-underline"
      @click="onShow"
    >
      {{ $t(trackingEvent ? 'displayPartial': 'display') }}
    </a>
    <v-dialog
      v-model="isActive"
      :width="width"
      style="z-index: 9999;"
    >
      <v-card rounded="lg">
        <v-card-title
          class="primary white--text elevation-0"
          height="50"
        >
          <span class="font-weight-bold">{{ $t('title', {trackingId}) }}</span>
          <v-spacer />
          <a @click="onClose"><v-icon class="white--text">mdi-close</v-icon></a>
        </v-card-title>
        <v-card-text>
          <template v-for="step in ['pickup', 'delivery']">
            <div
              v-if="steps[step].hasInfos()"
              :key="step+'-signature-text'"
              class="font-weight-bold mt-2"
            >
              {{ $t(step + '.proof') }}
            </div>
            <div
              v-if="steps[step].hasSignature()"
              :key="step+'-signature'"
            >
              {{ $t(step + '.signature') }}: <span class="font-italic">{{ steps[step].signatureText }}</span>
              <div><img
                :src="steps[step].signatureUrl"
                style="max-width: 100%"
              ></div>
            </div>
            <div
              v-if="steps[step].hasPicture()"
              :key="step+'-picture'"
            >
              {{ $t(step + '.photo') }}
              <div><img
                :src="steps[step].pictureUrl"
                style="max-width: 100%"
              ></div>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>

import TrackingService from '@/services/tracking'
import {ProofOfDelivery} from '@/models/tracking/proofOfDelivery'

const DELIVERY_CODE_STATUS_CODE = 'D1'
const PICKUP_CODE_STATUS_CODE = 'X4'

/**
 * Component to display proof of deliveries in a popup.
 */
export default {
  name: 'ProofOfDeliveriesPopup',
  props: {
    width: {
      type: String,
      required: true
    },
    trackingId: {
      type: String,
      required: true
    },
    trackingEvent: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      isActive: false,
      steps: {
        pickup: new ProofOfDelivery(),
        delivery: new ProofOfDelivery(),
      }
    }
  },
  computed: {
    hasInfos() {
      return this.steps.delivery.hasInfos() || this.steps.pickup.hasInfos()
    }
  },
  async mounted() {
    if (this.trackingEvent) {
      if (this.trackingEvent.podId) {
        const proof = await TrackingService.getPod(this.trackingId, this.trackingEvent.podId)
        switch (this.trackingEvent.statusCode) {
        case DELIVERY_CODE_STATUS_CODE:
          this.steps.delivery = proof
          break
        case PICKUP_CODE_STATUS_CODE:
          this.steps.pickup = proof
          break
        default:
          console.warn(`Unknown event status code: ${this.trackingEvent.statusCode}`)
        }
      } else {
        // means the event doesn't have a pod, so nothing to do here ...
      }
    } else {
      const proofs = await TrackingService.getLatestPod(this.trackingId)
      this.steps.delivery = proofs['delivery'] || new ProofOfDelivery()
      this.steps.pickup = proofs['pickup'] || new ProofOfDelivery()
    }
  },
  methods: {
    onClose() {
      this.isActive = false
    },
    onShow() {
      this.isActive = true
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'Proof of the shipment {trackingId}',
        display: 'View proof of delivery',
        displayPartial: 'Proof ',
        pickup: {
          proof: 'Pick up proof',
          signature: 'Pick up signature',
          photo: 'Pick up picture',
        },
        delivery: {
          proof: 'Delivery proof',
          signature: 'Delivery signature',
          photo: 'Delivery picture',
        },
      },
      fr: {
        title: 'Preuves liées à l\'envoi {trackingId}',
        display: 'Consulter les preuves de livraison',
        displayPartial: 'Preuve',
        pickup: {
          proof: 'Preuve de cueillette',
          signature: 'Signature à la cueillette',
          photo: 'Photo à la cueillette',
        },
        delivery: {
          proof: 'Preuve de livraison',
          signature: 'Signature à la livraison',
          photo: 'Photo à la livraison',
        },
      }
    }
  }
}
</script>