import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@/plugins/auth'
import Account from '@/pages/Account'
import Profile from '@/pages/Profile'
import store from '@/store'
import InvitationResponse from '@/pages/InvitationResponse'
import SignUp from '@/pages/SignUp'
import ShipperDashboard from '@/pages/shipper/ShipperDashboard'
import ShipperConfig from '@/pages/shipper/ShipperConfig'
import ShipperShipments from '@/pages/shipper/ShipperShipments'
import ShipperShipmentsUnfulfilled from '@/pages/shipper/ShipmentsUnfulfilled'
import ShipperShipmentsFulfilled from '@/pages/shipper/ShipmentsFulfilled'
import ShipperOrderEdit from '@/pages/shipper/OrderEdit'
import ShipperUsers from '@/pages/shipper/Users'
import ShipperInvitation from '@/pages/shipper/Invitation'
import AdminAccounts from '@/pages/admin/Accounts'
import AdminReports from '@/pages/admin/Reports'
import Tracking from '@/pages/Tracking'
import Sentry from '@/pages/Sentry'

Vue.use(VueRouter)

function queryAsProps(route) {
  return {...route.query, ...route.params}
}

const SHIPPER_META = {requiresAuth: true, accountType: ['SHIPPER']}

const routes = [
  {path: '/signup', component: SignUp, name: 'signUp',
    meta: {requiresAuth: false}},
  {path: '/tracking', component: Tracking, name: 'trackingForm',
    meta: {requiresAuth: false}},
  {path: '/tracking/:id', component: Tracking, name: 'tracking',
    meta: {requiresAuth: false}},
  {path: '/account', component: Account, name: 'account', props: queryAsProps,
    meta: {requiresAuth: true, accountType: ['CARRIER', 'DRIVER', 'SHIPPER'], ownerOnly: true}},
  {path: '/profile', component: Profile, name: 'profile', props: queryAsProps, meta: {requiresAuth: true}},
  {path: '/account/invitation/:id', component: InvitationResponse, name: 'invitationResponse',
    meta: {requiresAuth: false, accountType: ['CARRIER', 'DRIVER', 'SHIPPER']}},
  {path: '/shipper/dashboard', component: ShipperDashboard, name: 'shipperDashboard',
    meta: SHIPPER_META},
  {path: '/shipper/config', component: ShipperConfig, name: 'shipperConfig', props: queryAsProps,
    meta: SHIPPER_META},
  {path: '/shipper/order/create', component: ShipperOrderEdit, name: 'shipperOrderCreate', props: queryAsProps,
    meta: SHIPPER_META},
  {path: '/shipper/order/:id', component: ShipperOrderEdit, name: 'shipperOrderEdit', props: queryAsProps,
    meta: SHIPPER_META},
  {path: '/shipper/shipments', component: ShipperShipments, name: 'shipperShipments', props: queryAsProps,
    meta: SHIPPER_META,
    children: [
      {path: 'unfulfilled', component: ShipperShipmentsUnfulfilled, props: queryAsProps, meta: SHIPPER_META},
      {path: 'fulfilled', component: ShipperShipmentsFulfilled, props: queryAsProps, meta: SHIPPER_META},
    ]
  },
  {path: '/shipper/users', component: ShipperUsers, name: 'shipperUsers',
    meta: SHIPPER_META},
  {path: '/shipper/users/invite', component: ShipperInvitation, name: 'inviteUser', props: queryAsProps,
    meta: {requiresAuth: true, accountType: ['SHIPPER']}},
  {path: '/admin/accounts', component: AdminAccounts, name: 'adminAccounts', props: queryAsProps,
    meta: {requiresAuth: true, accountType: ['ADMIN']}},
  {path: '/admin/reports', component: AdminReports, name: 'adminReports',
    meta: {requiresAuth: true, accountType: ['ADMIN']}},

  {path: '/test/sentry', component: Sentry, name: 'sentry', meta: {requiresAuth: true, accountType: ['ADMIN']}},
]

const router = new VueRouter({routes})
router.beforeEach((to, from, next) => {
  // If we already have an account and we try to access the signup page, redirect to the account type home page.  We
  // don't want to create a new account.
  if (store.state.account && to.path === '/signup') {
    next('/')
    return
  }

  if (to.matched.some(route => !route.meta.requiresAuth)) { // "pass through" for anonymous pages
    next()
    return
  }
  auth.isLoggedIn().then((isLoggedIn) => {
    if (isLoggedIn) {
      if (localStorage.goToPath) {
        next(localStorage.goToPath)
        delete localStorage.goToPath
        return
      }
      if (to.path === '/' || !to.matched.some(route => !route.meta.accountType ||
          route.meta.accountType.includes(store.state.account.type))) {
        switch (store.state.account.type) {
        case 'CARRIER':
          next('/account')
          break
        case 'DRIVER':
          next('/account')
          break
        case 'SHIPPER':
          next('/shipper/dashboard')
          break
        case 'ADMIN': {
          // Save requested protected URL and allow to impersonate before going back there
          const route = to.matched[0]
          if (to.path !== '/' && route && route.meta.requiresAuth) {
            localStorage.impersonateGoToPath = to.fullPath
            next('/admin/accounts?impersonate=true')
          } else {
            next('/admin/accounts')
          }
          break
        }
        default:
          next('/signup')
        }
      } else {
        // route is for owner only and you're not the owner
        if (to.matched.some(route => route.meta['ownerOnly'] && route.meta.ownerOnly)) {
          if (((store.state.account.ownerId === store.state.user.id) || store.state.impersonate)) {
            next()
          } else {
            next('/')
          }
        }
        next()
      }
    } else if (to.matched.some(route => !route.meta.requiresAuth)) { // "pass through" for anonymous pages
      next()
    } else if (to.matched.length > 0 && to.path !== '/') {
      // if we are not logged in, and not requesting home screen, go to login !!!
      localStorage.goToPath = to.fullPath // save destination to go back there after login
      next(false)
      let accountType = null
      if (to.meta.accountType && to.meta.accountType.length === 1) {
        accountType = to.meta.accountType[0].toLowerCase()
      }
      auth.logIn(accountType)
    } else {
      next('/signup')
    }
  })
})

export default router
