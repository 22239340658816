<template>
  <l-map
    ref="map"
    style="height: 200px; width: 100%"
    :zoom="mapView.zoom"
    :center="mapView.center"
    @ready="mapReady = true"
  >
    <l-tile-layer :url="url" />
    <l-marker
      v-if="mappedAddress && mappedAddress.latitude && mappedAddress.longitude"
      :lat-lng="[mappedAddress.latitude, mappedAddress.longitude]"
    />
    <v-overlay
      absolute
      :value="geocoding"
      z-index="9999"
    >
      <v-progress-circular
        indeterminate
        color="red"
        size="40"
      />
    </v-overlay>
  </l-map>
</template>

<script>
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet'
import {DEFAULT_MAP_URL} from '@/globals'
import GeocodeService from '@/services/geocode'

export default {
  name: 'MapWidget',
  components: {LMap, LTileLayer, LMarker},
  props: {
    mappedAddress: {
      type: Object,
      default: () => ({}),
    },
  },
  i18n: {},
  data() {
    return {
      mapView: {
        zoom: 6,
        center: [46.3727442, -72.6658289]
      },
      url: DEFAULT_MAP_URL,
      mapReady: true,
      geocoding: false,
    }
  },
  mounted(){
    this.geocodeAddress(this.mappedAddress)
  },
  methods: {
    geocodeAddress(accountAddress) {
      this.geocoding = true
      if (!this._.isEmpty(accountAddress)) {
        GeocodeService.geocodeAddress(accountAddress, false)
          .then((address) => {
            this.$set(accountAddress, 'longitude', address.longitude)
            this.$set(accountAddress, 'latitude', address.latitude)
            this.$refs.map.mapObject.setView([address.latitude, accountAddress.longitude], 12)
          })
          .finally(() => {
            this.geocoding = false
          })
      }
    },
  }

}
</script>

<style scoped>

</style>
