export const errorHandler = (e) => {
  if (e.response) {
    if (e.response.status === 403 || e.response.status === 401) {
      window.location.href = '/'
      return Error(e.response.statusText)
    } else if (e.response.data) {
      return Error(e.response.data.message || e.response.data)
    }
  }
  return e
}
