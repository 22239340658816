<template>
  <v-container class="pa-0">
    <v-row dense>
      <v-col>
        <v-text-field
          :id="`${_uid}_search`"
          ref="txtSearch"
          v-model="search"
          aria-autocomplete="none"
          autocomplete="off"
          :label="label"
          dense
          outlined
          :rules="[validate]"
          :disabled="disabled"
          hide-details="auto"
          :placeholder="$t('search')"
          :append-outer-icon="search && search.length >= 3 ? 'mdi-square-edit-outline' : ''"
          :append-icon="required ? 'mdi-asterisk' : ''"
          prepend-inner-icon="mdi-magnify"
          @click:append-outer="onManualAddress"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <AddressRender
          :address="address"
          class="font-weight-bold text-body-2 pl-4 mb-2 mt-1"
          single-line
        />
      </v-col>
    </v-row>
    <v-row v-if="error">
      <v-col>
        <v-alert type="warning">
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <l-map
          ref="map"
          style="height: 200px; width: 100%"
          :zoom="mapView.zoom"
          :center="mapView.center"
          @ready="mapReady = true"
        >
          <l-tile-layer :url="url" />
          <l-marker
            v-if="address && address.latitude && address.longitude"
            :lat-lng="[address.latitude, address.longitude]"
          />
          <v-overlay
            absolute
            :value="geocoding"
            z-index="9999"
          >
            <v-progress-circular
              indeterminate
              color="red"
              size="40"
            />
          </v-overlay>
        </l-map>
      </v-col>
    </v-row>
    <AddressGeocodeDialog ref="addressEditDialog" />
    <div v-if="additionalFields">
      <v-row dense>
        <v-col>
          <v-text-field
            v-model="address.contact"
            class="mt-2"
            :label="$t('contact')"
            dense
            hide-details="auto"
            outlined
            :disabled="disabled"
            :rules="contactRequired ? [requiredRule, maxLength(256)] : [maxLength(256)]"
            :append-icon="contactRequired ? 'mdi-asterisk' : ''"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-text-field
            v-model="address.phone"
            :label="$t('phone')"
            dense
            hide-details="auto"
            outlined
            :disabled="disabled"
            :rules="phoneRequired ? [requiredRule, maxLength(256)] : [maxLength(256)]"
            :append-icon="phoneRequired ? 'mdi-asterisk' : ''"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-text-field
            v-model="address.email"
            :label="$t('email')"
            dense
            hide-details="auto"
            outlined
            :disabled="disabled"
            :rules="emailRequired ? [requiredRule, emailValid] : [emailValid]"
            :append-icon="emailRequired ? 'mdi-asterisk' : ''"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<style scoped>

</style>

<script>
import pca from 'pca'
import AddressRender from '@/components/AddressRender'
import AddressGeocodeDialog from '@/components/AddressGeocodeDialog'
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet'
import {DEFAULT_MAP_URL} from '@/globals'
import deepEqual from 'deep-equal'
import {emailValid, maxLength, required} from '@/utils/validation'
import {cancelErrorFilter} from '@/utils/errors'
import GeocodeService from '@/services/geocode'

export default {
  name: 'AddressWidget',

  components: {
    AddressRender, AddressGeocodeDialog,
    LMap, LTileLayer, LMarker
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    label: {
      type: String,
      default: '',
    },
    additionalFields: Boolean,
    disabled: Boolean,
    required: Boolean,
    contactRequired: Boolean,
    emailRequired: Boolean,
    phoneRequired: Boolean,
  },

  i18n: {
    messages: {
      fr: {
        search: 'Rechercher une adresse',
        required: 'L\'adresse est requise',
        geocodingError: 'Impossible de géo-positionner l\'adresse. Veuillez corriger.',
        contact: 'Contact',
        phone: 'Téléphone',
        email: 'Courriel',
      },
      en: {
        search: 'Search address',
        required: 'Address is required',
        geocodingError: 'We are unable to geocode address. Please correct the address.',
        contact: 'Contact',
        phone: 'Phone',
        email: 'Email',
      }
    }
  },

  data() {
    return {
      requiredRule: required,
      maxLength,
      address: this.value || {}, // TODO: clone value ???
      search: '',
      pcaControl: null,
      error: '',
      url: DEFAULT_MAP_URL,
      mapView: {
        zoom: 6,
        center: [46.3727442, -72.6658289]
      },
      geocoding: false,
      mapReady: false,
    }
  },

  computed: {},

  watch: {
    value (val) {
      if (val) {
        if (this.address) {
          const fields = ['address1', 'address2', 'city', 'province', 'provinceCode',
            'country', 'countryCode', 'postalcode', 'latitude', 'longitude']
          if (this.additionalFields) {
            fields.push('phone', 'email', 'contact')
          }
          let changed = false
          for (const field of fields) {
            if (val[field] !== this.address[field]) {
              changed = true
              break
            }
          }
          if (!changed) {
            return
          }
        }
        this.address = val
        if (val.latitude && val.longitude) {
          this.$refs.map.mapObject.flyTo([val.latitude, val.longitude], 12)
        } else if (val.address1) {
          this.geocodeAddress()
        }
      } else {
        this.address = {}
      }
      this.$refs.txtSearch.validate() // we launch validation here to ensure if value is changed field is revalidated
    },
    address: {deep: true, handler: function (val) {
      if (!deepEqual(val, this.value)) {
        this.$emit('input', val) // for v-model binding!
        if ( val && val.latitude && val.longitude) {
          this.$refs.map.mapObject.flyTo([val.latitude, val.longitude], 12)
        }
      }
    }},
  },

  beforeMount() {
    const val = this.value
    if (val) {
      if (val.latitude && val.longitude) {
        this.mapView = { // on est dans le beforeMount so on fait juste update le data!
          zoom: 12,
          center: [val.latitude, val.longitude]
        }
      } else if (val.address1) {
        this.geocodeAddress()
      }
    }
  },

  mounted() {
    const fields = [
        {element: `${this._uid}_search`, field: 'Line1', mode: pca.fieldMode.SEARCH},
      ],
      options = {
        key: 'aj22-km95-tm89-pj39'
      },
      control = new pca.Address(fields, options),
      me = this
    control.listen('populate', (address) => {
      me.address = {}
      me.$set(me.address, 'address2', address.SubBuilding)
      me.$set(me.address, 'city', address.City)
      me.$set(me.address, 'province', address.ProvinceName)
      me.$set(me.address, 'provinceCode', address.ProvinceCode)
      me.$set(me.address, 'country', address.CountryName)
      me.$set(me.address, 'countryCode', address.CountryIso2)
      me.$set(me.address, 'postalcode', address.PostalCode)
      me.$set(me.address, 'address1', `${address.BuildingNumber} ${address.Street}`) // triggers render!
      me.geocodeAddress()
    })
  },

  methods: {
    emailValid: emailValid,
    validate() {
      if (this.required && !this.address.address1) {
        return this.$t('required')
      } else if (Boolean(this.address) && Boolean(this.address.address1) && (!this.address.latitude || !this.address.longitude)) {
        return this.$t('geocodingError')
      }
      return true
    },
    geocodeAddress() {
      // Try to geocode selected address !!
      this.geocoding = true
      GeocodeService.geocodeAddress(this.address, false)
        .then((address) => {
          this.$set(this.address, 'longitude', address.longitude)
          this.$set(this.address, 'latitude', address.latitude)
          this.$refs.map.mapObject.setView([this.address.latitude, this.address.longitude], 12)
          this.search = ''
          this.error = ''
        })
        .catch((error) => {
          this.error = error // TODO: clean i18n message
          this.address.longitude = null
          this.address.latitude = null
          // TODO: depends on error type ???
          this.$refs.addressEditDialog.geocode([this.address], this.error)
            .then(() => {
              this.$refs.txtSearch.validate()
              this.error = ''
              this.$refs.map.mapObject.flyTo([this.address.latitude, this.address.longitude], 12)
            })
            .catch(cancelErrorFilter)
            .catch(reason => {
              console.error(reason)
            })
        })
        .finally(() => {
          this.$refs.txtSearch.validate()
          this.geocoding = false
        })
    },
    onManualAddress() {
      this.$refs.addressEditDialog.geocode([{address1: this.search}], 'msg.oneAddress')
        .then((addresses) => {
        // completely update address
          this.address = addresses[0]
          this.search = ''
        })
        .catch(cancelErrorFilter)
        .catch(reason => {
          console.error(reason)
        })
    }
  }
}
</script>
