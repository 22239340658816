<template>
  <div>
    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-icon
          style="cursor: pointer;"
          :disabled="loading"
          @click="refreshList"
        >
          mdi-replay
        </v-icon>
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-row
          dense
          justify="end"
        >
          <v-text-field
            v-model="search"
            solo
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('search.query')"
            dense
            @input="debounceSearch"
          />
        </v-row>
      </v-col>
    </v-row>
    <div v-if="loading">
      <v-progress-linear
        color="primary"
        indeterminate
      />
    </div>
    <div
      v-else
      class="d-flex justify-space-between align-center font-weight-bold"
      style="max-height: 500px; margin-right: 13px"
    >
      <div
        v-for="header in headers"
        :key="header.value"
        :style="`width: ${header.width}px`"
      >
        <div
          style="text-align: start"
          class="pl-2 py-7 "
        >
          {{ header.text }}
        </div>
      </div>
      <slot name="extra-header" />
    </div>
    <div
      v-if="items.length > 0"
      style="display: block; max-height: 55vh; overflow-y: scroll"
    >
      <div
        v-for="item in items"
        :key="item[itemKey]"
        class="rounded align-center mb-2 d-flex justify-space-between white "
      >
        <div
          v-for="header in headers"
          :key="`${item[itemKey]}_${header.value}`"
          :style="`width: ${header.width}px`"
        >
          <div
            v-if="header.formatter"
            style="text-align: start"
            class="pl-2 py-1 "
          >
            {{ header.formatter(item[header.value]) }}
            <slot
              :name="`item.${header.value}`"
              :item="item"
            />
          </div>
          <div
            v-else
            style="text-align: start"
            class="pl-2 py-6 "
          >
            {{ item[header.value] }}
            <slot
              :name="`item.${header.value}`"
              :item="item"
            />
          </div>
        </div>
        <slot
          :item="item"
          name="extra-controls"
        />
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-center"
    >
      <div>
        {{ emptySetText }}
      </div>
    </div>
    <v-row class="justify-center">
      <v-pagination
        v-model="page"
        :length="Math.floor(total / limit) + 1"
        :total-visible="6"
        class="my-4 justify-center"
      />
    </v-row>
  </div>
</template>

<script>
import {debounce} from 'lodash'
export default {
  name: 'SimpleList',
  props: {
    headers: {
      type: Array,
      default: () => [
        {text: 'list.name', value: 'name', width: 352},
        {text: 'list.phone', value: 'phone', width: 352},
        {text: 'list.email', value: 'email', width: 352},
        {text: 'list.lang', value: 'lang', width: 188},
        {text: 'list.invitationStatus', value: 'invitationStatus', width: 352},
      ],
    },
    items: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: true,
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    emptySetText: {
      type: String,
      default: 'No data'
    }
  },
  data: () => ({
    debounce: null,
    search: '',
    page: 1,
    limit: 50,
  }),
  watch: {
    page: 'fetchRemoteData',
  },
  methods: {
    debounceSearch:
        debounce(function () {
          this.fetchRemoteData()
        }, 750),
    refreshList() {
      this.search = ''
      this.page = 1
      this.fetchRemoteData()
    },
    fetchRemoteData() {
      const searchParams = {search: this.search}
      this.$emit('fetchRequested', searchParams, this.limit, this.page)
    },
  },
  i18n: {
    messages: {
      fr: {
        invite: 'Inviter',
        list: {
          title: 'Liste des livreurs | Liste des livreurs ({n})',
          email: 'Courriel',
          name: 'Nom',
          phone: 'Téléphone',
          lang: 'Langue',
          invitationStatus: 'Invitation',
          emptyset: 'Aucun chauffeur trouvé',
          status0: 'Invitation à envoyée',
          status1: 'Invitation acceptée',
          status2: 'Invitation refusée',
          status3: 'Invitation en attente',
          status4: 'Invitation en erreur (doublon)',
        },
        search: {
          query: 'Rechercher',
        },
        deleteConfirm: 'Voulez-vous vraiment supprimer cet expéditeur ? | Voulez-vous vraiment supprimer {n} expéditeurs ?'
      },
      en: {
        invite: 'Invite',
        list: {
          title: 'Drivers list | Drivers list ({n})',
          unlink: 'Unlink',
          edit: 'Edit',
          number: 'Number',
          emptyset: 'No driver found',
          name: 'Name',
          phone: 'Phone',
          lang: 'Lang',
          email: 'Email',
          invitationStatus: 'Invitation',
          status0: 'Invitation to be sent',
          status1: 'Invitation accepted',
          status2: 'Invitation declined',
          status3: 'Invitation pending',
          status4: 'Invitation error (duplicate)',
        },
        search: {
          query: 'Search',
        },
        deleteConfirm: 'Are you sure you want to delete this driver ? | Are you sure yuo want to delete these {n} drivers ?'
      }
    }
  },
}
</script>

<style scoped>
  .list-container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    padding: 0;
    list-style: none;
  }
  .section-title {
    font-weight: bold;
  }

  .selected {
    background-color: #D6D9D9D9
  }

</style>
