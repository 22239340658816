export const GET_CURRENT_ACCOUNT = 'getCurrentAccount'
export const IS_IMPERSONATED = 'isImpersonated'
export const GET_CONNECTORS = 'getConnectors'
const getters = {
  getCurrentAccount: (state) => {
    return state.account
  },
  getCurrentUser: (state) => {
    return state.user
  },
  isImpersonated: (state) => {
    return state.impersonate
  },
  getConnectors: (state) => {
    return state.connectors
  }
}
export default getters
