<template>
  <v-container>
    <ErrorAlert
      :error-msg="error.msg"
      :with-support-report="error.report"
      :technical-error-details="error.details"
      @clearError="error.msg = '' "
    />
    <v-row v-if="zeConnector">
      <v-col class="pb-4">
        <v-card>
          <v-row>
            <v-col cols="2">
              <v-img
                class="shrink ml-4"
                :src="`./connector/${zeConnector.connectorType}.png`"
                max-width="75"
              />
            </v-col>
            <v-col>
              <v-card-title class="ml-4">
                {{ $t('connector.' + zeConnector.connectorType) }}
              </v-card-title>
              <component
                :is="zeConnector.connectorType"
                ref="configEditor"
                :connector-config="JSON.parse(zeConnector.config)"
                :edit-mode="editMode"
                :connector-config-id="zeConnector.id"
              />
              <v-row v-if="!editMode">
                <v-col>
                  <v-btn
                    v-if="zeConnector.connectorType !== 'effi'"
                    color="primary"
                    @click="editMode = true"
                  >
                    {{ $t('edit') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="ml-4"
                    :loading="testing"
                    :disabled="testing"
                    @click="testConnector"
                  >
                    {{ $t('connection.test') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="ml-4"
                    :loading="processing"
                    :disabled="processing"
                    @click="deleteConnector"
                  >
                    {{ $t('delete') }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col>
                  <v-btn
                    color="primary"
                    :loading="processing"
                    :disabled="processing"
                    @click="saveConfig"
                  >
                    {{ $t('save') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="ml-4"
                    @click="cancelEdit"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="showSnackbar">
      {{ snackMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import ConnectorTypeDialog from '@/components/ConnectorTypeDialog'
import ShipperService from '@/services/shipper'
import CourrierPro from '@/components/connectors/CourrierPro'
import CarrierApi from '@/components/connectors/CarrierApi'
import DummyCarrier from '@/components/connectors/DummyCarrier'
import EffiCarrier from '@/components/connectors/EffiCarrier'
import Shopify from '@/components/connectors/Shopify'
import DummyShipper from '@/components/connectors/DummyShipper'
import ShipperManual from '@/components/connectors/ShipperManual'
import ShipperApi from '@/components/connectors/ShipperApi'
import {ACCOUNT_TYPE} from '@/utils/constants'
import ErrorAlert from '@/components/ErrorAlert'

export default {
  name: 'ConnectorEdit',

  components: {
    ConnectorTypeDialog, ErrorAlert,
    // Carrier connectors
    courrierpro: CourrierPro,
    dummyCarrier: DummyCarrier,
    carrierApi: CarrierApi,
    effi: EffiCarrier,
    // Shipper connectors
    shopify: Shopify,
    dummyShipper: DummyShipper,
    shipperManual: ShipperManual,
    shipperApi: ShipperApi,
  },

  props: {
    edit: Boolean,
    connector: {
      type: Object,
      default: null,
    },
  },

  i18n: {
    messages: {
      en: {
        connection: {
          test: 'Test',
        },
        success: 'Operation succeeded!',
        errors: {
          duplicateShipperManual: 'You can have only one manual connector!',
        }
      },
      fr: {
        connection: {
          test: 'Tester',
        },
        success: 'Operation complétée avec succès!',
        errors: {
          duplicateShipperManual: 'Vous ne pouvez avoir plus d\'un connecteur manuel!',
        }
      }
    }
  },

  data: function() {
    return {
      ACCOUNT_TYPE,
      showSnackbar: false,
      snackMsg: '',
      valid: false,
      processing: false,
      testing: false,
      editMode: this.edit,
      zeConnector: this.connector,
      error: {
        msg: '',
        report: false,
        details: '',
      }
    }
  },

  watch: {
    connector: function(val) {
      if (val !== this.zeConnector) {
        this.zeConnector = val
      }
    },
    edit: function(val) {
      if (val !== this.editMode) {
        this.editMode = val
      }
    }
  },

  methods: {

    saveConfig() {
      if (!this.$refs.configEditor.valid()) {
        return
      }
      this.zeConnector.config = JSON.stringify(this.$refs.configEditor.updatedConfig)
      this.processing = true
      let promise
      if (this.zeConnector.id) {
        promise = ShipperService.updateConnectorConfig(this.connector.id, JSON.parse(this.zeConnector.config))
      } else {
        promise = ShipperService.createConnector(this.zeConnector)
      }
      promise.then((connector) => {
        this.editMode = false
        this.zeConnector = connector
        this.$emit('edited', this.zeConnector)
        this.error = {}
      })
        .catch((error) => {
          if (error.message.indexOf('duplicateShipperManual') > -1) {
            this.error = {
              msg: this.$t('errors.duplicateShipperManual'),
              report: false,
              details: '',
            }
          } else {
            this.error = {
              msg: this.$t('errors.generic'),
              report: true,
              details: error.toString(),
            }
          }
        })
        .finally(() => {
          this.processing = false
        })
    },

    testConnector() {
      this.testing = true
      ShipperService.testConnector(this.zeConnector.id)
        .then((result) => {
          if (result.success === true) {
            this.snackMsg = this.$t('success')
          } else {
            this.snackMsg = result.error
          }
          this.showSnackbar = true
          this.error = {}
        })
        .catch((error) => {
          this.error = {
            msg: this.$t('errors.generic'),
            report: true,
            details: error.toString(),
          }
        })
        .finally(() => {
          this.testing = false
        })
    },

    deleteConnector() {
      this.processing = true
      ShipperService.deleteConnector(this.zeConnector.id)
        .then(() => {
          this.editMode = false
          this.zeConnector = null
          this.$emit('edited', this.zeConnector)
          this.$emit('update:edit')
          this.error = {}
        })
        .catch((error) => {
          this.error = {
            msg: this.$t('errors.generic'),
            report: true,
            details: error.toString(),
          }
        })
        .finally(() => {
          this.processing = false
        })
    },

    cancelEdit() {
      // create mode cancel ??
      if (!this.zeConnector.id) {
        this.zeConnector = null
        this.$emit('edited', this.zeConnector)
      }
      this.editMode = false
      this.error = {}
      this.$emit('update:edit')
    },

  }
}
</script>

<style scoped>

</style>