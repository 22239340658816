var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-12"},[_c('ErrorAlert',{staticStyle:{"margin-top":"15px"},attrs:{"error-msg":_vm.errorMsg,"with-support-report":_vm.technicalErrorDetails.withAction,"technical-error-details":_vm.technicalErrorDetails.details},on:{"clearError":function($event){_vm.errorMsg = ''}}}),_c('div',[_c('div',{staticClass:"list-container"},[_c('v-col',[_c('h2',[_vm._v(_vm._s(_vm.$tc('list.title', _vm.total))+" ")])]),_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAccountOwner),expression:"isAccountOwner"}],staticStyle:{"margin-right":"10px"},attrs:{"color":"primary","to":(_vm.invitePath + "?inviteType=user&returnUrl=" + (_vm.$route.path))}},[_vm._v(" "+_vm._s(_vm.$t('invite'))+" ")])],1),_c('SimpleList',{attrs:{"items":_vm.items,"total":_vm.total,"loading":_vm.loading,"item-key":"email","headers":_vm.headers,"empty-set-text":_vm.$t('list.emptyset')},on:{"fetchRequested":_vm.fetchUsers},scopedSlots:_vm._u([(_vm.isAccountOwner)?{key:"extra-header",fn:function(){return [_c('div',{staticStyle:{"width":"40px"}})]},proxy:true}:null,{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.userId === _vm.accountOwnerId),expression:"item.userId === accountOwnerId"}]},'v-icon',attrs,false),on),[_vm._v(" mdi-key-variant ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('list.ownerTooltip')))])])]}},(_vm.isAccountOwner)?{key:"extra-controls",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('v-menu',{attrs:{"left":"","offset-y":"","open-on-hover":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"disabled":item.userId === _vm.accountOwnerId || item.removed === 1 || (item.invitationStatus !== 1 && item.invitationStatus !== 5),"text":""},on:{"click":function($event){return _vm.transferOwnership(item)}}},[_vm._v(" "+_vm._s(_vm.$t('list.makeOwner'))+" ")])],1),_c('v-list-item',[_c('v-btn',{attrs:{"disabled":item.userId === _vm.accountOwnerId || item.removed === 1,"text":""},on:{"click":function($event){return _vm.deleteUser(item)}}},[_vm._v(" "+_vm._s(_vm.$t('list.deactivate'))+" ")])],1)],1)],1)],1)]}}:null],null,true)})],1),_c('ConfirmDialog',{ref:"userActionsConfirmDialog",attrs:{"message":_vm.$t(_vm.confirmMessage, {name: _vm.targetedUser.name})}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }