<template>
  <div>
    <v-row
      v-if="label"
      dense
    >
      <v-col class="text-body-2">
        {{ label }}
      </v-col>
    </v-row>
    <v-row
      dense
      align="center"
      justify="center"
    >
      <v-col>
        <div class="d-flex flex-row">
          <div
            class="mr-2 text-body-2"
            style="align-self: center"
          >
            {{ $t('between') }}
          </div>
          <time-picker-field
            v-model="tw.after"
            is-after
            :other-time="tw.before"
            format="24hr"
            :disabled="disabled"
            :clearable="clearable"
            :error="error"
          />
          <div
            class="mx-2 text-body-2"
            style="align-self: center;"
          >
            {{ $t('and') }}
          </div>
          <time-picker-field
            v-model="tw.before"
            is-before
            :other-time="tw.after"
            format="24hr"
            :disabled="disabled"
            :clearable="clearable"
            :error="error"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TimePickerField from '@/components/TimePickerField'
import {cloneDeep, isEqual} from 'lodash'

export default {
  name: 'TimeWindow',

  components: {
    TimePickerField
  },

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: Boolean,
    label: {
      type: String,
      default: '',
    },
    clearable: Boolean,
    error: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      tw: this.initFromValue(this.value)
    }
  },

  watch: {
    value: function (val) {
      this.tw = this.initFromValue(val)
    },
    tw: {deep: true, handler: function(val) {
      if (!isEqual(val, this.value)) {
        this.$emit('input', cloneDeep(val))
      }
    }},
  },

  methods: {
    initFromValue(value) {
      return value ? cloneDeep(value) : {}
    }
  },

  i18n: {
    messages: {
      fr: {
        between: 'Entre',
        and: 'et',
      },
      en: {
        between: 'Between',
        and: 'and ',
      },
    }
  }

}

</script>

<style scoped>

</style>
