var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(!_vm.$route.name || (_vm.$route.name && _vm.$route.name.indexOf('tracking') !== 0))?_c('v-app-bar',{staticStyle:{"z-index":"10000"},attrs:{"app":""}},[_c('div',{staticClass:"d-flex align-center "},[_c('v-img',{staticClass:"shrink mr-6",attrs:{"alt":"Vuetify Logo","contain":"","src":require('./assets/logo.png'),"transition":"scale-transition","width":"75"}})],1),(_vm.$store.state.account)?_c('v-tabs',{key:_vm.$i18n.locale},_vm._l((_vm.menuItems),function(item,idx){return _c('v-tab',{key:("menuItem" + idx),attrs:{"to":item.to}},[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")])}),1):_vm._e(),_c('v-spacer'),(_vm.$store.state.user)?_c('v-menu',{attrs:{"offset-y":"","z-index":"100001"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-circle ")]),_vm._v(" "+_vm._s(_vm.username)+" ")],1)]}}],null,false,521167518)},[_c('v-list',{attrs:{"dense":"","nav":""}},[(_vm.$store.state.impersonate)?_c('v-list-item',{on:{"click":_vm.returnAdmin}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-login")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('returnAdmin')))])],1)],1):_vm._e(),(_vm.$store.state.user)?_c('v-list-item',{attrs:{"to":"/profile"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('profile')))])],1)],1):_vm._e(),(_vm.$store.state.account && _vm.$store.state.account.type !== 'ADMIN' && (_vm.isAccountOwner || _vm.$store.state.impersonate))?_c('v-list-item',{attrs:{"to":"/account"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('settings')))])],1)],1):_vm._e(),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('logout')))])],1)],1)],1)],1):_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLoginBtn),expression:"showLoginBtn"}],on:{"click":_vm.login}},[_c('v-icon',[_vm._v("mdi-")]),_vm._v(" "+_vm._s(_vm.$t('login'))+" ")],1),_c('v-menu',{attrs:{"offset-y":"","z-index":"100001"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-help-circle ")])],1)]}}],null,false,1951763340)},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.showSupportDialog()}}},[_c('v-list-item-icon',{staticClass:"mt-3 mr-3"},[_c('v-icon',[_vm._v("mdi-handshake-outline")])],1),_c('v-list-item-content',{staticClass:"ma-1"},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('menu.support')))])],1)],1)],1)],1)],1):_vm._e(),_c('v-main',{staticClass:"main"},[_c('keep-alive',{attrs:{"include":"Map, Shipments, ShipmentSelectMenu, RoutesDrawer, StopsList, RoutesMap"}},[_c('router-view',{key:_vm.$route.fullPath})],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.showSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])]}}]),model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackMsg)+" ")]),_c('ErrorDialog',{attrs:{"message":_vm.errorMessage},model:{value:(_vm.showError),callback:function ($$v) {_vm.showError=$$v},expression:"showError"}}),_c('ContactSupportDialog',{attrs:{"details":_vm.errorMessage},model:{value:(_vm.showSupport),callback:function ($$v) {_vm.showSupport=$$v},expression:"showSupport"}}),(_vm.$store.state.account && _vm.$store.state.account.type === 'SHIPPER')?_c('ShopifyConsentDialog'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }