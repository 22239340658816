import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import {COLORS} from '@/utils/constants'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: COLORS.PRIMARY,
        black: '#222222',
        grayIcon: '#767676',
        lightgray: COLORS.LIGHTGRAY,
        effiYellow: COLORS.EFFI_YELLOW,
        effiOrange: COLORS.EFFI_ORANGE,
        effiDeepOrange: COLORS.EFFI_DEEP_ORANGE,
        effiBlue: COLORS.EFFI_BLUE,
        effiGreen: COLORS.EFFI_GREEN,
        effiHighlight: COLORS.EFFI_HIGHLIGHT,
        anchor: '#007DE5',
        grayIconDark: '#A8A8A8',
        lightblack: '#4A4A4A',
      },
      dark: {
        primary: COLORS.PRIMARY,
      }
    },
    options: {customProperties: true},
  }
})
